import { HeaderBackIcon, HeaderSwitchIcon } from '@ifca-ui/icons/dist/index.esm'
import {
  Close as CloseIcon,
  ExitToApp as ExitTopAppIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  Menu as MenuIcon,
} from '@mui/icons-material'
import {
  AppBar,
  Avatar,
  Breadcrumbs,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  type AppBarProps,
  type AvatarProps,
  type IconButtonProps,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import type React from 'react'
import { useCallback, useMemo, type FC } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Colors } from '../../styles/variables'

const StyledAppBar = styled(AppBar) <AppBarProps>`
  &::before {
    @media screen and (min-width: 600px) {
      content: close-quote;
      background: #f5f5f5;
      width: 100%;
      height: 24px;
    }
  }
  &.MuiAppBar-root {
    z-index: 2;
    @media screen and (min-width: 600px) {
      box-shadow: none;
      top: 64px;
      width: calc(100% - 288px);
      right: 24px;
    }
    &.full-width {
      left: 24px;
      width: calc(100% - 48px);
    }
  }
  .MuiToolbar-root {
    flex-wrap: wrap;
    min-height: unset;
    padding: 4px 12px;
  }
`
const LeftIconButton = styled(IconButton) <IconButtonProps>`
  padding: 0px !important;
  padding-right: 10px !important;
  color: #fff;
  border-radius: 2px;

  .MuiSvgIcon-root {
    background: white !important;
    border-radius: 2px;
    padding: 2px;
    width: 22px;
    height: 22px;
    color: ${Colors.primaryOrange};
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .MuiAvatar-root {
    width: 22px;
    height: 22px;
  }
`
const RightIconButton = styled(IconButton) <IconButtonProps>`
  padding: 0px !important;
  margin-left: 8px;
  color: #212121 !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  .MuiSvgIcon-root {
    font-size: 16px;
  }
`
const TopContainer = styled('div')`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 2px;
  align-items: center;
`
const TopSection = styled('div')`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  .label-smTitle {
    font-size: 10px;
    color: #fff;
    font-weight: 600;
    line-height: 16px;
    padding-top: 2px;
  }
  .label-title {
    color: #212121;
    line-height: 16px;
    font-size: 12px;
    font-weight: 600;
  }
`
const BottomContainer = styled('div')`
  display: flex;
  width: 100%;
  padding-top: 2px;
  nav.breadcrumbs {
    overflow: hidden;
    margin: -7px 0px -2px;
    flex: 1;
  }
  nav.right-breadcrumbs {
    overflow: hidden;
    margin: -7px 0px -2px;
    text-align: right;
    color: #212121;
    .MuiBreadcrumbs-separator {
      margin: 0px 2px;
      color: #212121 !important;
      font-size: 10px;
      line-height: 1px;
      padding: 12px 0px 5px 0px;
    }
  }
  .MuiBreadcrumbs-ol {
    overflow: hidden;
    padding-top: 2px;
    margin-right: 2px;
    display: inline-flex;
    flex-wrap: initial;
    .MuiBreadcrumbs-li {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .MuiBreadcrumbs-separator {
      margin: 0px 2px;
      color: #212121;
      font-size: 9px;
      line-height: 1px;
      padding: 12px 0px 5px 0px;
    }
    .breadcrumb-label {
      font-size: 10px;
      color: #212121;
      line-height: 1rem;
      &.current {
        font-weight: 500;
      }
    }
    .breadcrumb-right-label {
      font-size: 10px;
      color: #212121;
      &.current {
        font-weight: 600;
      }
    }
  }
`
export type LeftIconType = 'menu' | 'back' | 'close' | 'avatar'
export type RightIconType = 'switch' | 'back' | 'exit'
export interface HeaderProps extends AppBarProps {
  headerSection?: AppBarProps
  /**
   * for header background svg url
   */
  headerBgURL?: string
  /**
   * 'menu' | 'back' | 'close' | 'avatar'
   */
  leftIcon?: {
    icon?: LeftIconType
    avatar?: AvatarProps
    props?: IconButtonProps
  }
  /**
   * 'switch' | 'exit'
   */
  rightIcon?: {
    icon?: RightIconType
    props?: IconButtonProps
  }
  /**
   * topSection label for smTile and title

   */
  topSection?: {
    smTitle?: string
    title?: string
  }
  /**
   * bottomSection breadcrumbs and right label
   */
  bottomSection?: {
    breadcrumbs?: {
      maxItems?: number
      current?: string
    }
    rightText?: string | React.ReactNode
  }
  component?: JSX.Element
}

const circleStyle = {
  background: '#fff',
  borderRadius: '50%',
  boxShadow:
    '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  width: '20px',
  height: '20px',
}

const rightIconOptions = {
  switch: <HeaderSwitchIcon style={circleStyle} />,
  back: <HeaderBackIcon style={circleStyle} />,
  exit: <ExitTopAppIcon />,
}

export const Header: FC<HeaderProps> = ({
  headerBgURL,
  leftIcon,
  rightIcon,
  topSection,
  bottomSection,
  component,
  ...props
}) => {
  const hotkeyFns = useMemo<{
    esc: () => void
  }>(() => {
    let esc = (): void => { }

    if (leftIcon) {
      if (leftIcon.icon === 'back' && leftIcon.props?.onClick) {
        esc = leftIcon.props.onClick
      } else if (leftIcon.icon === 'close' && leftIcon.props?.onClick) {
        esc = leftIcon.props.onClick
      }
    }

    if (rightIcon) {
      if (rightIcon.icon === 'exit' && rightIcon.props?.onClick) {
        esc = rightIcon.props.onClick
      } else if (rightIcon.icon === 'back' && rightIcon.props?.onClick) {
        esc = rightIcon.props.onClick
      }
    }

    return {
      esc,
    }
  }, [leftIcon, rightIcon])

  useHotkeys('esc', () => {
    hotkeyFns.esc()
  })

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const leftIconOptions = useMemo(
    () => ({
      menu: <MenuIcon />,
      back: <KeyboardArrowLeftIcon style={{ margin: '4px 0' }} />,
      close: <CloseIcon />,
      avatar: (
        <Avatar
          {...leftIcon?.avatar}
          variant="square"
          imgProps={{
            loading: 'lazy',
          }}
        />
      ),
    }),
    [leftIcon]
  )

  const handleLeftIconOption = useCallback(
    (iconType: LeftIconType): JSX.Element => leftIconOptions[iconType] || '',
    [leftIconOptions]
  )

  const handleRightIconOption = useCallback(
    (iconType: RightIconType): JSX.Element => rightIconOptions[iconType] || '',
    []
  )

  // breadcrumbs
  const breadcrumbValue = useMemo(() => {
    return Array(bottomSection?.breadcrumbs?.maxItems ?? 0)
      .fill(0)
      .map((_, i) => {
        return (
          <Typography
            key={i}
            component="span"
            variant="inherit"
            className="breadcrumb-label"
          >
            ...
          </Typography>
        )
      })
  }, [bottomSection?.breadcrumbs?.maxItems])

  return (
    <>
      <StyledAppBar
        id="main-header"
        position="fixed"
        sx={{
          background: `url("${headerBgURL}")`,
          backgroundPosition: 'bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '130%',
        }}
        {...props}
      >
        <Toolbar disableGutters>
          <TopContainer>
            {leftIcon ? (
              <>
                {isDesktop && leftIcon?.icon === 'menu' ? null : (
                  <LeftIconButton {...leftIcon?.props} size="small">
                    {handleLeftIconOption(leftIcon?.icon as LeftIconType)}
                  </LeftIconButton>
                )}
              </>
            ) : null}
            <TopSection>
              <Typography
                key="smTitle"
                variant="inherit"
                component="div"
                className="label-smTitle"
                noWrap
              >
                {topSection?.smTitle}
              </Typography>
              <Typography
                key="labelTitle"
                variant="inherit"
                component="div"
                className="label-title"
                noWrap
              >
                {topSection?.title}
              </Typography>
            </TopSection>

            <RightIconButton {...rightIcon?.props}>
              {handleRightIconOption(rightIcon?.icon as RightIconType)}
            </RightIconButton>
          </TopContainer>
          <BottomContainer>
            <Breadcrumbs
              key="left-breadcrumbs"
              className="breadcrumbs"
              separator="/"
              aria-label="breadcrumb"
            >
              {breadcrumbValue}
              <Typography
                component="span"
                variant="inherit"
                className="breadcrumb-label current"
              >
                {bottomSection?.breadcrumbs?.current}
              </Typography>
            </Breadcrumbs>
            <Breadcrumbs
              key="right-breadcrumbs"
              className="right-breadcrumbs"
              separator="/"
              aria-label="breadcrumb"
            >
              <Typography
                component="span"
                variant="inherit"
                className="breadcrumb-right-label current"
              >
                {bottomSection?.rightText}
              </Typography>
            </Breadcrumbs>
          </BottomContainer>
        </Toolbar>
        {component}
      </StyledAppBar>
    </>
  )
}
