import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const BankerList = lazy(() => import('./BankerList'))
const BankerDetail = lazy(() => import('./BankerDetail'))
const EditBanker = lazy(() => import('./EditBanker'))
const NewBanker = lazy(() => import('./AddBanker'))

export const BANKER_PATH = {
  BANKER_LIST: '/banker/banker-list',
  BANKER_DETAIL: '/banker/banker-detail',
  EDIT_BANKER: '/banker/edit-banker',
  New_BANKER: '/banker/new-banker',
}
const BankerRoutes: RouteObject[] = [
  {
    path: 'banker-list' ,
	 element:<BankerList />,
  },
  {
    path: `banker-detail/:userId` ,
	 element:<BankerDetail />,
  },
  {
    path: `edit-banker/:userId` ,
	 element:<EditBanker />,
  },
  {
    path: `new-banker` ,
	 element:<NewBanker />,
  },
]

export default BankerRoutes
