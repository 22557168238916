export const FontFamily = {
  default: 'Poppins, sans-serif',
}

export const FontSizes = {
  fs_8: '8px',
  fs_9: '9px',
  fs_10: '10px',
  fs_11: '11px',
  fs_12: '12px',
  fs_14: '14px',
  fs_16: '16px',
  fs_18: '18px',
  fs_20: '20px',
}

export const FontWeights = {
  thin: 100,
  extralight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
}

export const LineHeights = {
  lh_10: '10px',
  lh_12: '12px',
  lh_14: '14px',
  lh_16: '16px',
  lh_18: '18px',
  lh_20: '20px',
}

export const FontWeightClasses = {
  '.fw-thin': {
    fontWeight: FontWeights.thin,
  },

  '.fw-extralight': {
    fontWeight: FontWeights.extralight,
  },
  '.fw-light': {
    fontWeight: FontWeights.light,
  },
  '.fw-regular': {
    fontWeight: FontWeights.regular,
  },
  '.fw-medium': {
    fontWeight: FontWeights.medium,
  },
  '.fw-semibold': {
    fontWeight: FontWeights.semibold,
  },
  '.fw-bold': {
    fontWeight: FontWeights.bold,
  },
}
// export const FontWeightClassess = css`
//   .fw-thin {
//     font-weight: ${FontWeights.thin};
//   }
//   .fw-extralight {
//     font-weight: ${FontWeights.extralight};
//   }
//   .fw-light {
//     font-weight: ${FontWeights.light};
//   }
//   .fw-regular {
//     font-weight: ${FontWeights.regular};
//   }
//   .fw-medium {
//     font-weight: ${FontWeights.medium};
//   }
//   .fw-semibold {
//     font-weight: ${FontWeights.semibold};
//   }
//   .fw-bold {
//     font-weight: ${FontWeights.bold};
//   }
//   .fw-extrabold {
//     font-weight: ${FontWeights.extrabold};
//   }
// `
export const Typography = {
  '.text-title': {
    fontSize: FontSizes.fs_16,
    fontWeight: FontWeights.semibold,
    lineHeight: LineHeights.lh_18,
  },
  '.text-smTitle': {
    fontSize: FontSizes.fs_14,
    fontWeight: FontWeights.semibold,
    lineHeight: LineHeights.lh_16,
  },
  '.text-xsTitle': {
    fontSize: FontSizes.fs_12,
    fontWeight: FontWeights.semibold,
    lineHeight: LineHeights.lh_16,
  },
  '.text-xxTitle': {
    fontSize: FontSizes.fs_10,
    fontWeight: FontWeights.semibold,
    lineHeight: LineHeights.lh_12,
  },
  '.text-mdDesc': {
    fontSize: FontSizes.fs_12,
    fontWeight: FontWeights.regular,
    lineHeight: LineHeights.lh_14,
  },
  '.text-desc': {
    fontSize: FontSizes.fs_11,
    fontWeight: FontWeights.regular,
    lineHeight: LineHeights.lh_16,
  },
  '.text-mdLabel': {
    fontSize: FontSizes.fs_12,
    fontWeight: FontWeights.medium,
    lineHeight: LineHeights.lh_16,
  },
  '.text-label': {
    fontSize: FontSizes.fs_10,
    fontWeight: FontWeights.medium,
    lineHeight: LineHeights.lh_12,
  },
  '.text-seperator': {
    margin: '0px 4px',
  },
}
// export const Typographys = css`
//   .text-title {
//     font-size: ${FontSizes.fs_16};
//     font-weight: ${FontWeights.semibold};
//     line-height: ${LineHeights.lh_18};
//   }
//   .text-smTitle {
//     font-size: ${FontSizes.fs_14};
//     font-weight: ${FontWeights.semibold};
//     line-height: ${LineHeights.lh_16};
//   }
//   .text-xsTitle {
//     font-size: ${FontSizes.fs_12};
//     font-weight: ${FontWeights.semibold};
//     line-height: ${LineHeights.lh_14};
//   }
//   .text-xxTitle {
//     font-size: ${FontSizes.fs_10};
//     font-weight: ${FontWeights.semibold};
//     line-height: ${LineHeights.lh_12};
//   }

//   .text-mdDesc {
//     font-size: ${FontSizes.fs_12};
//     font-weight: ${FontWeights.regular};
//     line-height: ${LineHeights.lh_14};
//   }

//   .text-desc {
//     font-size: ${FontSizes.fs_10};
//     font-weight: ${FontWeights.regular};
//     line-height: ${LineHeights.lh_12};
//   }
//   .text-mdLabel {
//     font-size: ${FontSizes.fs_12};
//     font-weight: ${FontWeights.medium};
//     line-height: ${LineHeights.lh_14};
//   }
//   .text-label {
//     font-size: ${FontSizes.fs_10};
//     font-weight: ${FontWeights.medium};
//     line-height: ${LineHeights.lh_12};
//   }
//   .text-seperator {
//     margin: 0px 4px;
//   }
//   .text-icon {
//     display: flex;
//     align-items: center;
//     overflow: hidden;

//     span {
//       white-space: nowrap;
//       overflow: hidden;
//       text-overflow: ellipsis;
//     }
//     .MuiSvgIcon-root,
//     svg {
//       margin-right: 4px;
//       font-size: 14px;
//       line-height: 16px;
//     }
//   }
// `
