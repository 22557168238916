import { Button, type ButtonProps } from '@mui/material'
// import styled from 'styled-components'
import { styled } from '@mui/material/styles'

interface AuthButtonProps extends ButtonProps {}
export const AuthButton = styled(Button)<AuthButtonProps>`
  &.MuiButton-root {
    width: 180px;
    display: block;
    margin: 24px auto 0px;
    text-transform: capitalize;
    border-radius: 100px;
    &.MuiButton-containedPrimary {
      color: #ffffff;
    }
    &.Mui-disabled {
      color: #00000042;
    }
  }
`
