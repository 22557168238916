import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const Profile = lazy(() => import('./Profile'))
const EditProfile = lazy(() => import('./EditProfile'))
const ChangePassword = lazy(() => import('./ChangePassword'))

export const ACCT_PATH = {
  PROFILE: 'profile',
  EDIT_PROFILE: 'edit-profile',
  CHANGE_PASSWORD: 'change-password',
}
const AcctRoutes: RouteObject[] = [
  {
    path: '',
    element: <Profile />,
  },
  {
    path: ACCT_PATH.PROFILE,
    element: <Profile />,
  },
  {
    path: ACCT_PATH.EDIT_PROFILE,
    element: <EditProfile />,
  },
  {
    path: ACCT_PATH.CHANGE_PASSWORD,
    element: <ChangePassword />,
  },]


export default AcctRoutes
