import { Box, Drawer as MuiDrawer, type DrawerProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import type * as React from 'react'

export const drawerWidth = 240

const classes = {
  drawer: `drawer`,
  drawerPaper: `drawerPaper`,
}
const Root = styled(Box)(({ theme }: any) => ({
  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up('sm')]: {
      width: 240,
      flexShrink: 0,
    },
    width: 240,
  },
  [`& .MuiDrawer-root`]: {
    width: 240,
  },
  [`& .${classes.drawerPaper}`]: {
    zIndex: 50,
    width: 240,

    [theme.breakpoints.up('sm')]: {
      top: 64,
      height: 'calc(100% - 64px)',
    },
  },
}))
export interface drawerProps extends DrawerProps {
  children?: React.ReactNode
}

export const Drawer: React.FC<drawerProps> = ({ id, children, ...props }) => {
  // const classes = useStyles()

  return (
    <Root id={id} component="nav" className={`${classes.drawer} here `}>
      <MuiDrawer
        {...props}
        sx={{
          '& .MuiDrawer-paper': { width: drawerWidth },
        }}
        anchor={'left'}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {children}
      </MuiDrawer>
    </Root>
  )
}
