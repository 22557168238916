import { MenuItem as MuiMenuItem, type MenuItemProps } from '@mui/material'
import { styled } from '@mui/system'

export const ProfileMenuItem = styled(MuiMenuItem)`
  &.MuiMenuItem-root {
    padding: 10px 23px;
    svg {
      margin: 0 5px 3px 0;
    }
  }
`

export const MenuItem = ({ ...props }: MenuItemProps): JSX.Element => {
  return (
    <MuiMenuItem
      sx={{
        // padding: '0px',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 600,
        color: '#212121',
        minHeight: { sm: '48px' },
        '& .MuiSvgIcon-root': {
          width: '18px',
          height: '18px',
          marginRight: '8px',
        },
      }}
      {...props}
    />
  )
}
