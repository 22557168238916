import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
export const layoutClasses = {
  root: `root`,
  mainContent: `mainContent`,
  contentShift: `contentShift`,
  toolbar: 'toolbar',
}

export const LayoutRoot = styled(Box)(({ theme }: any) => ({
  [`& .${layoutClasses.root}`]: {
    display: 'flex',
  },
  [`& .${layoutClasses.mainContent}`]: {
    position: 'relative',
    top: 64,
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      top: 64,
      marginLeft: `-240px`,
      padding: theme.spacing(3),
    },
  },

  [`& .${layoutClasses.contentShift}`]: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  [`& .${layoutClasses.toolbar}`]: theme.mixins.toolbar,
}))
