import { navigationObservable } from 'containers/helper/navigationSubjectHelper'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

//to subscribe the navigation subject help on navigating
const NavigationProvider = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigationObservable().subscribe(x => {
      if (x) {
        navigate(x)
      }
    })
  }, [])
  return <></>
}
export default NavigationProvider
