import { List as MuiList, type ListProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const ListRoot = styled(MuiList)`
  padding-top: 10px;
`

export const List = ({ ...props }: ListProps): JSX.Element => {
  return (
    <ListRoot
      {...props}
      sx={{
        paddingTop: '0px',
      }}
    />
  )
}
