import {
  AppBar,
  type AppBarProps,
  IconButton,
  type IconButtonProps,
  Toolbar,
  Typography,
} from '@mui/material'
import type * as React from 'react'
import { styled } from '@mui/system'

import { Menu as MenuIcon } from '@mui/icons-material'
import { ColorGradients } from '../../styles/variables'

const StyledAppBar = styled(AppBar)`
  background-color: white;
  /* background: ${ColorGradients.blue}; */
  z-index: 150;
  .MuiButtonBase-root {
    padding: 16px;
    margin-left: -16px;
  }
`
export interface DesktopHeaderProps extends AppBarProps {
  /** for desktop header title display purpose */
  title?: string
  leftIcon?: {
    props?: IconButtonProps
  }
}
export const DesktopHeader: React.FC<DesktopHeaderProps> = ({
  title,
  leftIcon,
  ...props
}) => {
  return (
    <StyledAppBar {...props}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          {...leftIcon?.props}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="span"
          variant="inherit"
          className="fw-medium"
          color="common.black"
        >
          {title}
        </Typography>
      </Toolbar>
    </StyledAppBar>
  )
}
