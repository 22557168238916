import { Typography } from '@mui/material'
import { type ReactNode } from 'react'
import styled from 'styled-components'

export const AuthSection = styled.div`
  background-size: cover !important;
  background: ${({ backgroundImage }: AuthContainerProps) =>
    backgroundImage
      ? `url("${backgroundImage}") no-repeat center center fixed`
      : '#212121'};
  height: 100vh;
  display: flex;
  align-items: center;
`
export const AuthContentSection = styled.div`
  max-width: 400px;
  min-width: 280px;
  margin: 0 auto;
  width: 80vw;
  padding: 30px 12px;
  border-radius: 10px;
  background-color: #00031d91;
  height: fit-content;
  // Title
  .page-title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    display: block;
    margin: 32px 0px 0px;
    color: white;
  }
  // SubTitle
  .page-subtitle {
    color: white;
    display: block;
    margin: 20px 0px 0px;
    text-align: center;
    white-space: break-spaces;
    line-height: 18px;
  }
  //forgot-password
  .forget-password {
    color: rgba(255, 255, 255, 0.54);
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    text-align: right;
    display: block;
    cursor: pointer;
    flex: 1;
  }
  // form content
  .form-content {
    margin-top: 40px;
    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 16px;
      .MuiFormLabel-root {
        color: rgba(255, 255, 255, 0.54);
      }
      .MuiInputBase-root {
        color: rgba(255, 255, 255, 0.87);
      }
      .MuiInput-underline:before {
        border-bottom: 1px solid rgba(255, 255, 255, 0.42);
      }
      .MuiIconButton-root {
        color: rgba(255, 255, 255, 0.54);
      }
    }
    .forget-password-row {
      display: flex;
      align-items: center;
      .MuiCheckbox-root {
        color: rgba(255, 255, 255, 0.54);
      }
      .MuiFormControlLabel-label {
        color: rgba(255, 255, 255, 0.54);
        font-size: 12px;
      }
      .MuiButtonBase-root {
        padding: 9px;
      }
    }
  }
`
const Logo = styled.img`
  width: 48vw;
  max-width: 250px;
  display: block;
  margin: 0 auto;
`
export interface AuthContainerProps {
  /** standard children prop: accepts any valid React Node  */
  children?: ReactNode
  /** Background image */
  backgroundImage?: string
  /** Logo Image */
  logo?: string
  /** Title value */
  title?: string
  /** Subtitle value */
  subTitle?: string
}
export const AuthContainer = ({
  title,
  children,
  logo,
  subTitle,
  ...props
}: AuthContainerProps): JSX.Element => {
  const LogoSection = logo && <Logo src={logo} alt="logo" loading="lazy" />

  const SubTitleSection = subTitle && (
    <Typography
      component="span"
      className="page-subtitle text-desc"
      variant="inherit"
    >
      {subTitle}
    </Typography>
  )
  return (
    <AuthSection {...props}>
      <AuthContentSection>
        {LogoSection}
        <Typography component="span" className="page-title" variant="inherit">
          {title}
        </Typography>
        {SubTitleSection}
        {children}
      </AuthContentSection>
    </AuthSection>
  )
}
