import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction as MuiBottomNavigationAction,
  type BottomNavigationActionProps,
  type BottomNavigationProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'

export const BottomNavigation = ({
  ...props
}: BottomNavigationProps): JSX.Element => {
  return <MuiBottomNavigation {...props} />
}

export const MuiBottomNavigationActionRoot = styled(MuiBottomNavigationAction)`
  padding: 8px 12px 8px;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  color: #212121;
`

export const BottomNavigationAction = ({
  ...props
}: BottomNavigationActionProps): JSX.Element => {
  return <MuiBottomNavigationActionRoot {...props} />
}
