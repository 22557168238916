// import { styled } from '@mui/material/styles'
import { Search as SearchIcon } from '@mui/icons-material'
import {
  InputAdornment,
  InputBase,
  List,
  ListItem,
  ListItemText,
  type InputBaseProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'
const SearchInputRoot = styled(List)`
  width: 100%;
  z-index: 100;
  background: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);

  .MuiListItem-root {
    padding: 10px 12px;
  }
  .MuiListItemText-root {
    margin: 0px;
    display: flex;
    padding: 2px 0px;
  }
  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
  .MuiInputBase-root {
    /* width: 100%; */
    .MuiInputBase-input {
      padding: 0px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
    }
    .MuiInputBase-input input::placeholder {
      color: #212121;
    }
  }
`
interface SearchInputBarProps {
  searchInput?: InputBaseProps
}

export const SearchInputBar = ({
  searchInput,
}: SearchInputBarProps): JSX.Element => {
  return (
    <SearchInputRoot id="search-input-bar" disablePadding>
      <ListItem id="search-field" disablePadding>
        <ListItemText
          disableTypography
          sx={{
            padding: '0px !important',
          }}
          primary={
            <>
              {' '}
              <InputBase
                inputProps={{ 'aria-label': 'search' }}
                {...searchInput}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#212121' }} />
                  </InputAdornment>
                }
              //   {...searchInput}
              />{' '}
            </>
          }
        />
      </ListItem>
    </SearchInputRoot>
  )
}
