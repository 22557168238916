import { Box, type BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import type * as React from 'react'
import { Colors } from '../../styles/variables'

export const SubheaderRoot = styled(Box)<SubheaderProps>`
  width: 100%;
  /* position: fixed; */
  display: flex;
  padding: 8px 12px;
  background: ${Colors.lightOrange};
  z-index: 100;
  box-sizing: border-box;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
`
export interface SubheaderProps extends BoxProps {
  children?: React.ReactNode
}
export const Subheader = ({
  children,
  ...props
}: SubheaderProps): JSX.Element => {
  return (
    <SubheaderRoot id="subheader" {...props}>
      {children}
    </SubheaderRoot>
  )
}
