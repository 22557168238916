import { ApolloProvider } from '@apollo/client'
import { Snackbar, Spinner, Theme } from '@ifca-ui/core'
import { Button, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { client } from 'client'
import AppContext from 'containers/context/Context'
import { RootInitialState, RootReducer } from 'containers/context/Reducer'
import { loadingObservable } from 'containers/helper/loadingHelper'
import { snackBarObservable } from 'containers/helper/snackBarSubjectHelper'
import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { MainRoutes } from 'containers/routes/RootRoutes'
import { LocationRoute } from 'containers/routes/LocationRoute'
import NavigationProvider from './NavigationProvider'
import '@ifca-ui/core/src/styles/style.scss'
import 'assets/styles/app.scss'

export default function App() {
  const [rootState, rootDispatch] = React.useReducer(
    RootReducer,
    RootInitialState
    )

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
    ) => {
    if (reason === 'clickaway') {
      return
    }
    rootDispatch({
      type: 'snackBar',
      payload: {
        open: false,
        message: '',
      },
    })
  }

  useEffect(() => {
    loadingObservable().subscribe(x => {
      rootDispatch({
        type: 'loading',
        payload: x,
      })
    })
    snackBarObservable().subscribe(x => {
      if (x) {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: x,
          },
        })
      }
    })
  }, [])

  return (
    <ThemeProvider theme={Theme}>
      <ApolloProvider client={client}>
        <CssBaseline />
        <AppContext.Provider value={{ rootState, rootDispatch }}>
          {rootState?.loading ? <Spinner /> : null}
          <BrowserRouter>
            <NavigationProvider />
            <LocationRoute />
            <MainRoutes />
          </BrowserRouter>
          {/* <UICoreGlobalStyle /> */}
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            message={rootState.snackBar?.message}
            open={rootState.snackBar?.open}
            onClose={handleClose}
            autoHideDuration={2000}
            action={
              <Button color="secondary" size="small" onClick={handleClose}>
                DISMISS
              </Button>
            }
          />
        </AppContext.Provider>
      </ApolloProvider>
    </ThemeProvider>
  )
}
