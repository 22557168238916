import { Box } from '@mui/material'
import { FontWeights } from '../../styles/variables'
import { styled } from '@mui/system'

export const ModuleSection = styled(Box)`
  padding: 0.5rem;
  .MuiListItem-root {
    padding: 6px 8px;
    border: none;
    margin-bottom: 8px;
    cursor:pointer;
    &.active {
      background: #faf2e8;
      border: 1px solid #ff9800;
      border-radius: 3px;
      color: #ff9800;
      cursor:auto;
      .svg-icon {
        width: 23px;
        height: auto;
      }
      .MuiTypography-root {
        font-weight: ${FontWeights.semibold} !important;
      }
      .MuiListItemIcon-root {
        color: #fff;
      }
    }
    .svg-icon {
      width: 23px;
      height: auto;
    }
    .MuiListItemIcon-root {
      min-width: unset;
    }
    .MuiListItemText-root {
      padding-left: 11px;
      .MuiTypography-root {
        font-size: 14px;
        font-weight: ${FontWeights.regular};
      }
    }
  }
`
