import { Box, type BoxProps } from '@mui/material'
import { styled } from '@mui/system'

export const SubHeaderContainer = styled(Box)<BoxProps>`
  position: fixed;
  z-index: 100;
  width: 100%;
  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    top: 152px;
    right: 24px;
  }
  &.full-width {
    left: 24px;
    width: calc(100% - 48px);
  }
`
