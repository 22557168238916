import { Fab, type FabProps } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import { type BottomSpacing } from 'src/utils/types'
import { useHotkeys } from 'react-hotkeys-hook'

interface FloatButtonProps extends FabProps {
  /** Bottom spacing */
  bottomSpacing?: BottomSpacing | undefined
  showHeight?: boolean
}

const bottomSpacingStyles = (bottomSpacing: BottomSpacing): string => {
  switch (bottomSpacing) {
    case 'sm':
      return '68px'
    case 'md':
      return '100px'
    default:
      return '24px'
  }
}
export const StyledFab = styled(Fab)`
  &.MuiFab-root {
    position: fixed;
    right: 24px;
    /* bottom: ${({ bottomSpacing }: FloatButtonProps) =>
      bottomSpacingStyles(bottomSpacing as BottomSpacing) || '24px'}; */
    /* background: Colors.primaryOrange; */
    /* &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12) !important;
    } */
    .MuiSvgIcon-root {
      color: #ffffff !important;
    }
  }
`
export const FloatButton: React.FC<FloatButtonProps> = ({
  bottomSpacing,
  onClick,
  showHeight = true,
  ...props
}) => {
  const handleButtonClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    if (typeof onClick === 'function') {
      onClick(e as React.MouseEvent)
    }
  }
  useHotkeys(
    'n',
    e => {
      if (typeof onClick === 'function') {
        e.preventDefault()
        handleButtonClick(e)
      } else {
        handleButtonClick(e)
      }
    },
    { scopes: ['new'] }
  )

  const floatButtonRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)

  // reset height
  useEffect(() => {
    setHeight(floatButtonRef?.current?.clientHeight || 0)
  }, [floatButtonRef?.current, props])

  return (
    <>
      <StyledFab
        ref={floatButtonRef}
        data-testid="fab"
        {...props}
        sx={{
          bottom: bottomSpacingStyles(bottomSpacing as BottomSpacing) || '24px',
        }}
        onClick={e => handleButtonClick(e)}
      />
      {showHeight && <div style={{ height: `${height}px` }} />}
    </>
  )
}
