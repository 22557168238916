import { Header } from '@ifca-ui/core'
import HeaderBg from 'assets/images/header/header-bg.svg'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { useContext, useEffect, useRef, useState } from 'react'

type props = {
  isDesktop: boolean
}

const HeaderX = ({ isDesktop }: props) => {
  const headerBgURL = HeaderBg
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  const headerRef = useRef<HTMLDivElement>(null)

  //listen to window size on change
  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    const toolBar = headerRef?.current?.querySelector('.MuiToolbar-root')
    rootDispatch({
      type: 'headerHeight',
      payload: isDesktop ? toolBar?.clientHeight : 0,
    })
  }, [
    headerRef?.current,
    headerBgURL,
    rootState.headerComponent,
    windowSize,
    isDesktop,
  ])
  return (
    <div ref={headerRef}>
      <Header
        headerBgURL={headerBgURL}
        leftIcon={rootState.headerComponent?.leftIcon}
        rightIcon={rootState.headerComponent?.rightIcon}
        topSection={rootState.headerComponent?.topSection}
        bottomSection={rootState.headerComponent?.bottomSection}
      />
    </div>
  )
}

export default HeaderX
