import { BehaviorSubject } from "rxjs";

let snackBarSubject = new BehaviorSubject<string>("");

export const setSnackBar = (message: string) => {
  snackBarSubject.next(message);
};

export const snackBarObservable = () => {
  return snackBarSubject;
};