import { Alert as MuiAlert, type AlertProps } from '@mui/material'
import { styled } from '@mui/material/styles'

// https://mui.com/api/alert/
interface alertProps extends AlertProps {}
export const Alert = styled(MuiAlert)<alertProps>`
  padding: 10px 12px;
  .MuiAlert-icon {
    margin-right: 8px;
    padding: 0px;
  }
  .MuiAlert-message {
    padding: 0px;
    display: flex;
    align-items: center;
  }
  .MuiSvgIcon-root {
    width: 12px;
    height: 18px;
  }
  &.MuiAlert-standardWarning {
    background: #fff2d9;
  }
`
