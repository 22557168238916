import { Event as EventIcon } from '@mui/icons-material'
import { Button, type ButtonProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  Colors,
  FontSizes,
  FontWeights,
  LineHeights,
} from '../../../styles/variables'
const DatePickerButtonRoot = styled(Button)`
  background-color: ${Colors.white};
  padding: 4px 12px;
  font-size: ${FontSizes.fs_12};
  line-height: ${LineHeights.lh_16};
  font-weight: ${FontWeights.medium};
  min-width: 120px;
  color: #212121;
  &:hover {
    background-color: ${Colors.white};
  }
`
interface DatePickerButtonProps extends ButtonProps {}
export const DatePickerButton = ({
  ...props
}: DatePickerButtonProps): JSX.Element => {
  return (
    <DatePickerButtonRoot
      variant="contained"
      color="primary"
      endIcon={
        <EventIcon sx={{ color: '#212121', width: '18px', height: '18px' }} />
      }
      {...props}
    />
  )
}
