
import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { navigationService } from 'navigationService'

/**
 * @param public Routes
 * ex: login , forgotpassword , changepassword and etc.
 *
 */


export const LocationRoute: FC = () => {
  let location = useLocation();
  const nav = new navigationService()

  React.useEffect(() => {
    if (location) {
      nav.setNavigationPath({ path: location.pathname, state: '' })
    }
  }, [location]);
  return (<></>)
}
