import MuiAccordion, { type AccordionProps } from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, {
  type AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  // border: `1px solid ${theme.palette.divider}`,
  // '&:not(:last-child)': {
  //   borderBottom: 0,
  // },
  // '&:before': {
  //   display: 'none',
  // },
  marginBottom: '4px',
  borderRadius: '5px',
  boxShadow:
    '0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1)',
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }: any) => ({
  padding: '0px 12px',
  borderBottom: '1px solid #e6e6e6',
  '& .MuiAccordionSummary-content': {
    marginRight: theme.spacing(1),
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#212121',
  },
}))
//
export const Expansion = ({ ...props }: AccordionProps): JSX.Element => {
  return <Accordion {...props} />
}

export const ExpansionSummary = ({
  ...props
}: AccordionSummaryProps): JSX.Element => {
  return <AccordionSummary {...props} />
}

export const ExpansionDetails = styled(MuiAccordionDetails)(() => ({}))
