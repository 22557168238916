import type React from 'react'
import {
  ListItem as MuiListItem,
  ListItemButton,
  type ListItemButtonProps,
  type ListItemProps as MuiListItemProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const ListItemRoot = styled(MuiListItem)`
  margin-bottom: 4px;
  flex-wrap: wrap;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  & .MuiListItemSecondaryAction-root .MuiIconButton-root {
    padding: 0px;
    color: #212121;
  }
  /* & > .MuiListItemButton-root {
    padding-right: 50px;
  } */
  & .MuiListItemIcon-root {
    min-width: 0px;
    margin-right: 8px;
  }
  & .MuiListItemAvatar-root {
    min-width: 0px;
    margin-right: 8px;

    .MuiAvatar-root {
      background-color: #fff;
    }
  }
`
const ListItemButtonRoot = styled(ListItemButton)`
  padding: 10px 12px;
`
interface ListItemProps extends MuiListItemProps {
  children?: React.ReactNode
  itemButton?: ListItemButtonProps
}
export const ListItem = ({
  children,
  itemButton,
  ...props
}: ListItemProps): JSX.Element => {
  return (
    <ListItemRoot disablePadding {...props}>
      <ListItemButtonRoot
        {...itemButton}
        // sx={{
        //   padding: '10px 12px',
        // }}
      >
        {children}
      </ListItemButtonRoot>
    </ListItemRoot>
  )
}
