import {
  type AvatarProps,
  Avatar,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  type IconButtonProps,
  IconButton,
  Typography,
} from '@mui/material'
import type * as React from 'react'
import { styled } from '@mui/material/styles'

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'

const ProfileContainer = styled('div')`
  /* background-size: 100% 100% !important; */
  background: ${(props: ProfileSectionProps) =>
    props.drawerImage ? `url("${props.drawerImage}")` : '#f5f5f5'};
`
const AvatarSection = styled(Box)`
  padding: 20px 16px 30px;
  margin: 0;
  border-radius: unset;
  .MuiAvatar-colorDefault {
    color: #fafafa;
    background-color: #212121 !important;
  }
  .avatar {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 100%;
  }
`
const StyledAvatar = styled(Avatar)`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 100%;
`
const StyledList = styled(List)`
  padding: 0px;
  background: #00000075;
  .MuiListItem-root {
    padding: 8px 48px 8px 16px;

    .MuiListItemText-root {
      margin: 0px;
      .MuiTypography-root {
        display: flex;
        color: #fff;
      }
    }
  }
  .MuiIconButton-root {
    color: #fff;
  }
`

export interface ProfileSectionProps {
  drawerImage?: string
  avatar?: AvatarProps
  profile?: {
    title?: string
    subTitle?: string
  }
  dropDownIcon?: IconButtonProps
}
export const ProfileSection: React.FC<ProfileSectionProps> = ({
  drawerImage,
  avatar,
  profile,
  dropDownIcon,
}) => {
  return (
    <ProfileContainer drawerImage={drawerImage}>
      <AvatarSection>
        <StyledAvatar {...avatar} />
      </AvatarSection>
      <StyledList disablePadding>
        <ListItem>
          <ListItemText
            primary={
              <Typography
                component="span"
                variant="inherit"
                className="text-smTitle"
              >
                {profile?.title}
              </Typography>
            }
            secondary={
              <Typography
                component="span"
                variant="inherit"
                className="text-desc"
              >
                {profile?.subTitle}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="simple-menu" {...dropDownIcon}>
              <ExpandMoreIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </StyledList>
    </ProfileContainer>
  )
}
