import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const NotificationPermission = lazy(() => import('./NotificationPermission'))

export const NOTIFICATION_PATH = {
  PERMISSION: '/permission',
}

const PermissionRoutes: RouteObject[] = [
  {
    path: '' ,
    element: <NotificationPermission />,
  },
]

export default PermissionRoutes
