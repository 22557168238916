import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material'
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  type ListItemProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import type * as React from 'react'

const SelectionRoot = styled(List)`
  width: 100%;
  z-index: 100;
  background: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);

  .MuiListItem-root {
    padding: 10px 12px;
  }
  .MuiListItemText-root {
    margin: 0px;
    display: flex;
    padding: 1.5px 0px;
  }
  .MuiIconButton-edgeEnd {
    padding: 8px;
    margin-right: -8px;
  }
  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
`

interface SelectionBarProps {
  title?: React.ReactNode
  selectionItem?: ListItemProps
}

export const SelectionBar = ({
  title,
  selectionItem,
}: SelectionBarProps): JSX.Element => {
  return (
    <SelectionRoot disablePadding id="selection-bar">
      <ListItem
        {...selectionItem}
        secondaryAction={
          <IconButton edge="end" sx={{ padding: 0 }}>
            <ArrowDropDownIcon sx={{ color: '#212121' }} />
          </IconButton>
        }
      >
        <ListItemButton sx={{ padding: '0px' }}>
          <ListItemText disableTypography primary={<> {title} </>} />
        </ListItemButton>
      </ListItem>
    </SelectionRoot>
  )
}
