import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSONObject: any;
  Upload: any;
};

export type AccountSpecEntity = {
  __typename?: 'accountSpecEntity';
  accountCode?: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  id: Scalars['String'];
  integrationAutoCapitalize: Scalars['Boolean'];
  leadAssignmentActivated: Scalars['Boolean'];
  loanPlusInfo: Scalars['JSONObject'];
  loanPlusProjectId?: Maybe<Scalars['Float']>;
  projectIntegration?: Maybe<Array<ProjectIntegrationEntity>>;
  purchaserAccountRef?: Maybe<PurchaserAccountReferenceEntity>;
  purchaserAppCss?: Maybe<PurchaserAppCssOutput>;
  smptInfo?: Maybe<SmtpInfoOutput>;
};

export type ActiveLoanStatusOutput = {
  __typename?: 'ActiveLoanStatusOutput';
  assignedCount: Scalars['Float'];
  count: Scalars['Float'];
  docsSubmittedCount: Scalars['Float'];
  loanAppealedCount: Scalars['Float'];
  loanDocSignedCount: Scalars['Float'];
  loanIssuedCount: Scalars['Float'];
  loanSignedCount: Scalars['Float'];
  openCount: Scalars['Float'];
  pendingDocsCount: Scalars['Float'];
  unassignedCount: Scalars['Float'];
  unreachableCount: Scalars['Float'];
};

export type AddEmailLogSchemeInput = {
  bccEmails?: InputMaybe<Scalars['String']>;
  ccEmails?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  errorMessage?: InputMaybe<Scalars['String']>;
  isEmailSent: Scalars['Boolean'];
  recipientEmail: Scalars['String'];
  refId: Scalars['String'];
  refType: Scalars['String'];
  subject: Scalars['String'];
};

/** JsonType */
export type Address = {
  __typename?: 'Address';
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  address?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  AddressType?: InputMaybe<AddressType>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['String']>;
  postCodeName?: InputMaybe<Scalars['String']>;
  siteName?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type AddressJson = {
  __typename?: 'AddressJson';
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  AddressType?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AddressJsonInput = {
  address?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  AddressType?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  postCode?: InputMaybe<Scalars['String']>;
  postCodeName?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export enum AddressType {
  Delivery = 'DELIVERY',
  Primary = 'PRIMARY',
  Site = 'SITE'
}

export type AgencyAppointedCount = {
  __typename?: 'agencyAppointedCount';
  lastAppointedDate?: Maybe<Scalars['DateTime']>;
  totalAppointed?: Maybe<Scalars['Float']>;
};

export type AgencyAppointedEntity = AuditEntity & {
  __typename?: 'agencyAppointedEntity';
  accountId: Scalars['String'];
  address: AddressJson;
  agencyContract: Array<AgencyContractEntity>;
  agencyContractProjectCount?: Maybe<Scalars['Float']>;
  agencyId?: Maybe<Scalars['String']>;
  agencyUnitCounts?: Maybe<AgentUnitCounts>;
  agentNegotiator: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  contactEmail: Scalars['String'];
  contactName: Scalars['String'];
  contactPhoneNo: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['String'];
  invitationLink: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  officeTel: Scalars['String'];
  pictureBlockUrl?: Maybe<Scalars['String']>;
  projectAgencyDiscounts?: Maybe<Array<ProjectAgencyDiscountEntity>>;
  projectAgencyPackages?: Maybe<Array<ProjectAgencyPackageEntity>>;
  projectAgencyRebates?: Maybe<Array<ProjectAgencyRebateEntity>>;
  sales?: Maybe<Array<SaleEntity>>;
};


export type AgencyAppointedEntityAgencyUnitCountsArgs = {
  projectId: Scalars['String'];
};


export type AgencyAppointedEntityPictureBlockUrlArgs = {
  block: Scalars['String'];
};

export type AgencyContactEntity = AuditEntity & {
  __typename?: 'agencyContactEntity';
  agencyAppointed: AgencyAppointedEntity;
  agencyUserRole: AgencyUserRoleEntity;
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDeletable: Scalars['Boolean'];
  isSuspendible: Scalars['Boolean'];
  languageSet: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  phoneNo?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  reportingTo?: Maybe<Scalars['String']>;
  reportingToUserId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  user: AgencyUserEntity;
  userId: Scalars['String'];
};

export type AgencyContractEntity = AuditEntity & {
  __typename?: 'agencyContractEntity';
  accountId: Scalars['String'];
  agency: AgencyAppointedEntity;
  agencyId: Scalars['String'];
  allocatedUnitId: Array<Scalars['String']>;
  allowUpdateLoanStatus: Scalars['Boolean'];
  allowWaitinglist: Scalars['Boolean'];
  attachment?: Maybe<Array<AttachmentEntity>>;
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id: Scalars['String'];
  isDiscountAllocated: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  isPackageAllocated: Scalars['Boolean'];
  isRebateAllocated: Scalars['Boolean'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  project: ProjectEntity;
  projectId: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type AgencyEntity = AuditEntity & {
  __typename?: 'agencyEntity';
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
};

export type AgencyLeadNotificationInput = {
  loanAssignment?: InputMaybe<LoanAssignmentInput>;
};

export type AgencyLeadNotificationOutput = {
  __typename?: 'AgencyLeadNotificationOutput';
  loanAssignment?: Maybe<LoanAssignmentOutput>;
};

export type AgencyNotificationEntity = {
  __typename?: 'agencyNotificationEntity';
  accountId: Scalars['String'];
  agencyNotificationRecipient: Array<AgencyNotificationRecipientEntity>;
  body: Scalars['String'];
  id: Scalars['String'];
  permissionId: Scalars['Float'];
  projectId: Scalars['String'];
  subject: Scalars['String'];
};

export type AgencyNotificationPolicyEntity = {
  __typename?: 'agencyNotificationPolicyEntity';
  accountId: Scalars['String'];
  id: Scalars['String'];
  notificationDuration: Scalars['Float'];
  permissionId: Scalars['Float'];
  userId: Scalars['String'];
};

export type AgencyNotificationRecipientEntity = AuditEntity & {
  __typename?: 'agencyNotificationRecipientEntity';
  accountId: Scalars['String'];
  agencyNotification: AgencyNotificationEntity;
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  isRead: Scalars['Boolean'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  notificationId: Scalars['String'];
  projectId: Scalars['String'];
  recipientId: Scalars['String'];
};

export type AgencySalesChartOutput = {
  __typename?: 'agencySalesChartOutput';
  coordinates: Scalars['String'];
  price: Scalars['Float'];
  status?: Maybe<Scalars['String']>;
  unit_id: Scalars['String'];
  unit_no: Scalars['String'];
  unit_status: Scalars['String'];
  unit_type: Scalars['String'];
};

export type AgencyUnitAllocationEntity = AuditEntity & {
  __typename?: 'agencyUnitAllocationEntity';
  accountId: Scalars['String'];
  agency: AgencyEntity;
  agencyId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  project: ProjectEntity;
  projectId: Scalars['String'];
  unit: UnitEntity;
  unitId: Scalars['String'];
};

export type AgencyUserEntity = AuditEntity & {
  __typename?: 'agencyUserEntity';
  accessToken?: Maybe<Scalars['String']>;
  activated: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  forgotPasswordLink?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  loginId: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  passwordHash?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type AgencyUserRoleEntity = AuditEntity & {
  __typename?: 'agencyUserRoleEntity';
  agency: AgencyEntity;
  agencyId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  reportPerson: AgencyUserEntity;
  reportPersonId?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  user: AgencyUserEntity;
  userId: Scalars['String'];
};

export type AgentPermissionEntity = AuditEntity & {
  __typename?: 'agentPermissionEntity';
  agency: AgencyEntity;
  agencyContact?: Maybe<AgencyContactEntity>;
  agencyId: Scalars['String'];
  agencyUser: AgencyUserEntity;
  agencyUserId: Scalars['String'];
  allowBook: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  project: ProjectEntity;
  projectId: Scalars['String'];
};

export type AgentUnitCounts = {
  __typename?: 'agentUnitCounts';
  allocatedunit?: Maybe<Scalars['Float']>;
  bookedunit?: Maybe<Scalars['Float']>;
  spasignedunit?: Maybe<Scalars['Float']>;
};

export type AllocationEntity = {
  __typename?: 'allocationEntity';
  accountId: Scalars['String'];
  allocateAmount: Scalars['Float'];
  commonStatus: Scalars['String'];
  contra?: Maybe<ContraEntity>;
  creditId: Scalars['String'];
  creditNote?: Maybe<CreditNoteEntity>;
  creditRefTable: Scalars['String'];
  debitId: Scalars['String'];
  debitNote?: Maybe<DebitNoteEntity>;
  debitRefTable: Scalars['String'];
  financialBillingId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  interest?: Maybe<LateInterestEntity>;
  miscBilling?: Maybe<MiscBillingEntity>;
  progressBilling?: Maybe<ProgressBillingEntity>;
  project: ProjectEntity;
  projectId: Scalars['String'];
  rebate?: Maybe<RebateEntity>;
  receipt?: Maybe<ReceiptEntity>;
  refund?: Maybe<RefundEntity>;
};

export type AllStandardCoreBaseEntity = StandardAuditEntity & {
  __typename?: 'AllStandardCoreBaseEntity';
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  ID: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
};

export type Amenities = {
  __typename?: 'Amenities';
  category: Scalars['String'];
  items: Array<Scalars['String']>;
};

export type AmenitiesInput = {
  category: Scalars['String'];
  items: Array<Scalars['String']>;
};

export type AppointmentEntity = {
  __typename?: 'AppointmentEntity';
  accountId: Scalars['String'];
  appointmentDate: Scalars['DateTime'];
  appointmentPurpose: AppointmentTypeEntity;
  appointmentTypeId: Scalars['String'];
  commonStatus: Scalars['String'];
  contactId: Scalars['String'];
  createdBy: Scalars['String'];
  createdTs: Scalars['DateTime'];
  endTime: Scalars['String'];
  id: Scalars['String'];
  lead: LeadContactEntity;
  leadId: Scalars['String'];
  leadSourceFromLead?: Maybe<LeadEntity>;
  modBy: Scalars['String'];
  modTs: Scalars['DateTime'];
  needManagerAssistant: Scalars['Boolean'];
  participantLimit: Scalars['Float'];
  project: ProjectEntity;
  projectId: Scalars['String'];
  remarks: Scalars['String'];
  salePerson: SUserEntity;
  salesPersonId: Scalars['String'];
  salesPersonOrigin: Scalars['String'];
  saleTeam: SaleTeamEntity;
  startTime: Scalars['String'];
  teamId: Scalars['String'];
};

export type AppointmentSchedulingEntity = {
  __typename?: 'appointmentSchedulingEntity';
  accountId: Scalars['String'];
  endTime: Scalars['String'];
  id: Scalars['String'];
  participantLimit: Scalars['Float'];
  projectId: Scalars['String'];
  salesGalleryCloseDate: Array<SalesGalleryCloseDateEntity>;
  startTime: Scalars['String'];
};

export type AppointmentTypeEntity = {
  __typename?: 'AppointmentTypeEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  seqNo: Scalars['Float'];
  systemUse: Scalars['Boolean'];
};

/** JsonType */
export type ApprovalListJson = {
  __typename?: 'ApprovalListJson';
  predicate?: Maybe<Scalars['String']>;
  roleId: Scalars['String'];
  roleName?: Maybe<Scalars['String']>;
  stepNo: Scalars['Float'];
};

export type ApprovalListJsonInput = {
  predicate?: InputMaybe<Scalars['String']>;
  roleId: Scalars['String'];
  roleName?: InputMaybe<Scalars['String']>;
  stepNo: Scalars['Float'];
};

export type ApprovalPolicyAssignmentEntity = {
  __typename?: 'approvalPolicyAssignmentEntity';
  accountId: Scalars['String'];
  approvalList: Array<ApprovalListJson>;
  category: Scalars['String'];
  commonStatus: Scalars['String'];
  entityId: Scalars['String'];
  id: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ApprovalPolicyEntity = {
  __typename?: 'approvalPolicyEntity';
  accountId: Scalars['String'];
  approvalList: Array<ApprovalListJson>;
  category: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ArchitectCertEntity = {
  __typename?: 'architectCertEntity';
  accountId: Scalars['String'];
  architectCertUnit?: Maybe<Array<ArchitectCertUnitEntity>>;
  architectCertWorkProgress?: Maybe<Array<ArchitectCertWorkProgressEntity>>;
  certificateDate: Scalars['DateTime'];
  certificateNo: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  projectId: Scalars['String'];
};

export type ArchitectCertUnitEntity = {
  __typename?: 'architectCertUnitEntity';
  accountId: Scalars['String'];
  architectCert: ArchitectCertEntity;
  architectCertId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  projectId: Scalars['String'];
  status: Scalars['String'];
  unitId: Scalars['String'];
  units?: Maybe<UnitEntity>;
};

export type ArchitectCertWorkProgressEntity = {
  __typename?: 'architectCertWorkProgressEntity';
  accountId: Scalars['String'];
  architectCertId: Scalars['String'];
  architectCerts: ArchitectCertEntity;
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  paymentPlanDetail?: Maybe<Array<PaymentPlanDetailEntity>>;
  progress: Array<WorkProgressEntity>;
  projectId: Scalars['String'];
  workProgressId: Scalars['String'];
};

export type AreaVarianceEntity = {
  __typename?: 'areaVarianceEntity';
  accountId: Scalars['String'];
  actualBuiltUp: Scalars['Float'];
  actualLandArea: Scalars['Float'];
  builtUp: Scalars['Float'];
  commonStatus: Scalars['String'];
  compensateAmount: Scalars['Float'];
  documentNo?: Maybe<Scalars['String']>;
  formula: Scalars['String'];
  id: Scalars['String'];
  landArea: Scalars['Float'];
  projectId: Scalars['String'];
  refId?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  saleId: Scalars['String'];
  sales?: Maybe<SaleEntity>;
};

export type AssignedBatchDetails = {
  __typename?: 'AssignedBatchDetails';
  batch_id: Scalars['String'];
  batch_title: Scalars['String'];
  countLeadSource?: Maybe<LeadBatchCountLeadSourceOutput>;
  createdTs?: Maybe<Scalars['DateTime']>;
  isRoundRobinUser?: Maybe<Scalars['Boolean']>;
  projects?: Maybe<Array<AssignedProjectDetails>>;
  record_count: Scalars['Float'];
};

export type AssignedProjectDetails = {
  __typename?: 'AssignedProjectDetails';
  assigned_date: Scalars['DateTime'];
  batch_id: Scalars['String'];
  lead_count: Scalars['Float'];
  project_name: Scalars['String'];
};

export type AttachmentEntity = AuditEntity & {
  __typename?: 'attachmentEntity';
  accountId: Scalars['String'];
  allowDelete?: Maybe<Scalars['Boolean']>;
  bucketFileName: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  fileName: Scalars['String'];
  id: Scalars['String'];
  isPrivate: Scalars['Boolean'];
  mediaType: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  refId: Scalars['String'];
  refTable: Scalars['String'];
  refType: Scalars['String'];
  resourceUrl: Scalars['String'];
  seqNo: Scalars['Float'];
  softwareCode: Scalars['String'];
};

export type AttachmentOutput = {
  __typename?: 'AttachmentOutput';
  brochureUrl?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type AuditEntity = {
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
};

export type AutoReleaseNotificationReminderInput = {
  agencyUserReceive?: InputMaybe<Scalars['Boolean']>;
  reminderDay?: InputMaybe<Scalars['Float']>;
  salesUserReceive?: InputMaybe<Scalars['Boolean']>;
};

export type AutoReleaseNotificationReminderOutput = {
  __typename?: 'autoReleaseNotificationReminderOutput';
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  reminderDay?: Maybe<Scalars['Float']>;
  salesUserReceive?: Maybe<Scalars['Boolean']>;
};

export type BankEntity = {
  __typename?: 'bankEntity';
  accountId: Scalars['String'];
  code: Scalars['String'];
  commonStatus: Scalars['String'];
  financier: Array<FinancierEntity>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type BaseInput = {
  commonStatus?: InputMaybe<CommonStatus>;
  createdBy?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
};

export type BillItemEntity = {
  __typename?: 'billItemEntity';
  accountId: Scalars['String'];
  billItemType: Scalars['String'];
  classificationId?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  msicId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  systemUse: Scalars['Boolean'];
};

export type BookingAttachmentsOutput = {
  __typename?: 'bookingAttachmentsOutput';
  bookingCcrAttachments: Array<AttachmentEntity>;
  bookingFeeAttachments: Array<AttachmentEntity>;
  bookingNricAttachments: Array<AttachmentEntity>;
  bookingPdpaAttachments: Array<AttachmentEntity>;
  solicitorAgreementAttachments: Array<AttachmentEntity>;
  solicitorOthersAttachments: Array<AttachmentEntity>;
};

export type BookingCancellationNotificationInput = {
  agencyUserReceive?: InputMaybe<Scalars['Boolean']>;
  purchaserReceive?: InputMaybe<Scalars['Boolean']>;
  salesUserReceive?: InputMaybe<Scalars['Boolean']>;
};

export type BookingCancellationNotificationOutput = {
  __typename?: 'BookingCancellationNotificationOutput';
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  purchaserReceive?: Maybe<Scalars['Boolean']>;
  salesUserReceive?: Maybe<Scalars['Boolean']>;
};

export type BookingConfirmationStatusOutput = {
  __typename?: 'BookingConfirmationStatusOutput';
  cancelledCount: Scalars['Float'];
  eLaunchAppCount: Scalars['Float'];
  integrationCount: Scalars['Float'];
  pendingCount?: Maybe<Scalars['Float']>;
  purchaserAppCount: Scalars['Float'];
  reservationCount: Scalars['Float'];
  total: Scalars['Float'];
};

export type BookingLeadsOutput = {
  __typename?: 'BookingLeadsOutput';
  contact_id: Scalars['String'];
  email: Scalars['String'];
  full_name: Scalars['String'];
  ic_no?: Maybe<Scalars['String']>;
  lead_id?: Maybe<Scalars['String']>;
  lead_source_id?: Maybe<Scalars['String']>;
  military_ic?: Maybe<Scalars['String']>;
  nationality_id?: Maybe<Scalars['String']>;
  phone_no: Scalars['String'];
};

export type BookingListDataOutput = {
  __typename?: 'BookingListDataOutput';
  agentPersonName?: Maybe<Scalars['String']>;
  bookingRemark?: Maybe<Scalars['String']>;
  builtUp: Scalars['String'];
  buyerCompanyName?: Maybe<Scalars['String']>;
  buyerCompanyRegNo?: Maybe<Scalars['String']>;
  buyerEmail?: Maybe<Scalars['String']>;
  buyerId: Scalars['String'];
  buyerName: Scalars['String'];
  buyerNric: Scalars['String'];
  buyerPhoneNo?: Maybe<Scalars['String']>;
  cancelledByName?: Maybe<Scalars['String']>;
  cancelledDate?: Maybe<Scalars['DateTime']>;
  cancelledReason?: Maybe<Scalars['String']>;
  isCompany?: Maybe<Scalars['Boolean']>;
  isPurchaserInfoComplete?: Maybe<Scalars['Boolean']>;
  listPrice: Scalars['String'];
  paidAmount?: Maybe<Scalars['Float']>;
  paymentMethod?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  saleDate: Scalars['DateTime'];
  salePaymentId?: Maybe<Scalars['String']>;
  salePaymentStatus?: Maybe<Scalars['String']>;
  salePersonName?: Maybe<Scalars['String']>;
  salesId: Scalars['String'];
  salesNo: Scalars['String'];
  salesStatus: Scalars['String'];
  sellingPrice: Scalars['String'];
  unitId: Scalars['String'];
  unitLayout?: Maybe<Scalars['String']>;
  unitNo: Scalars['String'];
};

export type BookingListLoanScreeningOutput = {
  __typename?: 'BookingListLoanScreeningOutput';
  caseCode: Scalars['String'];
  CCRIS?: Maybe<Scalars['String']>;
  CTOS?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type BookingNotificationInput = {
  assignmentAgencyUserReceive?: InputMaybe<Scalars['Boolean']>;
  assignmentPurchaserReceive?: InputMaybe<Scalars['Boolean']>;
  assignmentSalesUserReceive?: InputMaybe<Scalars['Boolean']>;
  attachBookingAttachment?: InputMaybe<Scalars['Boolean']>;
  attachTr?: InputMaybe<Scalars['Boolean']>;
};

export type BookingNotificationOutput = {
  __typename?: 'BookingNotificationOutput';
  assignmentAgencyUserReceive?: Maybe<Scalars['Boolean']>;
  assignmentPurchaserReceive?: Maybe<Scalars['Boolean']>;
  assignmentSalesUserReceive?: Maybe<Scalars['Boolean']>;
  attachBookingAttachment?: Maybe<Scalars['Boolean']>;
  attachTr?: Maybe<Scalars['Boolean']>;
};

export type BookingWaitlistEntity = {
  __typename?: 'bookingWaitlistEntity';
  accountId: Scalars['String'];
  agency?: Maybe<UserEntity>;
  agencyAcctId?: Maybe<Scalars['String']>;
  agentPersonId?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  contact: LeadContactEntity;
  contactId: Scalars['String'];
  id: Scalars['String'];
  lead: LeadEntity;
  leadId: Scalars['String'];
  leaveTime?: Maybe<Scalars['DateTime']>;
  project: ProjectEntity;
  queueTime: Scalars['DateTime'];
  reason?: Maybe<Scalars['String']>;
  releaseDate?: Maybe<Scalars['DateTime']>;
  salesPerson?: Maybe<UserEntity>;
  salesPersonId: Scalars['String'];
  seqno: Scalars['Float'];
  team: SaleTeamEntity;
  teamId: Scalars['String'];
  unit: UnitEntity;
};

export type BookStatusOutput = {
  __typename?: 'BookStatusOutput';
  bookedCount?: Maybe<Scalars['Float']>;
  cancelledCount?: Maybe<Scalars['Float']>;
  reserveCount?: Maybe<Scalars['Float']>;
  signedCount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  totalSellingPrice?: Maybe<Scalars['Float']>;
};

export type BridgingFinancierEntity = {
  __typename?: 'bridgingFinancierEntity';
  accountId: Scalars['String'];
  bridgingFinancierUnit: Array<BridgingFinancierUnitEntity>;
  chargeExecutionType: Scalars['String'];
  chargeExecutionValue: Scalars['Float'];
  commonStatus: Scalars['String'];
  financierId: Scalars['String'];
  financierReferenceNo: Scalars['String'];
  id: Scalars['String'];
  projectId: Scalars['String'];
  solicitorId: Scalars['String'];
  solicitorReferenceNo: Scalars['String'];
};

export type BridgingFinancierUnitEntity = {
  __typename?: 'bridgingFinancierUnitEntity';
  accountId: Scalars['String'];
  amount: Scalars['Float'];
  bridgingFinancier: BridgingFinancierEntity;
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  projectBridgingFinancierId: Scalars['String'];
  projectId: Scalars['String'];
  unitId: Scalars['String'];
};

export type CampaignEntity = {
  __typename?: 'campaignEntity';
  accountId: Scalars['String'];
  commonStatus?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  events: Array<EventsEntity>;
  id: Scalars['String'];
  name: Scalars['String'];
  projectId?: Maybe<Scalars['String']>;
  saleRebatePackage: SaleRebatePackageEntity;
  startDate: Scalars['DateTime'];
};

export type CityMasterDataOutput = {
  __typename?: 'CityMasterDataOutput';
  commonStatus: Scalars['String'];
  ID: Scalars['String'];
  name: Scalars['String'];
  stateID: Scalars['String'];
};

export type CloudStorageOutput = {
  __typename?: 'CloudStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  MediaType: Scalars['String'];
  PrivateUrl?: Maybe<Scalars['String']>;
  Url: Scalars['String'];
};

export type CollectionActivityEntity = {
  __typename?: 'collectionActivityEntity';
  accountId: Scalars['String'];
  activityDate: Scalars['DateTime'];
  activityRemark: Scalars['String'];
  activityStatus: Scalars['String'];
  commonStatus: Scalars['String'];
  completedDate: Scalars['DateTime'];
  id: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Block = 'BLOCK',
  Inactive = 'INACTIVE'
}

export type CompanyArAgingPolicyEntity = {
  __typename?: 'companyArAgingPolicyEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  company: CompanyEntity;
  companyId: Scalars['String'];
  id: Scalars['String'];
  period1: Scalars['Float'];
  period2: Scalars['Float'];
  period3: Scalars['Float'];
  period4: Scalars['Float'];
  period5: Scalars['Float'];
  period6: Scalars['Float'];
};

export type CompanyBankAccountEntity = {
  __typename?: 'companyBankAccountEntity';
  accountId: Scalars['String'];
  accountNo: Scalars['String'];
  accountType: Scalars['String'];
  bankProfile: CompanyBankProfileEntity;
  bankProfileId: Scalars['String'];
  code: Scalars['String'];
  collection?: Maybe<Array<ReceiptEntity>>;
  commonStatus: Scalars['String'];
  companyId: Scalars['String'];
  currency: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  id: Scalars['String'];
  receiptReversal?: Maybe<Array<ReceiptReversalEntity>>;
  swiftCode: Scalars['String'];
};

export type CompanyBankProfileEntity = {
  __typename?: 'companyBankProfileEntity';
  accountId: Scalars['String'];
  address: Scalars['String'];
  bankAccount: Array<CompanyBankAccountEntity>;
  commonStatus: Scalars['String'];
  company: CompanyEntity;
  companyId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  phoneNo: Scalars['String'];
};

export type CompanyEntity = {
  __typename?: 'companyEntity';
  accountId: Scalars['String'];
  address?: Maybe<AddressJson>;
  bankProfile: Array<CompanyBankProfileEntity>;
  baseCurrencyId: Scalars['String'];
  code: Scalars['String'];
  companyArAgingPolicy?: Maybe<CompanyArAgingPolicyEntity>;
  companyRegNo?: Maybe<Scalars['String']>;
  companyTax?: Maybe<CompanyTax>;
  contactNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  development: ProjectEntity;
  eInvClientId?: Maybe<Scalars['String']>;
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['DateTime']>;
  eInvStartDate?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  groupGstRegNo?: Maybe<Scalars['String']>;
  gstExpiryDate?: Maybe<Scalars['DateTime']>;
  gstNo?: Maybe<Scalars['String']>;
  holding: ProjectEntity;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  imageLogoPosition?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isEinvIntegrated: Scalars['Boolean'];
  isoImplementation?: Maybe<Scalars['Boolean']>;
  isoLogoPosition?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  officeTel?: Maybe<Scalars['String']>;
  parentCompanyId?: Maybe<Scalars['String']>;
  recordStatus: Scalars['String'];
  regionId?: Maybe<Scalars['String']>;
  sstNo?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  township: Array<TownshipEntity>;
  watermarkImplementation?: Maybe<Scalars['Boolean']>;
  watermarkWording?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

export type CompanyNumberSetupEntity = {
  __typename?: 'companyNumberSetupEntity';
  companyId: Scalars['String'];
  id: Scalars['String'];
  nextNumber: Scalars['Float'];
  numberFormat: NumberFormatEntity;
  numberFormatId: Scalars['String'];
};

export enum CompanyTax {
  GstNo = 'GST_NO',
  NoTax = 'NO_TAX',
  SstNo = 'SST_NO'
}

export type Contact = {
  __typename?: 'Contact';
  contactId: Scalars['String'];
  contactInfo?: Maybe<FinancierContactEntity>;
  date?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  link: Scalars['String'];
};

export type ContactAddressEntity = {
  __typename?: 'contactAddressEntity';
  accountId: Scalars['String'];
  address: AddressJson;
  contact: ContactEntity;
  contactId: Scalars['String'];
  id: Scalars['String'];
  isPrimary: Scalars['Boolean'];
};

export type ContactEntity = {
  __typename?: 'contactEntity';
  accountId: Scalars['String'];
  address?: Maybe<AddressJson>;
  addressList?: Maybe<Array<ContactAddressEntity>>;
  bookingWaitlists: Array<BookingWaitlistEntity>;
  commonStatus: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  companyRegNo?: Maybe<Scalars['String']>;
  contactAddress?: Maybe<Array<AddressJson>>;
  contactExt?: Maybe<ContactExtensionEntity>;
  dob?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  homeTel?: Maybe<Scalars['String']>;
  icNo?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  incomeRange?: Maybe<IncomeRangeEntity>;
  isPurchaserRegisted?: Maybe<Scalars['Boolean']>;
  jointBuyer?: Maybe<JointBuyerEntity>;
  jointBuyers: Array<JointBuyerEntity>;
  jointBuyerSignature?: Maybe<Scalars['String']>;
  leads?: Maybe<Array<LeadEntity>>;
  maritalStatus?: Maybe<MaritalStatusEntity>;
  militaryIc?: Maybe<Scalars['String']>;
  nationality?: Maybe<SNationalityEntity>;
  officeTel?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  race?: Maybe<RaceEntity>;
  sales: Array<SaleEntity>;
  salutation?: Maybe<SalutationEntity>;
  signatureUrl?: Maybe<Scalars['String']>;
  spouse?: Maybe<ContactEntity>;
};


export type ContactEntityLeadsArgs = {
  project_id?: InputMaybe<Scalars['String']>;
};

export type ContactExtensionEntity = AuditEntity & {
  __typename?: 'contactExtensionEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  contact?: Maybe<ContactEntity>;
  contactId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  customerOrigin: Scalars['String'];
  id: Scalars['String'];
  identityType: Scalars['String'];
  isBumi: Scalars['Boolean'];
  isForeignPurchase: Scalars['Boolean'];
  isVip: Scalars['Boolean'];
  mailingMethod: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  remark?: Maybe<Scalars['String']>;
  sstNo?: Maybe<Scalars['String']>;
  subscribeMaterialEmail: Scalars['Boolean'];
  subscribeMaterialPaNews: Scalars['Boolean'];
  subscribeMaterialSms: Scalars['Boolean'];
  subscribeMaterialWhatsapp: Scalars['Boolean'];
  tin?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  associateID?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  associateID?: InputMaybe<Scalars['String']>;
  contactNo?: InputMaybe<Scalars['String']>;
  designation?: InputMaybe<Scalars['String']>;
  docRefTable?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ContraEntity = {
  __typename?: 'contraEntity';
  accountId: Scalars['String'];
  allocation?: Maybe<Array<AllocationEntity>>;
  allowReversal: Scalars['Boolean'];
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  billItemId: Scalars['String'];
  commonStatus: Scalars['String'];
  description: Scalars['String'];
  documentAmount: Scalars['Float'];
  documentDate: Scalars['DateTime'];
  documentNo?: Maybe<Scalars['String']>;
  documentRef?: Maybe<Scalars['String']>;
  glGenerated: Scalars['String'];
  glGenerationBatch?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  projectId: Scalars['String'];
  requestedBy?: Maybe<Scalars['String']>;
  saleId: Scalars['String'];
  status: Scalars['String'];
  submittedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  ID: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
};

export type CountryMasterDataOutput = {
  __typename?: 'CountryMasterDataOutput';
  code: Scalars['String'];
  commonStatus: Scalars['String'];
  ID: Scalars['String'];
  name: Scalars['String'];
};

export type CountWaitingUnitOutput = {
  __typename?: 'CountWaitingUnitOutput';
  activeUnits?: Maybe<Scalars['Float']>;
  leaveQueue?: Maybe<Scalars['Float']>;
  waitList?: Maybe<Scalars['Float']>;
};

export type CreateAuditInput = {
  createdBy?: InputMaybe<Scalars['String']>;
};

export type CreditNoteEntity = {
  __typename?: 'creditNoteEntity';
  accountId: Scalars['String'];
  adjustDocumentReference?: Maybe<Scalars['String']>;
  allocation?: Maybe<Array<AllocationEntity>>;
  allowReversal: Scalars['Boolean'];
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  billItemId?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  documentAmount: Scalars['Float'];
  documentDate: Scalars['DateTime'];
  documentNo?: Maybe<Scalars['String']>;
  documentRef?: Maybe<Scalars['String']>;
  glGenerated: Scalars['String'];
  glGenerationBatch?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isReversal: Scalars['Boolean'];
  projectId: Scalars['String'];
  requestedBy?: Maybe<Scalars['String']>;
  saleId: Scalars['String'];
  status: Scalars['String'];
  submittedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
};

export type CurrencyExchangeRateEntity = {
  __typename?: 'currencyExchangeRateEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  currencyLog?: Maybe<Array<CurrencyLogObject>>;
  exchangeRate: Scalars['Float'];
  fromAmt: Scalars['Float'];
  fromCurrencyCode: Scalars['String'];
  id: Scalars['String'];
  toAmt: Scalars['Float'];
  toCurrencyCode: Scalars['String'];
};

export type CurrencyLogInput = {
  exchange_rate: Scalars['Float'];
  from_amt: Scalars['Float'];
  modifiedBy: Scalars['String'];
  modifiedDate: Scalars['DateTime'];
  modifiedName: Scalars['String'];
  to_amt: Scalars['Float'];
};

/** JsonType */
export type CurrencyLogObject = {
  __typename?: 'currencyLogObject';
  exchange_rate: Scalars['Float'];
  from_amt: Scalars['Float'];
  modifiedBy: Scalars['String'];
  modifiedDate: Scalars['DateTime'];
  modifiedName: Scalars['String'];
  to_amt: Scalars['Float'];
};

export type DataSource = {
  __typename?: 'DataSource';
  Columns?: Maybe<Array<Scalars['String']>>;
  DataMember: Scalars['String'];
  DataSource: Scalars['String'];
  DynamicFilterColumn?: Maybe<Scalars['String']>;
  IsMainQuery?: Maybe<Scalars['Boolean']>;
  IsStoreProcedure?: Maybe<Scalars['Boolean']>;
};

export type DataSourceInput = {
  Columns?: InputMaybe<Array<Scalars['String']>>;
  DataMember: Scalars['String'];
  DataSource: Scalars['String'];
  DynamicFilterColumn?: InputMaybe<Scalars['String']>;
  IsMainQuery?: InputMaybe<Scalars['Boolean']>;
  IsStoreProcedure?: InputMaybe<Scalars['Boolean']>;
};

export type DebitNoteEntity = {
  __typename?: 'debitNoteEntity';
  accountId: Scalars['String'];
  adjustDocumentReference?: Maybe<Scalars['String']>;
  allocation?: Maybe<Array<AllocationEntity>>;
  allowReversal: Scalars['Boolean'];
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  billItemId?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  documentAmount: Scalars['Float'];
  documentDate: Scalars['DateTime'];
  documentNo?: Maybe<Scalars['String']>;
  documentRef?: Maybe<Scalars['String']>;
  dueDate: Scalars['DateTime'];
  glGenerated: Scalars['String'];
  glGenerationBatch?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  interestRate: Scalars['Float'];
  isIncludeHoliday: Scalars['Boolean'];
  isReversal: Scalars['Boolean'];
  projectId: Scalars['String'];
  requestedBy?: Maybe<Scalars['String']>;
  saleId: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  status: Scalars['String'];
  submittedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
};

export type DirectionEntity = {
  __typename?: 'directionEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  seq_no?: Maybe<Scalars['Float']>;
  units: Array<UnitEntity>;
};

export type DiscountEntity = {
  __typename?: 'discountEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  discountIntegration?: Maybe<Array<IntegrationDiscountEntity>>;
  discountType?: Maybe<DiscountTypeEntity>;
  discountTypeId?: Maybe<Scalars['String']>;
  discountVal?: Maybe<Scalars['Float']>;
  formula?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDiscountRebate: Scalars['Boolean'];
  isIntegrated: Scalars['Boolean'];
  prefix?: Maybe<Scalars['String']>;
  projectAgencyDiscount: ProjectAgencyDiscountEntity;
  projectAgencyRebate: ProjectAgencyRebateEntity;
  projectDiscounts: Array<ProjectDiscountEntity>;
  saleRebatePackage?: Maybe<Array<SaleRebatePackageEntity>>;
  salesDiscount: Array<SaleDiscountEntity>;
  saleSubProduct?: Maybe<SaleSubproductEntity>;
  title: Scalars['String'];
};

/** JsonType */
export type DiscountListingObject = {
  __typename?: 'discountListingObject';
  amount: Scalars['Float'];
  discountId: Scalars['String'];
  discountMethod: Scalars['String'];
  formula: Scalars['String'];
  seqNo: Scalars['Float'];
  title: Scalars['String'];
};

export type DiscountListingUnitTransferInput = {
  amount: Scalars['Float'];
  discountId: Scalars['String'];
  discountMethod: Scalars['String'];
  formula: Scalars['String'];
  seqNo: Scalars['Float'];
  title: Scalars['String'];
};

/** JsonType */
export type DiscountObject = {
  __typename?: 'discountObject';
  discardBumi: Scalars['Boolean'];
  discounts?: Maybe<Array<DiscountListingObject>>;
};

export type DiscountParamaterValueOutput = {
  __typename?: 'DiscountParamaterValueOutput';
  amount: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  discountValue: Scalars['Float'];
  prefix: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type DiscountTypeEntity = AuditEntity & {
  __typename?: 'discountTypeEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  discount?: Maybe<DiscountEntity>;
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  systemUse: Scalars['Boolean'];
};

export type DiscountUnitTransferInput = {
  discardBumi: Scalars['Boolean'];
  discounts?: InputMaybe<Array<DiscountListingUnitTransferInput>>;
};

export type DistrictMasterDataOutput = {
  __typename?: 'DistrictMasterDataOutput';
  commonStatus: Scalars['String'];
  ID: Scalars['String'];
  name: Scalars['String'];
  stateID: Scalars['String'];
};

export type DocumentNumberDetailEntity = {
  __typename?: 'documentNumberDetailEntity';
  accountId: Scalars['String'];
  approvedBy: Scalars['String'];
  approvedTs?: Maybe<Scalars['DateTime']>;
  commonStatus: Scalars['String'];
  documentNumberHeader: DocumentNumberHeaderEntity;
  documentNumberHeaderId: Scalars['String'];
  formatType: Scalars['String'];
  formatValue: Scalars['String'];
  id: Scalars['String'];
  nextNumber?: Maybe<Scalars['Float']>;
  resetBy?: Maybe<Scalars['String']>;
  sequence: Scalars['Float'];
  startFrom?: Maybe<Scalars['Float']>;
  submittedBy: Scalars['String'];
  submittedTs?: Maybe<Scalars['DateTime']>;
};

export type DocumentNumberHeaderEntity = {
  __typename?: 'documentNumberHeaderEntity';
  accountId: Scalars['String'];
  approvedBy: Scalars['String'];
  approvedTs?: Maybe<Scalars['DateTime']>;
  commonStatus: Scalars['String'];
  companyId: Scalars['String'];
  docNumGenerated?: Maybe<Scalars['Boolean']>;
  documentNumberDetails?: Maybe<Array<DocumentNumberDetailEntity>>;
  documentNumberResetBy?: Maybe<Array<DocumentNumberResetByEntity>>;
  id: Scalars['String'];
  isAutoDocNo?: Maybe<Scalars['Boolean']>;
  refRecordId: Scalars['String'];
  refTable: Scalars['String'];
  sampleOutput: Scalars['String'];
  submittedBy: Scalars['String'];
  submittedTs?: Maybe<Scalars['DateTime']>;
  type: DocumentNumberHeaderTypeEnum;
};

export enum DocumentNumberHeaderTypeEnum {
  AreaVariance = 'AREA_VARIANCE',
  Contra = 'CONTRA',
  CreditNote = 'CREDIT_NOTE',
  DebitNote = 'DEBIT_NOTE',
  DescriptionList = 'DescriptionList',
  GetDescription = 'GetDescription',
  Interest = 'INTEREST',
  Invoice = 'INVOICE',
  Lad = 'LAD',
  Misc = 'MISC',
  Rebate = 'REBATE',
  Receipt = 'RECEIPT',
  Redemption = 'REDEMPTION',
  Refund = 'REFUND'
}

export type DocumentNumberResetByEntity = {
  __typename?: 'documentNumberResetByEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  companyId: Scalars['String'];
  documentNumberHeader: DocumentNumberHeaderEntity;
  documentNumberHeaderId: Scalars['String'];
  id: Scalars['String'];
  month?: Maybe<Scalars['Float']>;
  nextNumber?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type DownloadLogEntity = {
  __typename?: 'downloadLogEntity';
  accountId: Scalars['String'];
  downloadDate: Scalars['DateTime'];
  id: Scalars['String'];
  projectId?: Maybe<Scalars['String']>;
  reportType: Scalars['String'];
  userId: Scalars['String'];
  userType: Scalars['String'];
};

export type DownpaymentReminderEntity = AuditEntity & {
  __typename?: 'downpaymentReminderEntity';
  accountId: Scalars['String'];
  balanceAmount: Scalars['Float'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  generationBatch?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  progressBillingId: Scalars['String'];
  projectId: Scalars['String'];
  reminderDate: Scalars['DateTime'];
  reminderSeq: Scalars['Float'];
  saleId: Scalars['String'];
  share?: Maybe<Array<ShareObject>>;
};

export type DropDownDataSource = {
  __typename?: 'DropDownDataSource';
  DataMember: Scalars['String'];
  DataSource: Scalars['String'];
  parameters: Array<DropDownParamterInterface>;
};

export type DropDownDataSourceInput = {
  DataMember: Scalars['String'];
  DataSource: Scalars['String'];
  parameters: Array<DropDownDataSourceParameterInput>;
};

export type DropDownDataSourceParameterInput = {
  Name: Scalars['String'];
  ParameterName: Scalars['String'];
};

export type DropDownMember = {
  __typename?: 'DropDownMember';
  DynamicSource?: Maybe<DynamicSource>;
  StaticData?: Maybe<Array<StaticData>>;
  ValueSource: Scalars['String'];
};

export type DropDownMemberInput = {
  DynamicSource?: InputMaybe<DynamicSourceInput>;
  StaticData?: InputMaybe<Array<StaticDataInput>>;
  ValueSource: Scalars['String'];
};

export type DropDownParamterInterface = {
  __typename?: 'DropDownParamterInterface';
  Name: Scalars['String'];
  ParameterName: Scalars['String'];
};

export type DynamicSource = {
  __typename?: 'DynamicSource';
  DataSource: DropDownDataSource;
  DisplayMember: Scalars['String'];
  ValueMember: Scalars['String'];
};

export type DynamicSourceInput = {
  DataSource: DropDownDataSourceInput;
  DisplayMember: Scalars['String'];
  ValueMember: Scalars['String'];
};

export type EditEmailLogSchemeInput = {
  accountId: Scalars['String'];
  bccEmails?: InputMaybe<Scalars['String']>;
  ccEmails?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  errorMessage?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isEmailSent: Scalars['Boolean'];
  recipientEmail: Scalars['String'];
  refId: Scalars['String'];
  refType: Scalars['String'];
  subject: Scalars['String'];
};

export type EInvoiceDetailsInput = {
  __typename?: 'EInvoiceDetailsInput';
  countryCode?: Maybe<Scalars['String']>;
  debtorEinvClass?: Maybe<Scalars['String']>;
  isEinvRequired?: Maybe<Scalars['String']>;
  isForeignBuyer?: Maybe<Scalars['String']>;
  isGovOrAuth?: Maybe<Scalars['String']>;
  sstTaxRegNo?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  tinNo?: Maybe<Scalars['String']>;
};

export type ELaunchAllocationEntity = AuditEntity & {
  __typename?: 'eLaunchAllocationEntity';
  accountId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  eLaunch: ELaunchEntity;
  eLaunchId: Scalars['String'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  unit: UnitEntity;
  unitId: Scalars['String'];
};

export type ELaunchContactEntity = AuditEntity & {
  __typename?: 'eLaunchContactEntity';
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  elaunchUser: ELaunchUserEntity;
  email?: Maybe<Scalars['String']>;
  getLangMasterData: Array<LanguageNamesOutput>;
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  phoneNo?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userLanguageChoose: LanguageOutput;
};

export type ELaunchEntity = AuditEntity & {
  __typename?: 'eLaunchEntity';
  accountId: Scalars['String'];
  code: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  eLaunchAllocation?: Maybe<Array<ELaunchAllocationEntity>>;
  eLaunchLeadBatch?: Maybe<Array<ELaunchLeadBatchEntity>>;
  endDate: Scalars['DateTime'];
  hasEnded?: Maybe<Scalars['Boolean']>;
  hasPublished?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  publishDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  terminationDate: Scalars['DateTime'];
};

export type ELaunchFavouriteUnitEntity = AuditEntity & {
  __typename?: 'eLaunchFavouriteUnitEntity';
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  eLaunchId: Scalars['String'];
  eLaunchUser: ELaunchUserEntity;
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  unitId: Scalars['String'];
  userId: Scalars['String'];
};

export type ELaunchInvitationTemplateEntity = AuditEntity & {
  __typename?: 'eLaunchInvitationTemplateEntity';
  accountId: Scalars['String'];
  accountSpec: AccountSpecEntity;
  commonStatus: Scalars['String'];
  content: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  eLaunch: ELaunchEntity;
  eLaunchId: Scalars['String'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  salesDoc?: Maybe<SalesDocEntity>;
  salesDocId?: Maybe<Array<Scalars['String']>>;
  subject?: Maybe<Scalars['String']>;
};

export type ELaunchLeadBatchEntity = AuditEntity & {
  __typename?: 'eLaunchLeadBatchEntity';
  accountId: Scalars['String'];
  batchTitle: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  eLaunch: ELaunchEntity;
  eLaunchId: Scalars['String'];
  id: Scalars['String'];
  lastEmailSend?: Maybe<Array<LastEmailSendOutput>>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  recordCount: Scalars['Float'];
};

export type ELaunchLeadEntity = AuditEntity & {
  __typename?: 'eLaunchLeadEntity';
  accountId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  eLaunchBatchId: Scalars['String'];
  eLaunchLeadBatch: ELaunchLeadBatchEntity;
  id: Scalars['String'];
  lead: LeadEntity;
  leadId: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
};

export type ELaunchLogEntity = AuditEntity & {
  __typename?: 'eLaunchLogEntity';
  accountId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  eLaunch: ELaunchEntity;
  eLaunchId: Scalars['String'];
  id: Scalars['String'];
  log: Scalars['JSONObject'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
};

export type ELaunchOtpEntity = AuditEntity & {
  __typename?: 'eLaunchOtpEntity';
  accountId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  otpCode: Scalars['String'];
  otpExpiry: Scalars['DateTime'];
  otpType: Scalars['String'];
  phoneNo: Scalars['String'];
};

export type ELaunchRegisterEntity = AuditEntity & {
  __typename?: 'eLaunchRegisterEntity';
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  eLaunch: ELaunchEntity;
  eLaunchId: Scalars['String'];
  eLaunchUser: ELaunchUserEntity;
  id: Scalars['String'];
  invitedBy?: Maybe<Scalars['String']>;
  invitedById?: Maybe<Scalars['String']>;
  leadId: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type ELaunchUserEntity = AuditEntity & {
  __typename?: 'eLaunchUserEntity';
  accessToken?: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  activated: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  forgotPasswordLink?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  languageSet: Scalars['String'];
  loginId: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  refreshToken?: Maybe<Scalars['String']>;
};

export type ELetterOutput = {
  __typename?: 'ELetterOutput';
  content: Scalars['String'];
  fileName: Scalars['String'];
};

export type EmailLetterEntity = {
  __typename?: 'emailLetterEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  emailLetterParameter: Array<EmailLetterParameterEntity>;
  emailLetterTemplate: Array<EmailLetterTemplateEntity>;
  emailType: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  seqNo: Scalars['Float'];
};

export type EmailLetterParameterEntity = {
  __typename?: 'emailLetterParameterEntity';
  accountId: Scalars['String'];
  columnName: Scalars['String'];
  commonStatus: Scalars['String'];
  displayName: Scalars['String'];
  emailLetter: EmailLetterEntity;
  emailLetterId: Scalars['String'];
  id: Scalars['String'];
};

export type EmailLetterTemplateEntity = AuditEntity & {
  __typename?: 'emailLetterTemplateEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  emailLetter: EmailLetterEntity;
  emailLetterId: Scalars['String'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  projectEmailLetter: ProjectEmailLetterEntity;
  systemUse: Scalars['Boolean'];
  templateBody?: Maybe<Scalars['String']>;
};

export type EmailLogEntity = {
  __typename?: 'emailLogEntity';
  accountId: Scalars['String'];
  bccEmails: Scalars['String'];
  ccEmails: Scalars['String'];
  commonStatus: Scalars['String'];
  content: Scalars['String'];
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isEmailSent: Scalars['Boolean'];
  recipientEmail: Scalars['String'];
  refId: Scalars['String'];
  refType: Scalars['String'];
  subject: Scalars['String'];
};

export type EmailLogOutput = {
  __typename?: 'EmailLogOutput';
  accountId: Scalars['String'];
  bccEmails?: Maybe<Scalars['String']>;
  ccEmails?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  content: Scalars['String'];
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isEmailSent: Scalars['Boolean'];
  recipientEmail: Scalars['String'];
  refId: Scalars['String'];
  refType: Scalars['String'];
  subject: Scalars['String'];
};

export type EndfinContactEntity = {
  __typename?: 'endfinContactEntity';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isSuspendible: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNo?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  user: EndfinUserEntity;
  userId: Scalars['String'];
};

export type EndfinFinancierEntity = {
  __typename?: 'endfinFinancierEntity';
  activated: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  endfinUserRoles: Array<EndfinUserRoleEntity>;
  financier?: Maybe<FinancierEntity>;
  financiers: Array<FinancierEntity>;
  id: Scalars['String'];
  name: Scalars['String'];
  staffCount?: Maybe<Scalars['Float']>;
};

export type EndfinLoanAppActivityEntity = {
  __typename?: 'endfinLoanAppActivityEntity';
  activityType: Scalars['String'];
  activityTypeDesc: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  id: Scalars['String'];
  loanApp: EndfinLoanAppEntity;
  loanAppId: Scalars['String'];
  loanAppStatus: EndfinLoanAppStatusEntity;
  loanAppStatusId: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
};

export type EndfinLoanAppEntity = {
  __typename?: 'endfinLoanAppEntity';
  accountId: Scalars['String'];
  activityData?: Maybe<Array<EndfinLoanAppActivityEntity>>;
  agingDate?: Maybe<Scalars['DateTime']>;
  applyAmount: Scalars['Float'];
  applyDate: Scalars['DateTime'];
  approvalAmount?: Maybe<Scalars['Float']>;
  approvalDate?: Maybe<Scalars['DateTime']>;
  assignedDate?: Maybe<Scalars['DateTime']>;
  documentData?: Maybe<Array<AttachmentEntity>>;
  eLetteringExistenceType?: Maybe<Array<Scalars['String']>>;
  endfinLoanAppActivities: Array<EndfinLoanAppActivityEntity>;
  financier: FinancierEntity;
  financierId: Scalars['String'];
  id: Scalars['String'];
  lastActivity?: Maybe<EndfinLoanAppActivityEntity>;
  loanAppStatus: EndfinLoanAppStatusEntity;
  loanAppStatusId: Scalars['String'];
  loanType: Scalars['String'];
  sale: SaleEntity;
  saleId: Scalars['String'];
  staff?: Maybe<EndfinUserEntity>;
  tenure?: Maybe<Scalars['Float']>;
  user?: Maybe<EndfinUserEntity>;
  userId: Scalars['String'];
};

export type EndfinLoanAppStatusEntity = {
  __typename?: 'endfinLoanAppStatusEntity';
  endfinLoanAppActivities: Array<EndfinLoanAppActivityEntity>;
  endfinLoanApps: Array<EndfinLoanAppEntity>;
  id: Scalars['String'];
  seqNo: Scalars['Float'];
  stages: Scalars['Float'];
  statusCode: Scalars['String'];
  statusName: Scalars['String'];
};

export type EndfinNotificationEntity = AuditEntity & {
  __typename?: 'endfinNotificationEntity';
  accountId: Scalars['String'];
  body: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  endfinNotificationRecipient: Array<EndfinNotificationRecipientEntity>;
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  permissionId: Scalars['Float'];
  projectId: Scalars['String'];
  subject: Scalars['String'];
};

export type EndfinNotificationPolicyEntity = {
  __typename?: 'endfinNotificationPolicyEntity';
  accountId: Scalars['String'];
  id: Scalars['String'];
  notificationDuration: Scalars['Float'];
  permissionId: Scalars['Float'];
  userId: Scalars['String'];
};

export type EndfinNotificationRecipientEntity = AuditEntity & {
  __typename?: 'endfinNotificationRecipientEntity';
  accountId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  endfinNotification: EndfinNotificationEntity;
  id: Scalars['String'];
  isRead: Scalars['Boolean'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  notificationId: Scalars['String'];
  projectId: Scalars['String'];
  recipientId: Scalars['String'];
};

export type EndfinProjectFinancierEntity = {
  __typename?: 'endfinProjectFinancierEntity';
  accountId: Scalars['String'];
  assignedDate: Scalars['DateTime'];
  commonStatus: Scalars['String'];
  financialLimit: Scalars['Float'];
  financier: FinancierEntity;
  financierId: Scalars['String'];
  financierInfo: FinancierEntity;
  id: Scalars['String'];
  interestRate?: Maybe<Scalars['Float']>;
  isPanel: Scalars['Boolean'];
  project: ProjectEntity;
  projectId: Scalars['String'];
};

export type EndfinUserEntity = {
  __typename?: 'endfinUserEntity';
  accessToken?: Maybe<Scalars['String']>;
  activated: Scalars['Boolean'];
  assignedLoanCount?: Maybe<LoanAssignedCountOutput>;
  commonStatus: Scalars['String'];
  contact?: Maybe<EndfinContactEntity>;
  endfinContacts: EndfinContactEntity;
  endfinLoanApps: Array<EndfinLoanAppEntity>;
  endfinUserRoles: EndfinUserRoleEntity;
  forgotPasswordLink?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  loginId: Scalars['String'];
  passwordHash?: Maybe<Scalars['String']>;
  projectLoanCount?: Maybe<Array<LoanProjectCountOutput>>;
  refreshToken?: Maybe<Scalars['String']>;
  userRole?: Maybe<EndfinUserRoleEntity>;
};

export type EndfinUserRoleEntity = {
  __typename?: 'endfinUserRoleEntity';
  commonStatus: Scalars['String'];
  financier: EndfinFinancierEntity;
  financierId: Scalars['String'];
  id: Scalars['String'];
  role: Scalars['String'];
  user: EndfinUserEntity;
  userId: Scalars['String'];
};

export type EntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'entityRoleUserAssignmentEntity';
  accountId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  entityId: Scalars['String'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  roleId: Scalars['String'];
  userId: Scalars['String'];
};

export type ESignProjectLetterListingOutput = {
  __typename?: 'ESignProjectLetterListingOutput';
  defaulted?: Maybe<Scalars['Boolean']>;
  letterTemplateId?: Maybe<Scalars['String']>;
  letterType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EventsEntity = {
  __typename?: 'eventsEntity';
  accountId: Scalars['String'];
  budget: Scalars['Float'];
  campaign: CampaignEntity;
  campaignId: Scalars['String'];
  commonStatus?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  id: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  website: Scalars['String'];
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FinancialBillingEntity = {
  __typename?: 'financialBillingEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  documentAmount?: Maybe<Scalars['Float']>;
  financierId: Scalars['String'];
  id: Scalars['String'];
  principalAmt: Scalars['Float'];
  progressBilling?: Maybe<ProgressBillingEntity>;
  progressBillingId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  saleLoan?: Maybe<SaleLoanEntity>;
  saleLoanId: Scalars['String'];
  taxAmount?: Maybe<Scalars['Float']>;
};

export type FinancierContactEntity = {
  __typename?: 'financierContactEntity';
  email: Scalars['String'];
  financier: FinancierEntity;
  financierId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  phoneNo: Scalars['String'];
};

export type FinancierEntity = {
  __typename?: 'financierEntity';
  accountId: Scalars['String'];
  address?: Maybe<AddressJson>;
  assignedProjectCount: Scalars['Float'];
  bank?: Maybe<BankEntity>;
  bankId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  collection?: Maybe<Array<SaleLoanEntity>>;
  commonStatus: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  disbursementFinancierId?: Maybe<Scalars['String']>;
  endfinLoanApps: Array<EndfinLoanAppEntity>;
  endfinProjectFinanciers: Array<EndfinProjectFinancierEntity>;
  financialLimit?: Maybe<Scalars['Float']>;
  financier: EndfinFinancierEntity;
  financierCode?: Maybe<Scalars['String']>;
  financierContact: Array<FinancierContactEntity>;
  financierId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  loanCount: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  officeTel?: Maybe<Scalars['String']>;
  projectInvitation?: Maybe<Array<FinancierProject>>;
  receiptReversal?: Maybe<Array<ReceiptReversalEntity>>;
  saleLoan?: Maybe<Array<SaleLoanEntity>>;
  state?: Maybe<Scalars['String']>;
};

export type FinancierProject = {
  __typename?: 'FinancierProject';
  invitation: Array<Contact>;
  projectId: Scalars['String'];
};

export type FinancierUnitMortgage = {
  __typename?: 'financierUnitMortgage';
  financierName?: Maybe<Scalars['String']>;
  interestRate?: Maybe<Scalars['Float']>;
};

export type FloorOutput = {
  __typename?: 'FloorOutput';
  ActiveUnit?: Maybe<Array<UnitEntity>>;
  block: Scalars['String'];
  floor: Scalars['Float'];
  floorlabel: Scalars['String'];
  isSubProduct: Scalars['Boolean'];
  projectid: Scalars['String'];
  Unit?: Maybe<Array<UnitEntity>>;
};

export type GlAccountEntity = {
  __typename?: 'glAccountEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  companyId: Scalars['String'];
  glAccountCode: Scalars['String'];
  glType: Scalars['String'];
  id: Scalars['String'];
  refId: Scalars['String'];
  refTable: Scalars['String'];
};

export type GlExportEntity = {
  __typename?: 'glExportEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  companyId: Scalars['String'];
  costCentre: Scalars['String'];
  creditAmount: Scalars['Float'];
  debitAmount: Scalars['Float'];
  glAccountCode: Scalars['String'];
  glDate: Scalars['DateTime'];
  glGenerationBatch: Scalars['String'];
  glTransactionType: Scalars['String'];
  glType: Scalars['String'];
  id: Scalars['String'];
  journalType: Scalars['String'];
  posted: Scalars['Boolean'];
  refDescription?: Maybe<Scalars['String']>;
  refDocumentDate: Scalars['DateTime'];
  refDocumentNo: Scalars['String'];
  refId: Scalars['String'];
  refTable: Scalars['String'];
  taxId?: Maybe<Scalars['String']>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  MediaType: Scalars['String'];
  PrivateUrl?: Maybe<Scalars['String']>;
  Url: Scalars['String'];
};

export type ImportLeadDetails = {
  __typename?: 'ImportLeadDetails';
  address?: Maybe<Scalars['String']>;
  annual_income?: Maybe<Scalars['String']>;
  assign_status?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  event_id?: Maybe<Scalars['String']>;
  full_name: Scalars['String'];
  gender_id?: Maybe<Scalars['String']>;
  isContactExisted: Scalars['Boolean'];
  lead_remark?: Maybe<Scalars['String']>;
  lead_source_id: Scalars['String'];
  lead_status?: Maybe<Scalars['String']>;
  marital_id?: Maybe<Scalars['String']>;
  nationality_id?: Maybe<Scalars['String']>;
  phone_no: Scalars['String'];
  postCode?: Maybe<Scalars['String']>;
  prefer_location?: Maybe<Scalars['String']>;
  prefer_property?: Maybe<Scalars['String']>;
  race_id?: Maybe<Scalars['String']>;
  sales_person_assigned_id?: Maybe<Scalars['String']>;
  salutation_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_content?: Maybe<Scalars['String']>;
  utm_id?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  utm_term?: Maybe<Scalars['String']>;
};

/** JsonType */
export type IncludeInDetail = {
  __typename?: 'includeInDetail';
  paymentPlanDetailId?: Maybe<Scalars['String']>;
};

export type IncludeInDetailInput = {
  paymentPlanDetailId?: InputMaybe<Scalars['String']>;
};

export type IncomeRangeEntity = {
  __typename?: 'incomeRangeEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  contact: Array<ContactEntity>;
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  leadContact: Array<LeadContactEntity>;
  range: Scalars['String'];
  sales: Array<SaleEntity>;
  seqNo?: Maybe<Scalars['Float']>;
};

export type IntegrationDiscountEntity = {
  __typename?: 'integrationDiscountEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  discount: DiscountEntity;
  discountId: Scalars['String'];
  effectiveDate?: Maybe<Scalars['DateTime']>;
  expiredDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  ifcaDiscountId: Scalars['Float'];
  project: ProjectEntity;
  projectId: Scalars['String'];
  projectIntegration: ProjectIntegrationEntity;
};

export type IntegrationPackageEntity = {
  __typename?: 'integrationPackageEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  ifcaPackageId: Scalars['Float'];
  package: PackageEntity;
  packageId: Scalars['String'];
  project: ProjectEntity;
  projectId: Scalars['String'];
};

export type IntegrationSolicitorEntity = {
  __typename?: 'integrationSolicitorEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  ifcaSolicitorId: Scalars['Float'];
  project: ProjectEntity;
  projectIntegration: ProjectIntegrationEntity;
  solicitor: SolicitorAppointedEntity;
  solicitorId: Scalars['String'];
};

export type InterestEntity = {
  __typename?: 'interestEntity';
  account_id: Scalars['String'];
  batchId?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  interestAmount: Scalars['String'];
  interestDate: Scalars['DateTime'];
  interestRate: Scalars['String'];
  invoice: LedgerEntity;
  invoiceId: Scalars['String'];
  isWaive: Scalars['Boolean'];
  ledger: LedgerEntity;
  overdueAmount: Scalars['String'];
  overdueDay: Scalars['Float'];
  postedDate?: Maybe<Scalars['DateTime']>;
  project: ProjectEntity;
  sale: SaleEntity;
  trxStatus: Scalars['String'];
};

export type JointBuyerEntity = {
  __typename?: 'jointBuyerEntity';
  commonStatus: Scalars['String'];
  contact: ContactEntity;
  contactId: Scalars['String'];
  id: Scalars['String'];
  ifcaContactId: Scalars['String'];
  ifcaSaleId: Scalars['Float'];
  isEInvoiceRequired: Scalars['Boolean'];
  isGovAuth: Scalars['Boolean'];
  jointBuyerIdentityNo?: Maybe<Scalars['String']>;
  jointBuyerIdentityType?: Maybe<Scalars['String']>;
  jointBuyerName?: Maybe<Scalars['String']>;
  sale: SaleEntity;
  saleId: Scalars['String'];
  seq_no?: Maybe<Scalars['Float']>;
};

export type JointBuyerInfoForBookingListing = {
  __typename?: 'JointBuyerInfoForBookingListing';
  isJointBuyerInfoRequired?: Maybe<Scalars['Boolean']>;
  saleId?: Maybe<Scalars['String']>;
  totalJointBuyer?: Maybe<Scalars['Float']>;
};

export type JointBuyerParamaterValueOutput = {
  __typename?: 'JointBuyerParamaterValueOutput';
  contact: Scalars['String'];
  incomeRange: Scalars['String'];
  nationality: Scalars['String'];
  race: Scalars['String'];
  salutation: Scalars['String'];
};

export type KeyCollectionAppointmentEntity = {
  __typename?: 'keyCollectionAppointmentEntity';
  accountId: Scalars['String'];
  alarmVendorId?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  electricalDate?: Maybe<Scalars['DateTime']>;
  electricalRemark?: Maybe<Scalars['String']>;
  electricianId?: Maybe<Scalars['String']>;
  handOverDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  keyCollectionTimeslot?: Maybe<ProjectKeyCollectionTimeSlotEntity>;
  keyCollectionTimeslotId: Scalars['String'];
  keysCollected?: Maybe<Scalars['Float']>;
  plumberId?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  sanitaryDate?: Maybe<Scalars['DateTime']>;
  sanitaryRemark?: Maybe<Scalars['String']>;
  siteSupervisorId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type LadEntity = {
  __typename?: 'ladEntity';
  accountId: Scalars['String'];
  byFacilityDate?: Maybe<Scalars['DateTime']>;
  byUnitDate: Scalars['DateTime'];
  commonFacility: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  contraId?: Maybe<Scalars['String']>;
  documentNo?: Maybe<Scalars['String']>;
  facilityDueDays?: Maybe<Scalars['Float']>;
  facilityLadAmount?: Maybe<Scalars['Float']>;
  facilityLadDuration?: Maybe<Scalars['Float']>;
  facilityLadPercent?: Maybe<Scalars['Float']>;
  generationBatch: Scalars['String'];
  generationBatchDate: Scalars['DateTime'];
  id: Scalars['String'];
  isSimulate: Scalars['Boolean'];
  payoutPercent: Scalars['Float'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  spaPercent: Scalars['Float'];
  startWith: Scalars['String'];
  unitDueDays?: Maybe<Scalars['Float']>;
  unitDuration: Scalars['Float'];
  unitLadAmount: Scalars['Float'];
  unitLadPercent: Scalars['Float'];
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
};

export type LanguageNamesOutput = {
  __typename?: 'LanguageNamesOutput';
  langCode?: Maybe<Scalars['String']>;
  langName?: Maybe<Scalars['String']>;
};

export type LanguageOutput = {
  __typename?: 'LanguageOutput';
  fileNames?: Maybe<Array<Scalars['String']>>;
  obj?: Maybe<Scalars['String']>;
};

export type LastEmailSendInput = {
  SendBy: Scalars['String'];
  SendDate: Scalars['DateTime'];
};

export type LastEmailSendOutput = {
  __typename?: 'LastEmailSendOutput';
  SendBy: Scalars['String'];
  SendDate: Scalars['DateTime'];
};

export type LateInterestEntity = {
  __typename?: 'lateInterestEntity';
  accountId: Scalars['String'];
  allocation?: Maybe<Array<AllocationEntity>>;
  allowReversal: Scalars['Boolean'];
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  billItemId: Scalars['String'];
  calculateAmt: Scalars['Float'];
  calculateRate: Scalars['Float'];
  commonStatus: Scalars['String'];
  creditTerm?: Maybe<Scalars['Float']>;
  description: Scalars['String'];
  documentAmount: Scalars['Float'];
  documentDate?: Maybe<Scalars['DateTime']>;
  documentNo?: Maybe<Scalars['String']>;
  documentRef?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  generationBatch?: Maybe<Scalars['String']>;
  generationBatchDate?: Maybe<Scalars['DateTime']>;
  glGenerated: Scalars['String'];
  glGenerationBatch?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  interestAmount: Scalars['Float'];
  interestEndDate?: Maybe<Scalars['DateTime']>;
  interestStartDate: Scalars['DateTime'];
  isIncludeHoliday: Scalars['Boolean'];
  overdueDay: Scalars['Float'];
  projectId: Scalars['String'];
  refId: Scalars['String'];
  refTable: Scalars['String'];
  saleId: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  status: Scalars['String'];
  submittedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
  waiveAmount?: Maybe<Scalars['Float']>;
};

export type Lead3rdpartyIntegrationEntity = {
  __typename?: 'lead3rdpartyIntegrationEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  integrationType: Scalars['String'];
  integrationUrl?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  loginId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};

export type Lead3rdPartyIntegrationPostLogEntity = {
  __typename?: 'lead3rdPartyIntegrationPostLogEntity';
  accountId: Scalars['String'];
  body: Scalars['JSONObject'];
  header: Scalars['JSONObject'];
  id: Scalars['String'];
  integrationType: Scalars['String'];
  isSuccess: Scalars['Boolean'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  projectId?: Maybe<Scalars['String']>;
  receiveDate: Scalars['DateTime'];
  response?: Maybe<Scalars['String']>;
};

export type LeadActivityDetails = {
  __typename?: 'LeadActivityDetails';
  actiontakenname?: Maybe<Scalars['String']>;
  activityAudio?: Maybe<Scalars['String']>;
  activitydate: Scalars['DateTime'];
  activitydesc?: Maybe<Scalars['String']>;
  activitytype?: Maybe<Scalars['String']>;
  agencyname?: Maybe<Scalars['String']>;
  followupdate?: Maybe<Scalars['DateTime']>;
  followupremark?: Maybe<Scalars['String']>;
  lead_id?: Maybe<Scalars['String']>;
  leadStatus: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
};

export type LeadActivityEntity = {
  __typename?: 'leadActivityEntity';
  accountId: Scalars['String'];
  actionBy?: Maybe<Scalars['String']>;
  activityAudio?: Maybe<Scalars['String']>;
  activityDate: Scalars['DateTime'];
  activityDesc?: Maybe<Scalars['String']>;
  activityType: Scalars['String'];
  followUpDate?: Maybe<Scalars['DateTime']>;
  followUpRemark?: Maybe<Scalars['String']>;
  fromPersonId?: Maybe<Scalars['String']>;
  fromProject: ProjectEntity;
  fromTeam: SaleTeamEntity;
  id: Scalars['String'];
  lead: LeadEntity;
  leadId: Scalars['String'];
  leadStatus: Scalars['String'];
  personId?: Maybe<Scalars['String']>;
  project: ProjectEntity;
  remark?: Maybe<Scalars['String']>;
  team: SaleTeamEntity;
};

export type LeadAddressEntity = {
  __typename?: 'leadAddressEntity';
  accountId: Scalars['String'];
  address: AddressJson;
  contactId: Scalars['String'];
  id: Scalars['String'];
  isPrimary: Scalars['Boolean'];
  leadContact: LeadContactEntity;
};

export type LeadAssignmentSettingEntity = {
  __typename?: 'leadAssignmentSettingEntity';
  accountId: Scalars['String'];
  id: Scalars['String'];
  lastAssign: Scalars['DateTime'];
  leadCount: Scalars['Float'];
  projectId: Scalars['String'];
  seq: Scalars['Float'];
  userId: Scalars['String'];
  weight: Scalars['Float'];
};

export type LeadBatchCountLeadSourceOutput = {
  __typename?: 'LeadBatchCountLeadSourceOutput';
  id: Scalars['String'];
  others: Array<LeadBatchCountLeadSourceValuesOutput>;
  top1: Array<LeadBatchCountLeadSourceValuesOutput>;
  top2: Array<LeadBatchCountLeadSourceValuesOutput>;
  top3: Array<LeadBatchCountLeadSourceValuesOutput>;
};

export type LeadBatchCountLeadSourceValuesOutput = {
  __typename?: 'LeadBatchCountLeadSourceValuesOutput';
  count: Scalars['Float'];
  name: Scalars['String'];
};

export type LeadBatchEntity = AuditEntity & {
  __typename?: 'leadBatchEntity';
  accountId: Scalars['String'];
  assignedToProjectLeadCount: Scalars['Float'];
  batchTitle: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  lastAssignedToProject: LeadActivityEntity;
  leads: Array<LeadEntity>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  recordCount: Scalars['Float'];
};

export type LeadByBatch = {
  __typename?: 'LeadByBatch';
  contact_id: Scalars['String'];
  email: Scalars['String'];
  full_name: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  lead_id: Scalars['String'];
  lead_source?: Maybe<Scalars['String']>;
  phone_no: Scalars['String'];
  project_count: Scalars['Float'];
  project_id?: Maybe<Scalars['String']>;
};

export type LeadByBatchIds = {
  __typename?: 'LeadByBatchIds';
  assignedProjectDetails?: Maybe<Array<AssignedProjectDetails>>;
  batchTitle?: Maybe<Scalars['String']>;
  coldTotal?: Maybe<Scalars['Float']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  dropTotal?: Maybe<Scalars['Float']>;
  hotTotal?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isRoundRobinUser?: Maybe<Scalars['Boolean']>;
  leadData?: Maybe<Array<LeadDataOutput>>;
  openTotal?: Maybe<Scalars['Float']>;
  recordCount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  warmTotal?: Maybe<Scalars['Float']>;
};

export type LeadContactEntity = {
  __typename?: 'leadContactEntity';
  accountId: Scalars['String'];
  address?: Maybe<AddressJson>;
  addressList?: Maybe<Array<LeadAddressEntity>>;
  altEmail?: Maybe<Array<Scalars['String']>>;
  bookingWaitlists: Array<BookingWaitlistEntity>;
  commonStatus: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  companyRegNo?: Maybe<Scalars['String']>;
  distance: Scalars['Float'];
  dob?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  homeTel?: Maybe<Scalars['String']>;
  icNo?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  incomeRange?: Maybe<IncomeRangeEntity>;
  jointBuyers: Array<JointBuyerEntity>;
  jointBuyerSignature?: Maybe<Scalars['String']>;
  leadAddress?: Maybe<Array<AddressJson>>;
  leadJointBuyers: Array<LeadJointBuyerEntity>;
  leads?: Maybe<Array<LeadEntity>>;
  maritalStatus?: Maybe<MaritalStatusEntity>;
  militaryIc?: Maybe<Scalars['String']>;
  nationality?: Maybe<SNationalityEntity>;
  officeTel?: Maybe<Scalars['String']>;
  phoneNo: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
  race?: Maybe<RaceEntity>;
  sales: Array<SaleEntity>;
  salutation?: Maybe<SalutationEntity>;
  signatureUrl?: Maybe<Scalars['String']>;
};

export type LeadDataOutput = {
  __typename?: 'LeadDataOutput';
  activity_date?: Maybe<Scalars['DateTime']>;
  activity_desc?: Maybe<Scalars['String']>;
  contact_id: Scalars['String'];
  converted_to_booking: Scalars['Boolean'];
  createdTs?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  event_name?: Maybe<Scalars['String']>;
  existing_purchaser: Scalars['Boolean'];
  follow_up_date?: Maybe<Scalars['DateTime']>;
  follow_up_remark?: Maybe<Scalars['String']>;
  full_name: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  lead_id: Scalars['String'];
  lead_source: Scalars['String'];
  lead_status: Scalars['String'];
  phone_no: Scalars['String'];
  project_count: Scalars['Float'];
  sales_person_id?: Maybe<Scalars['String']>;
  sales_person_name?: Maybe<Scalars['String']>;
};

export type LeadEntity = {
  __typename?: 'leadEntity';
  accountId: Scalars['String'];
  agencyAcctId?: Maybe<Scalars['String']>;
  assignStatus?: Maybe<Scalars['String']>;
  batch: LeadBatchEntity;
  batchId?: Maybe<Scalars['String']>;
  contact: LeadContactEntity;
  contactId: Scalars['String'];
  eventId?: Maybe<Scalars['String']>;
  events?: Maybe<EventsEntity>;
  id: Scalars['String'];
  incomeRange?: Maybe<IncomeRangeEntity>;
  incomeRangeId?: Maybe<Scalars['String']>;
  lastLeadActId?: Maybe<Scalars['String']>;
  leadActivities: Array<LeadActivityEntity>;
  LeadActivity: Scalars['JSONObject'];
  leadAddress?: Maybe<Array<AddressJson>>;
  leadDetailsActivity?: Maybe<Array<LeadActivityDetails>>;
  leadJointBuyers: Array<LeadJointBuyerEntity>;
  leadSource: LeadSourceEntity;
  leadSourceId: Scalars['String'];
  leadStatus: Scalars['String'];
  LeadStatus: Scalars['JSONObject'];
  loanScreening?: Maybe<LoanScreenStatusOutput>;
  occupation?: Maybe<Scalars['String']>;
  preferLocation?: Maybe<Scalars['String']>;
  project: ProjectEntity;
  projectId?: Maybe<Scalars['String']>;
  propertyType?: Maybe<PropertyTypeEntity>;
  propertyTypeId?: Maybe<Scalars['String']>;
  referralContactNo?: Maybe<Scalars['String']>;
  referralName?: Maybe<Scalars['String']>;
  referralReferenceNo?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  sales: Array<SaleEntity>;
  salesPerson?: Maybe<UserEntity>;
  salesPersonId: Scalars['String'];
  team?: Maybe<SaleTeamEntity>;
  teamId: Scalars['String'];
  unit: UnitEntity;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_content?: Maybe<Scalars['String']>;
  utm_id?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  utm_term?: Maybe<Scalars['String']>;
  waitingList: Array<BookingWaitlistEntity>;
};

export type LeadHandleByWhichUserOutput = {
  __typename?: 'LeadHandleByWhichUserOutput';
  assignStatus: Scalars['String'];
  eLaunchId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  salesPersonId?: Maybe<Scalars['String']>;
};

export type LeadJointBuyerEntity = {
  __typename?: 'leadJointBuyerEntity';
  commonStatus: Scalars['String'];
  contact: LeadContactEntity;
  contactId: Scalars['String'];
  id: Scalars['String'];
  lead: LeadEntity;
  leadId: Scalars['String'];
};

export type LeadSourceEntity = {
  __typename?: 'leadSourceEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  leads: Array<LeadEntity>;
  name: Scalars['String'];
  sales: Array<SaleEntity>;
  seqNo?: Maybe<Scalars['Float']>;
  systemUse: Scalars['Boolean'];
};

export type LeadStatusCountOutput = {
  __typename?: 'LeadStatusCountOutput';
  bookedCount?: Maybe<Scalars['Float']>;
  coldCount?: Maybe<Scalars['Float']>;
  dropCount?: Maybe<Scalars['Float']>;
  hotCount?: Maybe<Scalars['Float']>;
  openCount?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['String']>;
  reservationCount?: Maybe<Scalars['Float']>;
  signedCount?: Maybe<Scalars['Float']>;
  totalConvertedAmount?: Maybe<Scalars['Float']>;
  totalCount?: Maybe<Scalars['Float']>;
  waitingCount?: Maybe<Scalars['Float']>;
  warmCount?: Maybe<Scalars['Float']>;
};

export type LeadTemplateReturnOutput = {
  __typename?: 'LeadTemplateReturnOutput';
  file?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  leadIds?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
};

export type LedgerEntity = {
  __typename?: 'ledgerEntity';
  account_id: Scalars['String'];
  adjustmentType?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  debtorClass?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docAmount: Scalars['String'];
  documentNo?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  interestInvoice: Array<InterestEntity>;
  interestLegder: Array<InterestEntity>;
  invoice: LedgerEntity;
  ledgers: Array<LedgerEntity>;
  mode: Scalars['String'];
  paymentInclude?: Maybe<Scalars['Boolean']>;
  paymentMethod?: Maybe<Scalars['String']>;
  postedDate?: Maybe<Scalars['DateTime']>;
  project: ProjectEntity;
  referenceNo?: Maybe<Scalars['String']>;
  reminders: Array<ReminderEntity>;
  sale: SaleEntity;
  schedule: ScheduleEntity;
  tax_id: Scalars['String'];
  taxAmount?: Maybe<Scalars['String']>;
  trxClass?: Maybe<Scalars['String']>;
  trxDate: Scalars['DateTime'];
  trxStatus: Scalars['String'];
  voidDate?: Maybe<Scalars['DateTime']>;
  voidReason?: Maybe<Scalars['String']>;
};

export type LetterSignEntity = AuditEntity & {
  __typename?: 'letterSignEntity';
  contactId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  sale: SaleEntity;
};

export type LetterTemplateEntity = AuditEntity & {
  __typename?: 'letterTemplateEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  isReportDesignEditor: Scalars['Boolean'];
  jointBuyerSignRequired: Scalars['Boolean'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  projectLetter: Array<ProjectLetterEntity>;
  standardLetter: StandardLetterEntity;
  standardLetterDataSourceVersion?: Maybe<Scalars['Float']>;
  standardLetterId: Scalars['String'];
  templateBody?: Maybe<Scalars['String']>;
};

export type ListOfSalesStaffByTeam = {
  __typename?: 'ListOfSalesStaffByTeam';
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  userId: Scalars['String'];
  userName: Scalars['String'];
};

export type LoanAddStaffInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNo: Scalars['String'];
  teamLead: Scalars['Boolean'];
};

export type LoanAlertAgingInput = {
  isActive: Scalars['Boolean'];
  maxMajorLoanAlertAging: Scalars['Float'];
  maxMinorLoanAlertAging: Scalars['Float'];
};

export type LoanAlertAgingOutput = {
  __typename?: 'LoanAlertAgingOutput';
  isActive: Scalars['Boolean'];
  maxMajorLoanAlertAging: Scalars['Float'];
  maxMinorLoanAlertAging: Scalars['Float'];
};

export type LoanAssignedCountOutput = {
  __typename?: 'LoanAssignedCountOutput';
  active_count: Scalars['Float'];
  total_count: Scalars['Float'];
  withdrawn_count: Scalars['Float'];
};

export type LoanAssignmentInput = {
  agencyUserReceive?: InputMaybe<Scalars['Boolean']>;
  endFinUserReceive?: InputMaybe<Scalars['Boolean']>;
  salesUserReceive?: InputMaybe<Scalars['Boolean']>;
};

export type LoanAssignmentOutput = {
  __typename?: 'loanAssignmentOutput';
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  endFinUserReceive?: Maybe<Scalars['Boolean']>;
  salesUserReceive?: Maybe<Scalars['Boolean']>;
};

export type LoanAssignStaffInput = {
  loan_ids: Array<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type LoanDownloadAttachmentOuput = {
  __typename?: 'LoanDownloadAttachmentOuput';
  base64: Scalars['String'];
  fileName: Scalars['String'];
};

export type LoanEditLoanInput = {
  applyAmount: Scalars['Float'];
  id: Scalars['String'];
  tenure: Scalars['Float'];
};

export type LoanEditStaffInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNo: Scalars['String'];
  teamLead: Scalars['Boolean'];
  user_id: Scalars['String'];
};

export type LoanEndFinContactInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNo: Scalars['String'];
};

export type LoanForgotPasswordInput = {
  confirmPassword: Scalars['String'];
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type LoanLeadNotificationInput = {
  leadAssignment?: InputMaybe<Scalars['Boolean']>;
};

export type LoanLeadNotificationOutput = {
  __typename?: 'LoanLeadNotificationOutput';
  leadAssignment?: Maybe<Scalars['Boolean']>;
};

export type LoanProjectCountOutput = {
  __typename?: 'LoanProjectCountOutput';
  active_count: Scalars['Float'];
  project_id: Scalars['String'];
  project_name: Scalars['String'];
  total_count: Scalars['Float'];
  withdrawn_count: Scalars['Float'];
};

export type LoanRegisterInput = {
  confirmPassword: Scalars['String'];
  contactId: Scalars['String'];
  financierName: Scalars['String'];
  loginId: Scalars['String'];
  password: Scalars['String'];
};

export type LoanRegisterOutput = {
  __typename?: 'LoanRegisterOutput';
  financierName: Scalars['String'];
  invitation: Contact;
};

export type LoanScreenEntity = {
  __typename?: 'loanScreenEntity';
  accountId: Scalars['String'];
  caseCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  payload: Scalars['JSONObject'];
  project: ProjectEntity;
  projectId: Scalars['String'];
  refCode?: Maybe<Scalars['String']>;
  refId: Scalars['String'];
  refTable: Scalars['String'];
  reportType: Scalars['String'];
  reportUrl?: Maybe<Scalars['String']>;
  serviceProvider: Scalars['String'];
  status: Scalars['String'];
};

export type LoanScreenReportOutput = {
  __typename?: 'LoanScreenReportOutput';
  fileName: Scalars['String'];
  mediaType: Scalars['String'];
  refType: Scalars['String'];
  resourceUrl: Scalars['String'];
};

export type LoanScreenStatusOutput = {
  __typename?: 'LoanScreenStatusOutput';
  caseCode: Scalars['String'];
  CCRIS?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  CTOS?: Maybe<Scalars['String']>;
  modifiedDate: Scalars['DateTime'];
  status: Scalars['String'];
};

export type LoanStatusOutput = {
  __typename?: 'LoanStatusOutput';
  acceptedCount: Scalars['Float'];
  approvedCount: Scalars['Float'];
  count: Scalars['Float'];
  rejectedCount: Scalars['Float'];
  withdrawnCount: Scalars['Float'];
};

export type LoanUpdateLoanStatusInput = {
  approvedAmount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  loanAppStatus: Scalars['String'];
  remarks?: InputMaybe<Scalars['String']>;
  solicitor_id?: InputMaybe<Scalars['String']>;
  solicitor_reference?: InputMaybe<Scalars['String']>;
  statusCode?: InputMaybe<Scalars['String']>;
  tenure?: InputMaybe<Scalars['Float']>;
};

export type LoanUploadAttachmentInput = {
  attachments: Array<Scalars['Upload']>;
  refId: Scalars['String'];
  refType: Scalars['String'];
};

export type LoanUploadFileInput = {
  base64: Scalars['String'];
  content_type: Scalars['String'];
  file_name: Scalars['String'];
  identity_type: Scalars['String'];
};

export type LoanUploadNricPassportInput = {
  attachments: Array<LoanUploadFileInput>;
  refId: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export type MaritalStatusEntity = {
  __typename?: 'maritalStatusEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  contacts: Array<ContactEntity>;
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  seqNo?: Maybe<Scalars['Float']>;
  systemUse: Scalars['Boolean'];
};

export type MasterDataOutput = {
  __typename?: 'MasterDataOutput';
  AddressType: Scalars['JSONObject'];
  City: Array<SCityEntity>;
  CommercialPurposes: Scalars['JSONObject'];
  Countries: Array<CountryMasterDataOutput>;
  CurrencyCode: Array<Array<Scalars['String']>>;
  DiscountPrefix: Scalars['JSONObject'];
  DiscountType: Array<DiscountTypeEntity>;
  ELettering: Scalars['JSONObject'];
  Events: Array<EventsEntity>;
  Genders: Scalars['JSONObject'];
  IncomeRanges: Array<IncomeRangeEntity>;
  IntegrationTypes: Scalars['JSONObject'];
  LandType: Scalars['JSONObject'];
  Language: Scalars['JSONObject'];
  LeadSources: Array<LeadSourceEntity>;
  LoanType: Scalars['JSONObject'];
  MaritalStatus: Array<MaritalStatusEntity>;
  Nationalities: Array<SNationalityEntity>;
  PaymentMethods: Scalars['JSONObject'];
  PaymentStatus: Scalars['JSONObject'];
  PayMethod: Scalars['JSONObject'];
  ProjectTypes: Array<ProjectTypeEntity>;
  PropertyTypes: Array<PropertyTypeEntity>;
  PurchaserAppSetting: Scalars['JSONObject'];
  Races: Array<RaceEntity>;
  ReasonCode: Array<ReasonCodeEntity>;
  Region: Array<RegionEntity>;
  Salutations: Array<SalutationEntity>;
  Tenures: Scalars['JSONObject'];
  UnitLayoutbyProject: Array<UnitLayoutEntity>;
  UnitTypes: Array<UnitTypeEntity>;
  UOM: Scalars['JSONObject'];
  UserPermission: Array<Scalars['Float']>;
};


export type MasterDataOutputPaymentMethodsArgs = {
  projectId?: InputMaybe<Scalars['String']>;
  saleId?: InputMaybe<Scalars['String']>;
};


export type MasterDataOutputPaymentStatusArgs = {
  projectId?: InputMaybe<Scalars['String']>;
  saleId?: InputMaybe<Scalars['String']>;
};


export type MasterDataOutputUnitLayoutbyProjectArgs = {
  project_id: Scalars['String'];
};


export type MasterDataOutputUserPermissionArgs = {
  projectId: Scalars['String'];
};

export type MediaSource = {
  __typename?: 'MediaSource';
  caption?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  videoType?: Maybe<Scalars['String']>;
};

export type MediaSourceInput = {
  caption?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
  videoType?: InputMaybe<Scalars['String']>;
};

export type MicrositeNewLeadOutput = {
  __typename?: 'MicrositeNewLeadOutput';
  accountId?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  eventId?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  homeTel?: Maybe<Scalars['String']>;
  icNo?: Maybe<Scalars['String']>;
  incomeRange?: Maybe<Scalars['String']>;
  leadSourceId?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<Scalars['String']>;
  militaryIc?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  officeTel?: Maybe<Scalars['String']>;
  passportNo?: Maybe<Scalars['String']>;
  phoneNo: Scalars['String'];
  postCode?: Maybe<Scalars['String']>;
  preferLocation?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  propertyType?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  salesPersonId?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmId?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  utmTerm?: Maybe<Scalars['String']>;
};

export type MiscBillingEntity = {
  __typename?: 'miscBillingEntity';
  accountId: Scalars['String'];
  allowReversal: Scalars['Boolean'];
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  billItemId: Scalars['String'];
  commonStatus: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  documentAmount: Scalars['Float'];
  documentDate: Scalars['DateTime'];
  documentNo?: Maybe<Scalars['String']>;
  documentRef?: Maybe<Scalars['String']>;
  dueDate: Scalars['DateTime'];
  generationBatch?: Maybe<Scalars['String']>;
  generationBatchDate?: Maybe<Scalars['DateTime']>;
  glGenerated?: Maybe<Scalars['Boolean']>;
  glGenerationBatch?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  interestRate?: Maybe<Scalars['Float']>;
  isIncludeHoliday: Scalars['Boolean'];
  projectId: Scalars['String'];
  requestedBy?: Maybe<Scalars['String']>;
  saleId: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  status: Scalars['String'];
  submittedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
};

export type MotTrackingEntity = {
  __typename?: 'motTrackingEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  confirmationPresentationReceipt: Scalars['DateTime'];
  forwardMotComSec: Scalars['DateTime'];
  forwardMotDirector: Scalars['DateTime'];
  id: Scalars['String'];
  instructionToSolicitor: Scalars['DateTime'];
  motIn: Scalars['DateTime'];
  motMortgageDate: Scalars['DateTime'];
  motMortgageNo: Scalars['String'];
  motOut: Scalars['DateTime'];
  motReceiptNo: Scalars['String'];
  motSolicitorId: Scalars['String'];
  motSolicitorReferenceNo?: Maybe<Scalars['String']>;
  motSubmissionDate: Scalars['DateTime'];
  motSubmissionNo?: Maybe<Scalars['String']>;
  motTransferDate: Scalars['DateTime'];
  projectId: Scalars['String'];
  receivedFrom?: Maybe<Scalars['String']>;
  receivedTitleDutyTransferred: Scalars['DateTime'];
  receiveLawyerAcknowledgement: Scalars['DateTime'];
  receiveMotCancellation: Scalars['DateTime'];
  receiveMotComSec: Scalars['DateTime'];
  receiveMotDirector: Scalars['DateTime'];
  releaseForTitle: Scalars['DateTime'];
  remark?: Maybe<Scalars['String']>;
  requestForTitle: Scalars['DateTime'];
  saleId: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  stampDutyPayment: Scalars['Float'];
  stampDutyPaymentDate: Scalars['DateTime'];
  titleExecutionDate: Scalars['DateTime'];
  titleForward: Scalars['DateTime'];
  titleNotificationDate: Scalars['DateTime'];
  units?: Maybe<UnitEntity>;
};

export type MultipleEsignProjectLetterListingOutput = {
  __typename?: 'MultipleEsignProjectLetterListingOutput';
  letter?: Maybe<Array<ESignProjectLetterListingOutput>>;
  letterTypeName?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateStaff: Scalars['Boolean'];
  addStaff: Scalars['Boolean'];
  assignStaff: Scalars['Boolean'];
  changePassword: Scalars['Boolean'];
  deactivateStaff: Scalars['Boolean'];
  editStaff: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  markAsRead: Scalars['Boolean'];
  newPassword: Scalars['Boolean'];
  newPasswordCheck: Scalars['Boolean'];
  register: Scalars['Boolean'];
  resendInvitation: Scalars['Boolean'];
  saveNotificationPermission: Scalars['Boolean'];
  updateLoanStatus: Scalars['Boolean'];
  updateProfile: Scalars['Boolean'];
  uploadAttachment: Scalars['Boolean'];
  uploadNricPassport: Array<AttachmentEntity>;
};


export type MutationActivateStaffArgs = {
  user_id: Scalars['String'];
};


export type MutationAddStaffArgs = {
  input: LoanAddStaffInput;
};


export type MutationAssignStaffArgs = {
  input: LoanAssignStaffInput;
};


export type MutationChangePasswordArgs = {
  input: LoanForgotPasswordInput;
};


export type MutationDeactivateStaffArgs = {
  user_id: Scalars['String'];
};


export type MutationEditStaffArgs = {
  input: LoanEditStaffInput;
};


export type MutationForgotPasswordArgs = {
  Email: Scalars['String'];
};


export type MutationMarkAsReadArgs = {
  notificationIds: Array<Scalars['String']>;
};


export type MutationNewPasswordArgs = {
  confirmPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationRegisterArgs = {
  input: LoanRegisterInput;
};


export type MutationResendInvitationArgs = {
  userId: Scalars['String'];
};


export type MutationSaveNotificationPermissionArgs = {
  input: NotificationInput;
};


export type MutationUpdateLoanStatusArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  input: LoanUpdateLoanStatusInput;
};


export type MutationUpdateProfileArgs = {
  ConInput: LoanEndFinContactInput;
  file?: InputMaybe<Scalars['Upload']>;
};


export type MutationUploadAttachmentArgs = {
  input: LoanUploadAttachmentInput;
};


export type MutationUploadNricPassportArgs = {
  input: LoanUploadNricPassportInput;
};

export type NewsAnnouncementEntity = AuditEntity & {
  __typename?: 'newsAnnouncementEntity';
  accountId: Scalars['String'];
  attachment?: Maybe<Array<AttachmentEntity>>;
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  id: Scalars['String'];
  isExpired?: Maybe<Scalars['Boolean']>;
  isPublish: Scalars['Boolean'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  newsProjects: Array<NewsProjectEntity>;
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
};


export type NewsAnnouncementEntityAttachmentArgs = {
  limit?: InputMaybe<Scalars['Float']>;
};

export type NewsProjectEntity = AuditEntity & {
  __typename?: 'newsProjectEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  newsAnnouncement: NewsAnnouncementEntity;
  newsId: Scalars['String'];
  projectId: Scalars['String'];
};

export type NotificationInput = {
  day?: InputMaybe<Scalars['Float']>;
  notificationList?: InputMaybe<Array<Scalars['Float']>>;
};

export type NumberFormatEntity = {
  __typename?: 'numberFormatEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  companyNumberSetups: Array<CompanyNumberSetupEntity>;
  id: Scalars['String'];
  numberFormat: Scalars['String'];
  numberType: Scalars['String'];
};

/** JsonType */
export type OldSaleDetail = {
  __typename?: 'oldSaleDetail';
  discounts?: Maybe<Array<DiscountListingObject>>;
};

export type OldSaleDetailInput = {
  discounts?: InputMaybe<Array<DiscountListingUnitTransferInput>>;
};

export type OnlineUserEntity = AuditEntity & {
  __typename?: 'OnlineUserEntity';
  accountId: Scalars['String'];
  accountName?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  ID: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  lastLogin: Scalars['DateTime'];
  lastLogout?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  socketId?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<Scalars['String']>;
  softwarePackage?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type OpenLeadAssignmentSettingEntity = {
  __typename?: 'openLeadAssignmentSettingEntity';
  accountId: Scalars['String'];
  id: Scalars['String'];
  lastAssign: Scalars['DateTime'];
  leadCount: Scalars['Float'];
  seq: Scalars['Float'];
  userId: Scalars['String'];
  weight: Scalars['Float'];
};

export type OpenLeadSummary = {
  __typename?: 'OpenLeadSummary';
  lastAssignedLeadCount?: Maybe<Scalars['Float']>;
  lastAssignedLeadDate?: Maybe<Scalars['String']>;
  lastImportedDate?: Maybe<Scalars['DateTime']>;
  lastImportedLeadCount?: Maybe<Scalars['Float']>;
  totalBatchImported?: Maybe<Scalars['Float']>;
};

export type PackageEntity = {
  __typename?: 'packageEntity';
  commonStatus: Scalars['String'];
  desc?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  id: Scalars['String'];
  integrationPackage: Array<IntegrationPackageEntity>;
  isDeletable: Scalars['Boolean'];
  isIntegrated: Scalars['Boolean'];
  listPrice: Scalars['Float'];
  marketValue: Scalars['Float'];
  name: Scalars['String'];
  packageExt?: Maybe<PackageExtEntity>;
  project: ProjectEntity;
  projectAgencyPackage: ProjectAgencyPackageEntity;
  projectId: Scalars['String'];
  salesContractPackage?: Maybe<SalePackageEntity>;
  salesPackage: Array<SaleEntity>;
  startDate: Scalars['DateTime'];
};

export type PackageExtEntity = {
  __typename?: 'packageExtEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  package: PackageEntity;
  packageId: Scalars['String'];
  taxSchemeId: Scalars['String'];
};

export type PanelListDto = {
  __typename?: 'PanelListDto';
  bank_name: Scalars['String'];
  financier_name: Scalars['String'];
  finproject_id: Scalars['String'];
  logo_url: Scalars['String'];
  office_tel?: Maybe<Scalars['Float']>;
  total_staff: Scalars['Float'];
};

export type ParamaterValueOutput = {
  __typename?: 'ParamaterValueOutput';
  address?: Maybe<AddressJson>;
  buyerSolicitor?: Maybe<SolicitorAppointedEntity>;
  company: CompanyEntity;
  contactExt?: Maybe<ContactExtensionEntity>;
  developerSolicitor?: Maybe<SolicitorAppointedEntity>;
  developmentCompany: CompanyEntity;
  discount?: Maybe<Array<DiscountParamaterValueOutput>>;
  holdingCompany: CompanyEntity;
  jointBuyerDetails?: Maybe<Array<ContactEntity>>;
  jointBuyers?: Maybe<Array<JointBuyerEntity>>;
  jointBuyerSignRequired: Scalars['Boolean'];
  lead?: Maybe<LeadEntity>;
  mainBuyerDetails?: Maybe<ContactEntity>;
  payment?: Maybe<SalePaymentValueOutput>;
  project: ProjectEntity;
  sale?: Maybe<SaleEntity>;
  salePackage?: Maybe<Array<PackageEntity>>;
  saleRebate?: Maybe<Array<SaleRebateEntity>>;
  spouse?: Maybe<ContactEntity>;
  unit: UnitEntity;
};

export type ParameterEntity = {
  __typename?: 'parameterEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  parameterType: Scalars['String'];
  saleContact: SaleContactEntity;
  seqNo?: Maybe<Scalars['Float']>;
};

export type PassswordErrorMsgOuput = {
  __typename?: 'PassswordErrorMsgOuput';
  RequireDigit: Scalars['String'];
  RequiredLength: Scalars['String'];
  RequiredUniqueChars: Scalars['String'];
  RequireLowercase: Scalars['String'];
  RequireNonAlphanumeric: Scalars['String'];
  RequireUppercase: Scalars['String'];
};

export type PassswordPolicyOuput = {
  __typename?: 'PassswordPolicyOuput';
  ErrorMsg: PassswordErrorMsgOuput;
  RequireDigit: Scalars['Boolean'];
  RequiredLength: Scalars['Float'];
  RequiredUniqueChars: Scalars['Float'];
  RequireLowercase: Scalars['Boolean'];
  RequireNonAlphanumeric: Scalars['Boolean'];
  RequireUppercase: Scalars['Boolean'];
};

export type PasswordPolicyEntity = {
  __typename?: 'passwordPolicyEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  notAllowReusePasswordForLast?: Maybe<Scalars['Float']>;
  passwordResetByDay?: Maybe<Scalars['Float']>;
  requiredDigit: Scalars['Boolean'];
  requiredMinNoCharacter?: Maybe<Scalars['Float']>;
  requiredSpecialCharacter: Scalars['Boolean'];
  requiredUpperLowerCase: Scalars['Boolean'];
};

export type PAuditEntity = {
  created_by: Scalars['String'];
  created_date: Scalars['DateTime'];
  created_ref_table: Scalars['String'];
  modified_by: Scalars['String'];
  modified_date: Scalars['DateTime'];
  modified_ref_table: Scalars['String'];
};

export type PaymentGatewayEntity = {
  __typename?: 'paymentGatewayEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  gatewayInfo: Scalars['JSONObject'];
  gatewayType: Scalars['String'];
  id: Scalars['String'];
  projectSpecs: Array<ProjectSpecEntity>;
  title: Scalars['String'];
};

export type PaymentPlanDetailEntity = {
  __typename?: 'paymentPlanDetailEntity';
  accountId: Scalars['String'];
  amountType: Scalars['String'];
  amountValue: Scalars['Float'];
  architectCertWorkProgress?: Maybe<ArchitectCertWorkProgressEntity>;
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  includeInDetailId?: Maybe<Array<IncludeInDetail>>;
  installmentBillingDay?: Maybe<Scalars['Float']>;
  isDownpayment: Scalars['Boolean'];
  isStakeholder: Scalars['Boolean'];
  isVpStage: Scalars['Boolean'];
  isWithInterest: Scalars['Boolean'];
  numberOfPayment?: Maybe<Scalars['Float']>;
  payment: PaymentPlanEntity;
  paymentPlanId: Scalars['String'];
  progressBilling?: Maybe<Array<ProgressBillingEntity>>;
  stageSeqNo: Scalars['Float'];
  stakeholderDuration?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  workProgress?: Maybe<WorkProgressEntity>;
  workProgressId: Scalars['String'];
};

export type PaymentPlanEntity = {
  __typename?: 'paymentPlanEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  details?: Maybe<Array<PaymentPlanDetailEntity>>;
  effectiveDate: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  payMethod?: Maybe<Scalars['String']>;
  projectPaymentPlan?: Maybe<Array<ProjectPaymentPlanEntity>>;
  saleExt?: Maybe<SaleExtensionEntity>;
  unitExt?: Maybe<UnitExtensionEntity>;
};

export type PaymentReminderEntity = {
  __typename?: 'paymentReminderEntity';
  accountId: Scalars['String'];
  balanceAmount: Scalars['Float'];
  commonStatus: Scalars['String'];
  generationBatch?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  projectId: Scalars['String'];
  refId: Scalars['String'];
  refTable: Scalars['String'];
  reminderDate: Scalars['DateTime'];
  reminderSeq: Scalars['Float'];
  saleId: Scalars['String'];
  share?: Maybe<Array<ShareObject>>;
};

export type PdpaDetailByProject = {
  __typename?: 'PDPADetailByProject';
  id?: Maybe<Scalars['String']>;
  letterType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  standardLetterId?: Maybe<Scalars['String']>;
  templateBody?: Maybe<Scalars['String']>;
};

export type PermissionDetail = {
  __typename?: 'PermissionDetail';
  child?: Maybe<Array<PermissionDetail>>;
  code?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
  permissionId?: Maybe<Scalars['Float']>;
  permissionType?: Maybe<PermissionType>;
  title: Scalars['String'];
};

export type PermissionRoleAssignmentEntity = AuditEntity & {
  __typename?: 'permissionRoleAssignmentEntity';
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  permissionId: Scalars['Float'];
  roleId: Scalars['String'];
};

export enum PermissionType {
  Report = 'REPORT',
  SalesAdmin = 'SALES_ADMIN'
}

export type PictureBlockEntity = {
  __typename?: 'pictureBlockEntity';
  block: Scalars['String'];
  id: Scalars['String'];
  project: ProjectEntity;
  resource: ResourceEntity;
  seqNo: Scalars['Float'];
};

export type PictureFloorEntity = {
  __typename?: 'pictureFloorEntity';
  block: Scalars['String'];
  floor: Scalars['Float'];
  id: Scalars['String'];
  project: ProjectEntity;
  resource: ResourceEntity;
  seqNo: Scalars['Float'];
};

export type PictureGalleryEntity = {
  __typename?: 'pictureGalleryEntity';
  id: Scalars['String'];
  project: ProjectEntity;
  resource: ResourceEntity;
  seqNo: Scalars['Float'];
};

export type PictureUnitlayoutEntity = {
  __typename?: 'pictureUnitlayoutEntity';
  block: Scalars['String'];
  id: Scalars['String'];
  noOfBathroom: Scalars['Float'];
  noOfBedroom: Scalars['Float'];
  project: ProjectEntity;
  projectId: Scalars['String'];
  resource: ResourceEntity;
  seqNo: Scalars['Float'];
  unitLayout: UnitLayoutEntity;
  unitType?: Maybe<Scalars['String']>;
  virtualModelLink: Scalars['String'];
};

export type PlatformUserAssignedAccountEntity = {
  __typename?: 'PlatformUserAssignedAccountEntity';
  accountId: Scalars['String'];
  commonStatus: CommonStatus;
  id: Scalars['String'];
  platformUserId: Scalars['String'];
};

export type PlatformUserEntity = {
  __typename?: 'PlatformUserEntity';
  accessToken?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  contactNo?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  ID: Scalars['String'];
  isActivated: Scalars['Boolean'];
  name: Scalars['String'];
  password: Scalars['String'];
  refreshToken?: Maybe<Scalars['String']>;
  role: PlatformUserRole;
  username: Scalars['String'];
};

export enum PlatformUserRole {
  Admin = 'ADMIN',
  Staff = 'STAFF',
  Superadmin = 'SUPERADMIN'
}

export type PostCodeMasterDataOutput = {
  __typename?: 'PostCodeMasterDataOutput';
  cityID: Scalars['String'];
  commonStatus: Scalars['String'];
  ID: Scalars['String'];
  postcode: Scalars['String'];
};

export type PostLogEntity = {
  __typename?: 'postLogEntity';
  body: Scalars['JSONObject'];
  endpoint?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isSuccess: Scalars['Boolean'];
  modifiedDate: Scalars['DateTime'];
  postDate: Scalars['DateTime'];
  response?: Maybe<Scalars['String']>;
  retryCount?: Maybe<Scalars['Float']>;
  unitId?: Maybe<Scalars['Float']>;
};

export type PriceAdjustmentEntity = {
  __typename?: 'priceAdjustmentEntity';
  accountId: Scalars['String'];
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  commonStatus: Scalars['String'];
  computationMode: Scalars['String'];
  computationValue: Scalars['Float'];
  id: Scalars['String'];
  name: Scalars['String'];
  priceAdjustmentUnit?: Maybe<Array<PriceAdjustmentUnitEntity>>;
  projectId: Scalars['String'];
  reason: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  roundingMode: Scalars['String'];
  roundingPrecision: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  submittedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
};

export type PriceAdjustmentUnitEntity = {
  __typename?: 'priceAdjustmentUnitEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  newUnitListPrice: Scalars['Float'];
  priceAdjustmentId: Scalars['String'];
  priceAdjustments?: Maybe<PriceAdjustmentEntity>;
  projectId: Scalars['String'];
  unitId: Scalars['String'];
  unitListPrice: Scalars['Float'];
  units?: Maybe<UnitEntity>;
};

export type ProgressBillingEntity = {
  __typename?: 'progressBillingEntity';
  accountId: Scalars['String'];
  addOnRefId?: Maybe<Scalars['String']>;
  addOnRefTable?: Maybe<Scalars['String']>;
  allowReversal: Scalars['Boolean'];
  billItemId: Scalars['String'];
  chargePercent: Scalars['Float'];
  commonStatus: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  documentAmount?: Maybe<Scalars['Float']>;
  documentDate?: Maybe<Scalars['DateTime']>;
  documentNo?: Maybe<Scalars['String']>;
  documentRef?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  financialBilling?: Maybe<Array<FinancialBillingEntity>>;
  generationBatch?: Maybe<Scalars['String']>;
  generationBatchCreditTerm?: Maybe<Scalars['Float']>;
  glGenerated: Scalars['String'];
  glGenerationBatch?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  installmentSeqNo?: Maybe<Scalars['Float']>;
  interestRate?: Maybe<Scalars['Float']>;
  isDownpayment: Scalars['Boolean'];
  isIncludeHoliday: Scalars['Boolean'];
  isInstallment: Scalars['Boolean'];
  isStakeholder: Scalars['Boolean'];
  isVpStage: Scalars['Boolean'];
  paymentPlanDetail?: Maybe<PaymentPlanDetailEntity>;
  paymentPlanDetailId: Scalars['String'];
  principalAmt?: Maybe<Scalars['Float']>;
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  stageSeqNo: Scalars['Float'];
  stakeholderProcess?: Maybe<StakeholderProcessEntity>;
  subproduct?: Maybe<SaleSubproductEntity>;
  taxLedger?: Maybe<Array<TaxLedgerEntity>>;
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
};

export type ProgressLogDetails = {
  __typename?: 'ProgressLogDetails';
  name?: Maybe<Scalars['String']>;
  progressdate?: Maybe<Scalars['DateTime']>;
  progressdesc?: Maybe<Scalars['String']>;
  progresstype?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  sale_id?: Maybe<Scalars['String']>;
  spastatus?: Maybe<Scalars['String']>;
};

export type ProjectAgencyDiscountEntity = {
  __typename?: 'projectAgencyDiscountEntity';
  accountId: Scalars['String'];
  agency: AgencyAppointedEntity;
  agencyId: Scalars['String'];
  commonStatus: Scalars['String'];
  discount: DiscountEntity;
  discountId: Scalars['String'];
  id: Scalars['String'];
  project: ProjectEntity;
  projectId: Scalars['String'];
};

export type ProjectAgencyPackageEntity = {
  __typename?: 'projectAgencyPackageEntity';
  accountId: Scalars['String'];
  agency: AgencyAppointedEntity;
  agencyId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  packageId: Scalars['String'];
  projectPackage: PackageEntity;
};

export type ProjectAgencyRebateEntity = {
  __typename?: 'projectAgencyRebateEntity';
  accountId: Scalars['String'];
  agency: AgencyAppointedEntity;
  agencyId: Scalars['String'];
  commonStatus: Scalars['String'];
  discount: DiscountEntity;
  discountId: Scalars['String'];
  id: Scalars['String'];
  project: ProjectEntity;
  projectId: Scalars['String'];
};

export type ProjectAreaVarianceEntity = {
  __typename?: 'projectAreaVarianceEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  formula?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  projectId: Scalars['String'];
};

export type ProjectBlockMediaOuput = {
  __typename?: 'ProjectBlockMediaOuput';
  block: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  pictureid: Scalars['String'];
  resourceid?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ProjectBookingDetailOutput = {
  __typename?: 'ProjectBookingDetailOutput';
  bookingFeeAttachments: Scalars['Float'];
  buyer: Scalars['Float'];
  joinBuyer: Scalars['Float'];
  otherAttachments: Scalars['Float'];
  sale: Scalars['Float'];
  unit: Scalars['Float'];
};

export type ProjectConstructionEntity = {
  __typename?: 'projectConstructionEntity';
  accountId: Scalars['String'];
  architect?: Maybe<Scalars['String']>;
  architectureStructure?: Maybe<Scalars['String']>;
  basePrice?: Maybe<Scalars['Float']>;
  buildingDesigner?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  completionDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  landPermitDate?: Maybe<Scalars['DateTime']>;
  landProprietor?: Maybe<Scalars['String']>;
  numberBlockUnit?: Maybe<Scalars['Float']>;
  planner?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  propertyAgent?: Maybe<Scalars['String']>;
  spaDuration?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  superStructureType?: Maybe<Scalars['String']>;
  taxRegNo?: Maybe<Scalars['String']>;
};

export type ProjectDiscountEntity = {
  __typename?: 'projectDiscountEntity';
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  discount: DiscountEntity;
  discountId: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  expired_date: Scalars['DateTime'];
  id: Scalars['String'];
  project: ProjectEntity;
  projectId: Scalars['String'];
};

export type ProjectEmailLetterEntity = AuditEntity & {
  __typename?: 'projectEmailLetterEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  emailLetterTemplate: EmailLetterTemplateEntity;
  emailLetterTemplateId: Scalars['String'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  project: ProjectEntity;
  projectId: Scalars['String'];
  subject: Scalars['String'];
  templateBody: Scalars['String'];
};

export type ProjectEntity = {
  __typename?: 'projectEntity';
  accountId: Scalars['String'];
  activeLoans?: Maybe<ActiveLoanStatusOutput>;
  agencyAppointedCount?: Maybe<AgencyAppointedCount>;
  agencyContract: Array<AgencyContractEntity>;
  agencySalesChartUnitDisplay?: Maybe<Array<AgencySalesChartOutput>>;
  allocations: Array<AllocationEntity>;
  amenities?: Maybe<Array<Amenities>>;
  attachment?: Maybe<Array<AttachmentEntity>>;
  blockGalleryImage?: Maybe<Array<ProjectBlockMediaOuput>>;
  bookingWaitlists: Array<BookingWaitlistEntity>;
  builtup?: Maybe<Array<Scalars['Float']>>;
  city?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  company?: Maybe<CompanyEntity>;
  completionDate?: Maybe<Scalars['DateTime']>;
  countBook?: Maybe<BookStatusOutput>;
  countBookConfirmation?: Maybe<BookingConfirmationStatusOutput>;
  countLoan?: Maybe<LoanStatusOutput>;
  country: Scalars['String'];
  countUnassignedLeads: Scalars['Float'];
  countUnitAvailable: Scalars['Float'];
  countUnitReserved: Scalars['Float'];
  countUnitSigned: Scalars['Float'];
  countUnitTotal: Scalars['Float'];
  countWaitlistTotal: CountWaitingUnitOutput;
  currency: Scalars['String'];
  desc?: Maybe<Scalars['String']>;
  developmentCompany: CompanyEntity;
  developmentCompanyId: Scalars['String'];
  discounts: Array<ProjectDiscountEntity>;
  distinctBlock: Array<Scalars['String']>;
  distinctBlockFloor?: Maybe<Array<ProjectUnitBlockFloorOuput>>;
  embededGoogleDriveLink?: Maybe<Array<ProjectYoutubeLinkOutput>>;
  embededVimeoLink?: Maybe<Array<ProjectYoutubeLinkOutput>>;
  embededYoutubeLink?: Maybe<Array<ProjectYoutubeLinkOutput>>;
  endfinProjectFinanciers: Array<EndfinProjectFinancierEntity>;
  existingPurchaser?: Maybe<Scalars['Float']>;
  firstGalleryImage?: Maybe<ProjectResourceOuput>;
  floorGalleryImage?: Maybe<Array<ProjectFloorMediaOuput>>;
  galleryImage?: Maybe<Array<ProjectGalleryMediaOuput>>;
  holdingCompany: CompanyEntity;
  holdingCompanyId: Scalars['String'];
  id: Scalars['String'];
  integrationDiscount: Array<IntegrationDiscountEntity>;
  interests: Array<InterestEntity>;
  isIntegrated: Scalars['Boolean'];
  isManager: Scalars['Boolean'];
  isSaleMade: Scalars['Boolean'];
  IsSubscribed: Scalars['JSONObject'];
  landType: Scalars['String'];
  landTypeDesc: Scalars['String'];
  launchDate?: Maybe<Scalars['DateTime']>;
  leadActivitiesFromProject: Array<LeadActivityEntity>;
  leadActivitiesProject: Array<LeadActivityEntity>;
  leads: Array<LeadEntity>;
  leadStatusCount?: Maybe<LeadStatusCountOutput>;
  ledgers: Array<LedgerEntity>;
  loanScreens: Array<LoanScreenEntity>;
  loanStatusList: Array<EndfinLoanAppStatusEntity>;
  maxPrice?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  packages: Array<PackageEntity>;
  pictureBlocks: Array<PictureBlockEntity>;
  pictureBlockUrl?: Maybe<Scalars['String']>;
  pictureFloors: Array<PictureFloorEntity>;
  pictureGalleries: Array<PictureGalleryEntity>;
  pictureUnitLayouts: Array<PictureUnitlayoutEntity>;
  priceRange?: Maybe<Scalars['String']>;
  projectAddress?: Maybe<Scalars['String']>;
  projectAgencyDiscount: ProjectAgencyDiscountEntity;
  projectAgencyRebate: ProjectAgencyRebateEntity;
  projectCode: Scalars['String'];
  projectDetailsUnit?: Maybe<Array<UnitEntity>>;
  projectEmailLetter: Array<ProjectEmailLetterEntity>;
  projectExt?: Maybe<ProjectExtEntity>;
  projectGps?: Maybe<Scalars['String']>;
  projectIntegration: ProjectIntegrationEntity;
  projectLetter: Array<ProjectLetterEntity>;
  projectSpec: Array<ProjectSpecEntity>;
  projectSpecs: ProjectSpecEntity;
  projectType: ProjectTypeEntity;
  reminders: Array<ReminderEntity>;
  resourcefloor?: Maybe<Array<ResourceEntity>>;
  resourceunitlayout?: Maybe<Array<ResourceEntity>>;
  saleGalleryAddress?: Maybe<Scalars['String']>;
  saleGalleryGps?: Maybe<Scalars['String']>;
  sales: Array<SaleEntity>;
  saleschartBlock: Array<Scalars['String']>;
  salesCharts: Array<SalesChartEntity>;
  salesDoc: Array<SalesDocEntity>;
  saleTeamAllocation: Array<SaleTeamAllocationEntity>;
  schedules: Array<ScheduleEntity>;
  solicitorCount?: Maybe<SolicitorStatusCount>;
  solicitorLayoutList?: Maybe<Array<SolicitorLayoutList>>;
  state?: Maybe<Scalars['String']>;
  tenure: Scalars['String'];
  tenureDesc: Scalars['String'];
  totalLoanCount?: Maybe<Scalars['Float']>;
  townshipName?: Maybe<Scalars['String']>;
  unitLayoutGalleryImage?: Maybe<Array<ProjectUnitLayoutMediaOuput>>;
  unitLayouts: Array<UnitLayoutEntity>;
  unitMaxBuiltUp?: Maybe<Scalars['String']>;
  unitMaxPrice?: Maybe<Scalars['String']>;
  unitMinBuiltUp?: Maybe<Scalars['String']>;
  unitMinPrice?: Maybe<Scalars['String']>;
  units: Array<UnitEntity>;
  unitTourVirtual?: Maybe<Array<UnitTourVirtualOutput>>;
  unitTypes?: Maybe<Array<UnitTypeOutput>>;
  uom: Scalars['String'];
  uomDesc: Scalars['String'];
  videoLink?: Maybe<Array<MediaSource>>;
  virtualModel?: Maybe<Array<ProjectResourceOuput>>;
  virtualModelLink?: Maybe<Array<MediaSource>>;
  vpDate?: Maybe<Scalars['DateTime']>;
  withdrawnLoans?: Maybe<WithdrawnLoanStatusOutput>;
};


export type ProjectEntityAgencySalesChartUnitDisplayArgs = {
  agencyId: Scalars['String'];
  block: Scalars['String'];
};


export type ProjectEntityDistinctBlockArgs = {
  phase?: InputMaybe<Scalars['String']>;
};


export type ProjectEntityPictureBlockUrlArgs = {
  block: Scalars['String'];
};


export type ProjectEntitySaleschartBlockArgs = {
  phase?: InputMaybe<Scalars['String']>;
};


export type ProjectEntitySolicitorLayoutListArgs = {
  solicitorAppointedId?: InputMaybe<Scalars['String']>;
};


export type ProjectEntityUnitMaxPriceArgs = {
  phase?: InputMaybe<Scalars['String']>;
};


export type ProjectEntityUnitMinPriceArgs = {
  phase?: InputMaybe<Scalars['String']>;
};

export type ProjectExtEntity = {
  __typename?: 'projectExtEntity';
  accountId: Scalars['String'];
  bankAccountId?: Maybe<Scalars['String']>;
  bankProfileId: Scalars['String'];
  commonStatus: Scalars['String'];
  companyBankAccount?: Maybe<CompanyBankAccountEntity>;
  companyBankProfile?: Maybe<CompanyBankProfileEntity>;
  id: Scalars['String'];
  plotNo?: Maybe<Scalars['String']>;
  project: ProjectEntity;
  projectId: Scalars['String'];
  township?: Maybe<TownshipEntity>;
  townshipId?: Maybe<Scalars['String']>;
};

export type ProjectFireInsuranceEntity = {
  __typename?: 'projectFireInsuranceEntity';
  accountId: Scalars['String'];
  certificateNo?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  companyAddress?: Maybe<AddressJson>;
  companyName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  projectId: Scalars['String'];
};

export type ProjectFloorMediaOuput = {
  __typename?: 'ProjectFloorMediaOuput';
  block: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  floor: Scalars['Float'];
  floorlabel?: Maybe<Scalars['String']>;
  pictureid: Scalars['String'];
  resourceid?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ProjectGalleryMediaOuput = {
  __typename?: 'ProjectGalleryMediaOuput';
  caption?: Maybe<Scalars['String']>;
  pictureid: Scalars['String'];
  resourceid?: Maybe<Scalars['String']>;
  seqno?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type ProjectIntegrationEntity = {
  __typename?: 'projectIntegrationEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  gracePeriod?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  ifcaCompanyId?: Maybe<Scalars['String']>;
  ifcaCompanyName?: Maybe<Scalars['String']>;
  ifcaPhaseId?: Maybe<Scalars['String']>;
  ifcaPhaseName?: Maybe<Scalars['String']>;
  ifcaProjectId?: Maybe<Scalars['String']>;
  ifcaProjectName?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrationUrl?: Maybe<Scalars['String']>;
  isDiscountIntegrated: Scalars['Boolean'];
  isMultiplePhaseIntegrated: Scalars['Boolean'];
  isPackagesIntegrated: Scalars['Boolean'];
  isRebateIntegrated: Scalars['Boolean'];
  isSolicitorIntegrated: Scalars['Boolean'];
  paymentPlanCode?: Maybe<Scalars['String']>;
  paymentPlanDesc?: Maybe<Scalars['String']>;
  paymentPlanId?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
};

export type ProjectKeyCollectionContractorEntity = {
  __typename?: 'projectKeyCollectionContractorEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  contactNo: Scalars['String'];
  contractorType: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  projectId: Scalars['String'];
};

export type ProjectKeyCollectionScheduleEntity = {
  __typename?: 'projectKeyCollectionScheduleEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  endDate: Scalars['DateTime'];
  endTime: Scalars['String'];
  id: Scalars['String'];
  interval: Scalars['Float'];
  projectId: Scalars['String'];
  startDate: Scalars['DateTime'];
  startTime: Scalars['String'];
  teamMember: TeamMemberObject;
  timeslot: Array<ProjectKeyCollectionTimeSlotEntity>;
};

export type ProjectKeyCollectionTimeSlotEntity = {
  __typename?: 'projectKeyCollectionTimeSlotEntity';
  accountId: Scalars['String'];
  appointment?: Maybe<KeyCollectionAppointmentEntity>;
  collectionScheduleId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  keyCollectionSchedule?: Maybe<ProjectKeyCollectionScheduleEntity>;
  projectId: Scalars['String'];
  scheduleDate: Scalars['DateTime'];
  scheduleTime: Scalars['String'];
  userId: Scalars['String'];
};

export type ProjectLadEntity = {
  __typename?: 'projectLadEntity';
  accountId: Scalars['String'];
  commonFacility: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  facilityLadDuration?: Maybe<Scalars['Float']>;
  facilityLadPercent?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  payoutPercent: Scalars['Float'];
  projectId: Scalars['String'];
  spaPercent?: Maybe<Scalars['Float']>;
  startWith: Scalars['String'];
  taxSchemeId: Scalars['String'];
  unitDuration: Scalars['Float'];
  unitLadPercent: Scalars['Float'];
};

export type ProjectLastActiveTransactionsEntity = {
  __typename?: 'projectLastActiveTransactionsEntity';
  booked: Scalars['Float'];
  cancelled: Scalars['Float'];
  project_id: Scalars['String'];
  signed: Scalars['Float'];
  total: Scalars['Float'];
  transaction_date: Scalars['DateTime'];
};

export type ProjectLateInterestEntity = {
  __typename?: 'projectLateInterestEntity';
  accountId: Scalars['String'];
  enableFloatingInterest: Scalars['Boolean'];
  excludeHoliday: Scalars['Boolean'];
  excludePaymentDate: Scalars['Boolean'];
  id: Scalars['String'];
  loanGracePeriod: Scalars['Float'];
  loanGracePeriodStartWith: Scalars['String'];
  minimumInterestAmount: Scalars['Float'];
  projectId: Scalars['String'];
  taxSchemeId?: Maybe<Scalars['String']>;
};

export type ProjectLateInterestExceptionEntity = {
  __typename?: 'projectLateInterestExceptionEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  projectId: Scalars['String'];
  remark: Scalars['String'];
  saleId: Scalars['String'];
  sales?: Maybe<SaleEntity>;
};

export type ProjectLetterEntity = {
  __typename?: 'projectLetterEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  letterTemplate: LetterTemplateEntity;
  project: ProjectEntity;
};

export type ProjectLetterTemplateEntity = {
  __typename?: 'projectLetterTemplateEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  isReportDesignEditor: Scalars['Boolean'];
  jointBuyerSignRequired: Scalars['Boolean'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  standardLetter: StandardLetterEntity;
  standardLetterDataSourceVersion?: Maybe<Scalars['Float']>;
  standardLetterId: Scalars['String'];
  templateBody?: Maybe<Scalars['String']>;
};

export type ProjectMiscChargesEntity = {
  __typename?: 'projectMiscChargesEntity';
  accountId: Scalars['String'];
  billItemId: Scalars['String'];
  commonStatus: Scalars['String'];
  formula: Scalars['String'];
  id: Scalars['String'];
  interestRate: Scalars['Float'];
  projectId: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  taxSchemeId: Scalars['String'];
};

export type ProjectPaymentPlanEntity = {
  __typename?: 'projectPaymentPlanEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  paymentPlan: PaymentPlanEntity;
  paymentPlanId: Scalars['String'];
  projectId: Scalars['String'];
};

export type ProjectPaymentReminderExceptionEntity = AuditEntity & {
  __typename?: 'projectPaymentReminderExceptionEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  maxReminder?: Maybe<Scalars['Float']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
};

export type ProjectPermitEntity = {
  __typename?: 'projectPermitEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  expiryDate: Scalars['DateTime'];
  id: Scalars['String'];
  issueDate: Scalars['DateTime'];
  permitNo: Scalars['String'];
  permitType: Scalars['String'];
  projectId: Scalars['String'];
};

export type ProjectPolicyEntity = {
  __typename?: 'projectPolicyEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  creditTerms: Scalars['Float'];
  id: Scalars['String'];
  interestRate: Scalars['Float'];
  projectId: Scalars['String'];
  roundingMode: Scalars['String'];
  roundingPrecision: Scalars['String'];
};

export type ProjectReminderEntity = AuditEntity & {
  __typename?: 'projectReminderEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  downpaymentReminder1: Scalars['Float'];
  downpaymentReminder2: Scalars['Float'];
  downpaymentReminder3: Scalars['Float'];
  downpaymentReminder4: Scalars['Float'];
  id: Scalars['String'];
  minimumAmount: Scalars['Float'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  paymentReminder1: Scalars['Float'];
  paymentReminder2: Scalars['Float'];
  paymentReminder3: Scalars['Float'];
  paymentReminder4: Scalars['Float'];
  projectId: Scalars['String'];
  reminderFor?: Maybe<Scalars['String']>;
  spaReminder1: Scalars['Float'];
  spaReminder2: Scalars['Float'];
  spaReminder3: Scalars['Float'];
  spaReminder4: Scalars['Float'];
};

export type ProjectResourceOuput = {
  __typename?: 'ProjectResourceOuput';
  caption?: Maybe<Scalars['String']>;
  resourceid?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ProjectSaleListOuput = {
  __typename?: 'ProjectSaleListOuput';
  booking_amount?: Maybe<Scalars['Float']>;
  contact_id: Scalars['String'];
  date: Scalars['DateTime'];
  full_name: Scalars['String'];
  project_id: Scalars['String'];
  sale_id: Scalars['String'];
  sales_status: Scalars['String'];
  salesPerson?: Maybe<UserEntity>;
  unit_id: Scalars['String'];
  unit_no: Scalars['String'];
  unit_price: Scalars['String'];
};

export type ProjectSalesOfficeEntity = {
  __typename?: 'ProjectSalesOfficeEntity';
  accountId: Scalars['String'];
  architecturePermit?: Maybe<Scalars['String']>;
  areaUOM1?: Maybe<Scalars['String']>;
  areaUOM2?: Maybe<Scalars['String']>;
  areaUOM3?: Maybe<Scalars['String']>;
  areaUOMConversion1?: Maybe<Scalars['String']>;
  areaUOMConversion2?: Maybe<Scalars['String']>;
  buildingContractorAwarded?: Maybe<Scalars['String']>;
  buildingContractorName?: Maybe<Scalars['String']>;
  builtUpArea?: Maybe<Scalars['Float']>;
  commonArea?: Maybe<Scalars['Float']>;
  commonAreaPercent?: Maybe<Scalars['Float']>;
  commonStatus: Scalars['String'];
  constructionPermit?: Maybe<Scalars['String']>;
  creditControlEmail?: Maybe<Scalars['String']>;
  creditControlHOD?: Maybe<Scalars['String']>;
  creditControlOfficeTel?: Maybe<Scalars['String']>;
  customerCareContactNo?: Maybe<Scalars['String']>;
  customerCareEmail?: Maybe<Scalars['String']>;
  customerCareHOD?: Maybe<Scalars['String']>;
  customerCareLocalAuthorityAddress?: Maybe<AddressJson>;
  customerCareLocalAuthorityContactNo?: Maybe<Scalars['String']>;
  customerCareLocalAuthorityName?: Maybe<Scalars['String']>;
  densityPercentage?: Maybe<Scalars['Float']>;
  DLPTerm1?: Maybe<Scalars['String']>;
  DLPTerm2?: Maybe<Scalars['String']>;
  floorArea?: Maybe<Scalars['Float']>;
  floorAreaPercent?: Maybe<Scalars['Float']>;
  generalManager?: Maybe<Scalars['String']>;
  greenspaceArea?: Maybe<Scalars['Float']>;
  greenspaceAreaPercent?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  landArea?: Maybe<Scalars['Float']>;
  projectId: Scalars['String'];
  projectManager?: Maybe<Scalars['String']>;
  propertyManager?: Maybe<Scalars['String']>;
  salesLocalAuthorityAddress?: Maybe<AddressJson>;
  salesLocalAuthorityContactNo?: Maybe<Scalars['String']>;
  salesLocalAuthorityName?: Maybe<Scalars['String']>;
  salesMarketingHOD?: Maybe<Scalars['String']>;
  salesOfficeAddress?: Maybe<Scalars['String']>;
  salesOfficeFax?: Maybe<Scalars['String']>;
  salesOfficeTel?: Maybe<Scalars['String']>;
  salesPermit?: Maybe<Scalars['String']>;
  usagePurpose?: Maybe<Scalars['String']>;
  usageRatioPercent?: Maybe<Scalars['Float']>;
  warrantyPeriod?: Maybe<Scalars['Float']>;
};

export type ProjectSpecEntity = {
  __typename?: 'projectSpecEntity';
  accountId: Scalars['String'];
  agencyLeadNotification?: Maybe<AgencyLeadNotificationOutput>;
  alertAgingActivated: Scalars['Boolean'];
  assignEndTime?: Maybe<Scalars['String']>;
  assignStartTime?: Maybe<Scalars['String']>;
  autoAssignLeadActivated: Scalars['Boolean'];
  autoReassignLeadActivated: Scalars['Boolean'];
  autoReassignLeadDuration?: Maybe<Scalars['Float']>;
  autoReleaseNotificationReminder?: Maybe<AutoReleaseNotificationReminderOutput>;
  autoReleaseReservationDay: Scalars['Float'];
  bookingfeeAmountRequired: Scalars['Boolean'];
  bookingFeeMandatory: Scalars['Boolean'];
  bookingFormLang: Scalars['String'];
  bookingPDPAconsentRequired: Scalars['Boolean'];
  bookingRefundPolicyconsentRequired: Scalars['Boolean'];
  bookingSignRequired: Scalars['Boolean'];
  ccrAttachmentRequired: Scalars['Boolean'];
  creditTerm?: Maybe<Scalars['Float']>;
  display2DChart: Scalars['Boolean'];
  ELetteringSMSRequired: Scalars['Boolean'];
  expectedSnpDuration?: Maybe<Scalars['Float']>;
  expectedSnpRequired: Scalars['Boolean'];
  getAccountSpec?: Maybe<AccountSpecEntity>;
  gracePeriod?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  ifcaReceiptDesc: Scalars['String'];
  isIfcaReceiptDefaultDesc: Scalars['Boolean'];
  isReservationEnable?: Maybe<Scalars['Boolean']>;
  lateInterestRate?: Maybe<Scalars['Float']>;
  leadAgingActivated: Scalars['Boolean'];
  leadAgingManagerHandled?: Maybe<Array<Scalars['String']>>;
  leadAgingNotificationMethod?: Maybe<Array<Scalars['String']>>;
  leadAssignNotificationActivated: Scalars['Boolean'];
  leadAssignNotificationMethod?: Maybe<Array<Scalars['String']>>;
  leadIdleOne?: Maybe<Scalars['Float']>;
  leadIdleThree?: Maybe<Scalars['Float']>;
  leadIdleTwo?: Maybe<Scalars['Float']>;
  loanAlertAging?: Maybe<LoanAlertAgingOutput>;
  loanconsentRequired: Scalars['Boolean'];
  loanScreening: Scalars['Boolean'];
  minAge?: Maybe<Scalars['Float']>;
  minAgeRequired: Scalars['Boolean'];
  minBookingfeeAmount: Scalars['Float'];
  notifyUnattendedLeadActivated: Scalars['Boolean'];
  notifyUnattendedLeadDuration?: Maybe<Scalars['Float']>;
  nricAttachmentRequired: Scalars['Boolean'];
  paymentGateway?: Maybe<PaymentGatewayEntity>;
  paymentGatewayId?: Maybe<Scalars['String']>;
  paymentGatewayRefundPolicy?: Maybe<Scalars['String']>;
  project: ProjectEntity;
  projectId: Scalars['String'];
  purchaserAppSetting: Scalars['String'];
  reassignEndTime?: Maybe<Scalars['String']>;
  reassignStartTime?: Maybe<Scalars['String']>;
  reminder_1Duration?: Maybe<Scalars['Float']>;
  reminder_2Duration?: Maybe<Scalars['Float']>;
  reminder_3Duration?: Maybe<Scalars['Float']>;
  reminder_4Duration?: Maybe<Scalars['Float']>;
  reservationAgency: Scalars['Boolean'];
  reservationSales: Scalars['Boolean'];
  saleLeadNotification?: Maybe<SaleLeadNotificationOutput>;
  salesAlertAging?: Maybe<SalesAlertAgingOutput>;
  solicitorAlertAging?: Maybe<SolicitorAlertAgingOutput>;
  statementRefundPolicy?: Maybe<Scalars['String']>;
  templateAddressShow: Scalars['Boolean'];
  templateCompanyShow?: Maybe<Scalars['String']>;
  templateDiscountsShow: Scalars['Boolean'];
  templateMaritalStatusShow: Scalars['Boolean'];
  templatePackagesShow: Scalars['Boolean'];
  templateRemarksShow: Scalars['Boolean'];
  trGeneration: Scalars['Boolean'];
  unattendedLeadManagerHandled?: Maybe<Array<Scalars['String']>>;
  unattendedLeadNotificationMethod?: Maybe<Array<Scalars['String']>>;
  unitSort: Scalars['String'];
};

export type ProjectTitleEntity = AuditEntity & {
  __typename?: 'projectTitleEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  expiryDate: Scalars['DateTime'];
  id: Scalars['String'];
  issueDate: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  projectId: Scalars['String'];
  projectTitleUnits: Array<ProjectTitleUnitEntity>;
  receiveDate: Scalars['DateTime'];
  remark?: Maybe<Scalars['String']>;
  titleNo: Scalars['String'];
  titleType: Scalars['String'];
};

export type ProjectTitleUnitEntity = {
  __typename?: 'projectTitleUnitEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  projectId: Scalars['String'];
  projectTitle: ProjectTitleEntity;
  projectTitleId: Scalars['String'];
  unitId: Scalars['String'];
  units: UnitEntity;
};

export type ProjectTypeEntity = {
  __typename?: 'projectTypeEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  projects: Array<ProjectEntity>;
  seq_no?: Maybe<Scalars['Float']>;
};

export type ProjectUnitBlockFloorOuput = {
  __typename?: 'ProjectUnitBlockFloorOuput';
  block: Scalars['String'];
  floor: Scalars['Float'];
  floorlabel: Scalars['String'];
};

export type ProjectUnitLayoutMediaOuput = {
  __typename?: 'ProjectUnitLayoutMediaOuput';
  block?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  noOfBathroom?: Maybe<Scalars['Float']>;
  noOfBedroom?: Maybe<Scalars['Float']>;
  pictureid: Scalars['String'];
  resourceid?: Maybe<Scalars['String']>;
  seqno?: Maybe<Scalars['Float']>;
  unit_type_desc?: Maybe<Scalars['String']>;
  unit_type_id?: Maybe<Scalars['String']>;
  unitcount?: Maybe<Scalars['Float']>;
  unitLayout?: Maybe<Scalars['String']>;
  unitlayoutid?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  virtualModelLink?: Maybe<Scalars['String']>;
};

export type ProjectYoutubeLinkOutput = {
  __typename?: 'ProjectYoutubeLinkOutput';
  caption?: Maybe<Scalars['String']>;
  thumbnail: Scalars['String'];
  url: Scalars['String'];
  videoType?: Maybe<Scalars['String']>;
};

export type PropertyTypeEntity = {
  __typename?: 'propertyTypeEntity';
  accountId: Scalars['String'];
  commercialPurpose: Scalars['String'];
  commercialPurposeDesc: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  propertyTypeExt?: Maybe<PropertyTypeExtEntity>;
  seq_no?: Maybe<Scalars['Float']>;
  units: Array<UnitEntity>;
};

export type PropertyTypeExtEntity = {
  __typename?: 'propertyTypeExtEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  propertyType: PropertyTypeEntity;
  propertyTypeId: Scalars['String'];
  taxSchemeId: Scalars['String'];
};

export type PurchaserAccountReferenceEntity = AuditEntity & {
  __typename?: 'purchaserAccountReferenceEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  refId?: Maybe<Array<PurchaserReferenceOutput>>;
  userId: Scalars['String'];
};

export type PurchaserAppCssInput = {
  backgroundAttachment?: InputMaybe<Scalars['String']>;
  backgroundImage?: InputMaybe<Scalars['String']>;
  backgroundPosition?: InputMaybe<Scalars['String']>;
  backgroundRepeat?: InputMaybe<Scalars['String']>;
  backgroundSize?: InputMaybe<Scalars['String']>;
  cardColor?: InputMaybe<Scalars['String']>;
  headerColor?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  showLoginIcon?: InputMaybe<Scalars['Boolean']>;
  showPurchaserAppWord?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaserAppCssOutput = {
  __typename?: 'purchaserAppCssOutput';
  backgroundAttachment?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  backgroundPosition?: Maybe<Scalars['String']>;
  backgroundRepeat?: Maybe<Scalars['String']>;
  backgroundSize?: Maybe<Scalars['String']>;
  cardColor?: Maybe<Scalars['String']>;
  headerColor?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  showLoginIcon?: Maybe<Scalars['Boolean']>;
  showPurchaserAppWord?: Maybe<Scalars['Boolean']>;
};

export type PurchaserContactEntity = AuditEntity & {
  __typename?: 'purchaserContactEntity';
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lead?: Maybe<Array<PurchaserLeadContactOutput>>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  phoneNo?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  user: PurchaserUserEntity;
  userId: Scalars['String'];
};

export type PurchaserLeadContactInput = {
  LeadId: Scalars['String'];
  ProjectId: Scalars['String'];
};

export type PurchaserLeadContactOutput = {
  __typename?: 'PurchaserLeadContactOutput';
  LeadId: Scalars['String'];
  ProjectId: Scalars['String'];
};

export type PurchaserNotificationEntity = {
  __typename?: 'purchaserNotificationEntity';
  accountId: Scalars['String'];
  body: Scalars['String'];
  id: Scalars['String'];
  projectId: Scalars['String'];
  purchaserNotificationRecipient: Array<PurchaserNotificationRecipientEntity>;
  subject: Scalars['String'];
};

export type PurchaserNotificationRecipientEntity = {
  __typename?: 'purchaserNotificationRecipientEntity';
  accountId: Scalars['String'];
  id: Scalars['String'];
  isRead: Scalars['Boolean'];
  notificationId: Scalars['String'];
  projectId: Scalars['String'];
  purchaserNotification: PurchaserNotificationEntity;
  recipientId: Scalars['String'];
};

export type PurchaserPaymentEntity = AuditEntity & {
  __typename?: 'purchaserPaymentEntity';
  accountId: Scalars['String'];
  amount: Scalars['Float'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  ifcaSaleId?: Maybe<Scalars['Float']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  paymentGatewayId?: Maybe<Scalars['String']>;
  paymentMethod: Scalars['String'];
  paymentStatus: Scalars['String'];
  receiptNo?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  response: Scalars['JSONObject'];
  saleId?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  transactionMethod?: Maybe<Scalars['String']>;
  unitId: Scalars['String'];
};

export type PurchaserReferenceInput = {
  AccountID: Scalars['Float'];
  IntegrationId?: InputMaybe<Scalars['String']>;
  IsIntegrate?: InputMaybe<Scalars['Boolean']>;
  ProjectId: Scalars['Float'];
  ProjectID: Scalars['Float'];
  SaleId: Scalars['Float'];
  SaleStatus: Scalars['String'];
  UnitId: Scalars['Float'];
};

export type PurchaserReferenceOutput = {
  __typename?: 'PurchaserReferenceOutput';
  AccountID: Scalars['Float'];
  IntegrationId?: Maybe<Scalars['String']>;
  IsIntegrate?: Maybe<Scalars['Boolean']>;
  ProjectId: Scalars['Float'];
  ProjectID: Scalars['Float'];
  SaleId: Scalars['Float'];
  SaleStatus: Scalars['String'];
  UnitId: Scalars['Float'];
};

export type PurchaserUserEntity = AuditEntity & {
  __typename?: 'purchaserUserEntity';
  accessToken?: Maybe<Scalars['String']>;
  activated: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  forgotPasswordLink?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  loginId: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  passwordHash?: Maybe<Scalars['String']>;
  purchaserAccountReference?: Maybe<PurchaserAccountReferenceEntity>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  deleteAttachment: Scalars['Boolean'];
  downloadAttachment: LoanDownloadAttachmentOuput;
  getAttachmentByLoanId: Array<AttachmentEntity>;
  getELetterPDF: ELetterOutput;
  getEndfinFinancierName: Scalars['String'];
  getESignLetter: Array<ESignProjectLetterListingOutput>;
  getFinancierStaff: Array<EndfinContactEntity>;
  getLoanCountByProjectId: ProjectEntity;
  getLoanDetailById: EndfinLoanAppEntity;
  getLoanListByProjectId: Array<EndfinLoanAppEntity>;
  getLoanListByProjectIdActive: Array<EndfinLoanAppEntity>;
  getLoanListByProjectIdLOAssignment: Array<EndfinLoanAppEntity>;
  getLoanListByProjectIdWithdrawn: Array<EndfinLoanAppEntity>;
  getMultiESignLetter: Array<MultipleEsignProjectLetterListingOutput>;
  getNotificationHistoryDay?: Maybe<Scalars['Float']>;
  getNotificationPermission: Scalars['JSONObject'];
  getNotifications?: Maybe<Scalars['JSONObject']>;
  getProjectbyId: ProjectEntity;
  getProjectLoan: Array<ProjectEntity>;
  getSolicitorListByProjectId: Array<SolicitorAppointedEntity>;
  getStaffDetails: EndfinUserEntity;
  getUserNotificationPermission?: Maybe<Array<Scalars['String']>>;
  GetWaterMarkText: Scalars['String'];
  login: EndfinUserEntity;
  loginInvitation: EndfinUserEntity;
  logout: Scalars['Boolean'];
  markAllRead: Scalars['Boolean'];
  passswordPolicy: PassswordPolicyOuput;
  profile: EndfinContactEntity;
  refreshToken?: Maybe<Scalars['String']>;
  registerToken?: Maybe<LoanRegisterOutput>;
};


export type QueryDeleteAttachmentArgs = {
  attachmentId: Scalars['String'];
};


export type QueryDownloadAttachmentArgs = {
  attachmentId: Scalars['String'];
};


export type QueryGetAttachmentByLoanIdArgs = {
  loanId: Scalars['String'];
};


export type QueryGetELetterPdfArgs = {
  letterTemplateId: Scalars['String'];
  loanId: Scalars['String'];
  projectId: Scalars['String'];
};


export type QueryGetESignLetterArgs = {
  loanId: Scalars['String'];
};


export type QueryGetLoanCountByProjectIdArgs = {
  project_id: Scalars['String'];
};


export type QueryGetLoanDetailByIdArgs = {
  loan_id: Scalars['String'];
};


export type QueryGetLoanListByProjectIdArgs = {
  loan_status_ids?: InputMaybe<Array<Scalars['String']>>;
  project_id: Scalars['String'];
  searchedDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryGetLoanListByProjectIdActiveArgs = {
  loan_status_ids?: InputMaybe<Scalars['String']>;
  project_id: Scalars['String'];
  searchedDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryGetLoanListByProjectIdLoAssignmentArgs = {
  project_id: Scalars['String'];
  searchedDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryGetLoanListByProjectIdWithdrawnArgs = {
  loan_status_ids?: InputMaybe<Scalars['String']>;
  project_id: Scalars['String'];
  searchedDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryGetMultiESignLetterArgs = {
  loanId: Scalars['String'];
};


export type QueryGetNotificationsArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetProjectbyIdArgs = {
  projectid: Scalars['String'];
};


export type QueryGetSolicitorListByProjectIdArgs = {
  project_id: Scalars['String'];
};


export type QueryGetStaffDetailsArgs = {
  user_id: Scalars['String'];
};


export type QueryLoginArgs = {
  FinancierId?: InputMaybe<Scalars['String']>;
  LoginId: Scalars['String'];
  Password: Scalars['String'];
};


export type QueryLoginInvitationArgs = {
  LoginId: Scalars['String'];
  Password: Scalars['String'];
};


export type QueryRegisterTokenArgs = {
  register_link: Scalars['String'];
};

export type QueryFilterString = {
  __typename?: 'QueryFilterString';
  Column: Scalars['String'];
  Expression: Scalars['String'];
  ParameterName?: Maybe<Scalars['String']>;
  Value: Scalars['String'];
};

export type QueryFilterStringInput = {
  Column: Scalars['String'];
  Expression: Scalars['String'];
  ParameterName?: InputMaybe<Scalars['String']>;
  Value: Scalars['String'];
};

export type QueryParameter = {
  __typename?: 'QueryParameter';
  Name: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
  Value: Scalars['String'];
  ValueType: Scalars['String'];
};

export type QueryParameterInput = {
  Name: Scalars['String'];
  Type?: InputMaybe<Scalars['String']>;
  Value: Scalars['String'];
  ValueType: Scalars['String'];
};

export type RaceEntity = {
  __typename?: 'raceEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  contacts: Array<ContactEntity>;
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  seqNo?: Maybe<Scalars['Float']>;
};

export type ReasonCodeEntity = {
  __typename?: 'reasonCodeEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  seqNo?: Maybe<Scalars['Float']>;
  systemUse: Scalars['Boolean'];
  type: Scalars['String'];
};

export type RebateEntity = {
  __typename?: 'rebateEntity';
  accountId: Scalars['String'];
  allocation?: Maybe<Array<AllocationEntity>>;
  allowReversal: Scalars['Boolean'];
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  billItemId: Scalars['String'];
  commonStatus: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  documentAmount: Scalars['Float'];
  documentDate: Scalars['DateTime'];
  documentNo?: Maybe<Scalars['String']>;
  documentRef?: Maybe<Scalars['String']>;
  glGenerated: Scalars['String'];
  glGenerationBatch?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  projectId: Scalars['String'];
  requestedBy?: Maybe<Scalars['String']>;
  saleId: Scalars['String'];
  saleRebatePackage: SaleRebatePackageEntity;
  status: Scalars['String'];
  submittedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
};

export type ReceiptEntity = {
  __typename?: 'receiptEntity';
  accountId: Scalars['String'];
  accounts?: Maybe<Array<CompanyBankAccountEntity>>;
  allocation?: Maybe<Array<AllocationEntity>>;
  allowReversal: Scalars['Boolean'];
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  bankAccountId?: Maybe<Scalars['String']>;
  cbGenerated: Scalars['Boolean'];
  cbGenerationBatch?: Maybe<Scalars['String']>;
  chequeDate?: Maybe<Scalars['DateTime']>;
  chequeExpireDate?: Maybe<Scalars['DateTime']>;
  commonStatus: Scalars['String'];
  creditCardFacilityId?: Maybe<Scalars['String']>;
  creditCardType?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  documentAmount: Scalars['Float'];
  documentDate: Scalars['DateTime'];
  documentNo: Scalars['String'];
  documentRef?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  financierId?: Maybe<Scalars['String']>;
  financiers?: Maybe<Array<FinancierEntity>>;
  generationBatch: Scalars['String'];
  generationBatchDate: Scalars['DateTime'];
  glGenerated: Scalars['String'];
  glGenerationBatch?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  paymentMethod: Scalars['String'];
  projectId: Scalars['String'];
  receiptAmount: Scalars['Float'];
  saleId: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  status: Scalars['String'];
  submittedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
};

export type ReceiptReversalEntity = {
  __typename?: 'receiptReversalEntity';
  accountId: Scalars['String'];
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  bankAccount?: Maybe<Array<CompanyBankAccountEntity>>;
  bankAccountId?: Maybe<Scalars['String']>;
  cbGenerated: Scalars['Boolean'];
  cbGenerationBatch?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  documentAmount: Scalars['Float'];
  documentDate: Scalars['DateTime'];
  documentNo: Scalars['String'];
  exchangeRate?: Maybe<Scalars['Float']>;
  financierId?: Maybe<Scalars['String']>;
  financiers?: Maybe<Array<FinancierEntity>>;
  glGenerated: Scalars['String'];
  glGenerationBatch?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  projectId: Scalars['String'];
  receiptAmount: Scalars['Float'];
  saleId: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  status: Scalars['String'];
  submittedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
};

export type RedemptionDetailEntity = {
  __typename?: 'redemptionDetailEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  contraId?: Maybe<Scalars['String']>;
  documentNo?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  projectId: Scalars['String'];
  redemption: RedemptionEntity;
  redemptionAmount: Scalars['Float'];
  redemptionId: Scalars['String'];
  unitId: Scalars['String'];
};

export type RedemptionEntity = {
  __typename?: 'redemptionEntity';
  accountId: Scalars['String'];
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  commonStatus: Scalars['String'];
  documentDate: Scalars['DateTime'];
  id: Scalars['String'];
  isPosted: Scalars['Boolean'];
  paidById: Scalars['String'];
  paymentParty: Scalars['String'];
  projectId: Scalars['String'];
  redemptionDetails: Array<RedemptionDetailEntity>;
  redemptionSum: Scalars['Float'];
  referenceNo?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  submittedBy?: Maybe<Scalars['DateTime']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
};

export type RefinancingEntity = {
  __typename?: 'refinancingEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  completedDate?: Maybe<Scalars['DateTime']>;
  doaDate: Scalars['DateTime'];
  doaSolicitorId: Scalars['String'];
  doaSolicitorReferenceNo: Scalars['String'];
  financierId: Scalars['String'];
  financierReferenceNo: Scalars['String'];
  generationBatch: Scalars['String'];
  id: Scalars['String'];
  loanAgreementDate: Scalars['DateTime'];
  loanAmount: Scalars['Float'];
  loanSeqNo?: Maybe<Scalars['Float']>;
  loanSolicitorId: Scalars['String'];
  loanSolicitorReferenceNo?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  refId: Scalars['String'];
  refTable?: Maybe<Scalars['String']>;
  rrDate: Scalars['DateTime'];
  rrSolicitorId: Scalars['String'];
  rrSolicitorReferenceNo: Scalars['String'];
  status: Scalars['String'];
};

export type RefundEntity = {
  __typename?: 'refundEntity';
  accountId: Scalars['String'];
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  bankAccountId?: Maybe<Scalars['String']>;
  chequeDate?: Maybe<Scalars['DateTime']>;
  chequeExpireDate?: Maybe<Scalars['DateTime']>;
  commonStatus: Scalars['String'];
  creditCardType?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  documentAmount: Scalars['Float'];
  documentDate: Scalars['DateTime'];
  documentNo?: Maybe<Scalars['String']>;
  documentRef?: Maybe<Scalars['String']>;
  glGenerated: Scalars['String'];
  glGenerationBatch?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  projectId: Scalars['String'];
  refundMethod: Scalars['String'];
  saleId: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  status: Scalars['String'];
  submittedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
};

export type RegionEntity = {
  __typename?: 'regionEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  seqNo?: Maybe<Scalars['Float']>;
  systemUse: Scalars['Boolean'];
};

export type ReminderEntity = {
  __typename?: 'reminderEntity';
  balanceAmount: Scalars['String'];
  batchId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  ledger: LedgerEntity;
  project: ProjectEntity;
  reminderDate: Scalars['DateTime'];
  reminderNo?: Maybe<Scalars['String']>;
  reminderSeq: Scalars['Float'];
  sale: SaleEntity;
  trxStatus: Scalars['String'];
};

export type ReportParameter = {
  __typename?: 'ReportParameter';
  AllowNull?: Maybe<Scalars['Boolean']>;
  Description: Scalars['String'];
  DropDownMember?: Maybe<DropDownMember>;
  GlobalType?: Maybe<Scalars['String']>;
  IsDateRange?: Maybe<Scalars['Boolean']>;
  MultiValue?: Maybe<Scalars['Boolean']>;
  Name: Scalars['String'];
  Tag?: Maybe<Scalars['String']>;
  Type: Scalars['String'];
  Visible?: Maybe<Scalars['Boolean']>;
};

export type ReportParameterInput = {
  AllowNull?: InputMaybe<Scalars['Boolean']>;
  Description: Scalars['String'];
  DropDownMember?: InputMaybe<DropDownMemberInput>;
  GlobalType?: InputMaybe<Scalars['String']>;
  IsDateRange?: InputMaybe<Scalars['Boolean']>;
  MultiValue?: InputMaybe<Scalars['Boolean']>;
  Name: Scalars['String'];
  Tag?: InputMaybe<Scalars['String']>;
  Type: Scalars['String'];
  Visible?: InputMaybe<Scalars['Boolean']>;
};

export type ReportPermissionEntity = AuditEntity & {
  __typename?: 'reportPermissionEntity';
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  permissionId: Scalars['Float'];
  roleId: Scalars['String'];
};

export type ResourceEntity = AuditEntity & {
  __typename?: 'resourceEntity';
  account_id: Scalars['String'];
  bucketFileName: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  fileName: Scalars['String'];
  id: Scalars['String'];
  isPrivate: Scalars['Boolean'];
  mediaType: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  pictureBlocks: Array<PictureBlockEntity>;
  pictureFloors: Array<PictureFloorEntity>;
  pictureGalleries: Array<PictureGalleryEntity>;
  pictureUnitlayouts: Array<PictureUnitlayoutEntity>;
  resourceUrl: Scalars['String'];
  salesCharts: Array<SalesChartEntity>;
};

export type RoleEntity = AuditEntity & {
  __typename?: 'roleEntity';
  accountId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  permissionIds: Array<Scalars['Float']>;
  permissions: Array<Scalars['Float']>;
  returnSalePermissionOutput: Array<SalesPermission>;
  salesAndReportPermission: Array<Scalars['Float']>;
  salesAndReportPermissionIds: Array<Scalars['Float']>;
  softwareCode: Scalars['String'];
  userCountByRole: Scalars['Float'];
  users?: Maybe<Array<UserEntity>>;
};


export type RoleEntityUsersArgs = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type SaleCancellationEntity = AuditEntity & {
  __typename?: 'saleCancellationEntity';
  accountId: Scalars['String'];
  cancellationDate: Scalars['DateTime'];
  cancelReason: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  forfeitureSum: Scalars['Float'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  projectId: Scalars['String'];
  remark: Scalars['String'];
  saleId: Scalars['String'];
  sales: SaleEntity;
  status: Scalars['String'];
  taxSchemeId: Scalars['String'];
};

export type SaleContactEntity = AuditEntity & {
  __typename?: 'saleContactEntity';
  accountId: Scalars['String'];
  contactNo: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['String'];
  isPrimary: Scalars['Boolean'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  officeTel?: Maybe<Scalars['String']>;
  parameter?: Maybe<ParameterEntity>;
  relationshipId?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  saleId: Scalars['String'];
  salutation?: Maybe<SalutationEntity>;
  salutationId?: Maybe<Scalars['String']>;
};

export type SaleDiscountEntity = {
  __typename?: 'saleDiscountEntity';
  amount: Scalars['Float'];
  discount: DiscountEntity;
  discountId: Scalars['String'];
  id: Scalars['String'];
  sale: SaleEntity;
  saleDiscountExtension?: Maybe<SalesDiscountExtEntity>;
  saleId: Scalars['String'];
  seqNo: Scalars['Float'];
};

export type SaleEntity = {
  __typename?: 'saleEntity';
  accountId: Scalars['String'];
  accountSpec?: Maybe<AccountSpecEntity>;
  address?: Maybe<AddressJson>;
  addressList?: Maybe<Array<ContactAddressEntity>>;
  agencyAccId?: Maybe<AgencyEntity>;
  agencyAcctId?: Maybe<Scalars['String']>;
  agencyContractId?: Maybe<Scalars['String']>;
  agencyUser?: Maybe<AgencyContactEntity>;
  agentPersonId?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  areaVariance?: Maybe<Array<AreaVarianceEntity>>;
  attachment?: Maybe<AttachmentEntity>;
  attendedBy?: Maybe<Scalars['String']>;
  billMethod?: Maybe<Scalars['String']>;
  bookDate: Scalars['DateTime'];
  bookingAmount?: Maybe<Scalars['Float']>;
  bookingAttachments: BookingAttachmentsOutput;
  bookingAttachmentsCount: Scalars['Float'];
  bookingBadgeCount: Scalars['Float'];
  bookingFeeAttachments: Array<AttachmentEntity>;
  bookingFeeInfo?: Maybe<SalePaymentEntity>;
  bookingRemark?: Maybe<Scalars['String']>;
  bookingTimeTaken?: Maybe<Scalars['Float']>;
  buyerIdentityNo?: Maybe<Scalars['String']>;
  buyerIdentityType?: Maybe<Scalars['String']>;
  buyerName?: Maybe<Scalars['String']>;
  buyerReference?: Maybe<Scalars['String']>;
  buyerSignatureUrl?: Maybe<Scalars['String']>;
  buyerSolicitor?: Maybe<SolicitorAppointedEntity>;
  cancelledBy?: Maybe<Scalars['String']>;
  cancelledByUser?: Maybe<UserEntity>;
  cancelledDate?: Maybe<Scalars['DateTime']>;
  cancelledReason?: Maybe<Scalars['String']>;
  cancelReasonId?: Maybe<Scalars['String']>;
  collectedDate?: Maybe<Scalars['DateTime']>;
  commonStatus: Scalars['String'];
  contact: ContactEntity;
  contactAddress?: Maybe<Array<AddressJson>>;
  contactExt?: Maybe<ContactExtensionEntity>;
  contactExtention?: Maybe<ContactExtensionEntity>;
  contactId?: Maybe<Scalars['String']>;
  convertedToBooking: Scalars['Boolean'];
  debitNote?: Maybe<Array<DebitNoteEntity>>;
  developerReference?: Maybe<Scalars['String']>;
  developerSolicitor?: Maybe<SolicitorAppointedEntity>;
  developerSolicitorId?: Maybe<Scalars['String']>;
  developerSpaStatus?: Maybe<Scalars['String']>;
  discount: Array<SaleDiscountEntity>;
  distance?: Maybe<Scalars['String']>;
  elaunchId?: Maybe<Scalars['String']>;
  employerAddress?: Maybe<Scalars['String']>;
  employerName?: Maybe<Scalars['String']>;
  endfinLoanApps: Array<EndfinLoanAppEntity>;
  endfinLoanAppsCount: Scalars['Float'];
  eventId?: Maybe<Scalars['String']>;
  events?: Maybe<EventsEntity>;
  expectedSnpDate?: Maybe<Scalars['DateTime']>;
  followUpBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  ifcaContactId: Scalars['String'];
  ifcaSaleId: Scalars['Float'];
  ifcaSaleNo: Scalars['String'];
  incomeRange?: Maybe<IncomeRangeEntity>;
  integrationDiscount?: Maybe<Array<IntegrationDiscountEntity>>;
  interests: Array<InterestEntity>;
  isAutoReleaseNotified: Scalars['Boolean'];
  isBumi: Scalars['Boolean'];
  isCancelNotified: Scalars['Boolean'];
  isCompany: Scalars['Boolean'];
  isCustomisedBookingForm: Scalars['Boolean'];
  joinBuyer: Array<JointBuyerEntity>;
  joinBuyerInfo?: Maybe<JointBuyerInfoForBookingListing>;
  jointBuyers: Array<JointBuyerEntity>;
  keyCollectionAppointment?: Maybe<Array<KeyCollectionAppointmentEntity>>;
  lateInterest?: Maybe<Array<LateInterestEntity>>;
  lateInterestException?: Maybe<ProjectLateInterestExceptionEntity>;
  lead: LeadEntity;
  leadId?: Maybe<Scalars['String']>;
  leadSource?: Maybe<LeadSourceEntity>;
  ledgers: Array<LedgerEntity>;
  letterSign: Array<LetterSignEntity>;
  listPrice?: Maybe<Scalars['Float']>;
  loanScreening?: Maybe<LoanScreenStatusOutput>;
  loanScreeningReports?: Maybe<Array<LoanScreenReportOutput>>;
  markUpAmount?: Maybe<Scalars['Float']>;
  markUpBy?: Maybe<Scalars['String']>;
  markUpReason?: Maybe<Scalars['String']>;
  markUpValue?: Maybe<Scalars['Float']>;
  miscBilling?: Maybe<Array<MiscBillingEntity>>;
  motTracking?: Maybe<Array<MotTrackingEntity>>;
  nonIntegratedRebate?: Maybe<Array<SaleRebateEntity>>;
  otherAttachments: Array<AttachmentEntity>;
  package?: Maybe<Array<Scalars['String']>>;
  packageList: Array<PackageEntity>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentMethodDesc?: Maybe<Scalars['String']>;
  paymentReference?: Maybe<Scalars['String']>;
  paymentRemark?: Maybe<Scalars['String']>;
  PdpaSignatureUrl?: Maybe<Scalars['String']>;
  pendingIntegrate: Scalars['Boolean'];
  progressBilling?: Maybe<Array<ProgressBillingEntity>>;
  progressLogSolicitor?: Maybe<Array<ProgressLogDetails>>;
  project: ProjectEntity;
  projectCompany: CompanyEntity;
  projectId: Scalars['String'];
  projectIntegration?: Maybe<Array<ProjectIntegrationEntity>>;
  receipt?: Maybe<Array<ReceiptEntity>>;
  receiptReversal?: Maybe<Array<ReceiptReversalEntity>>;
  referralContactNo?: Maybe<Scalars['String']>;
  referralName?: Maybe<Scalars['String']>;
  referralReferenceNo?: Maybe<Scalars['String']>;
  refund?: Maybe<Array<RefundEntity>>;
  reminders: Array<ReminderEntity>;
  repeatPurchaserAgency?: Maybe<Scalars['Float']>;
  repeatPurchaserSales?: Maybe<Scalars['Float']>;
  reserveDate?: Maybe<Scalars['DateTime']>;
  retryCount: Scalars['Float'];
  saleCancellation?: Maybe<SaleCancellationEntity>;
  saleExt?: Maybe<SaleExtensionEntity>;
  saleExtension?: Maybe<SaleExtensionEntity>;
  saleLoan?: Maybe<Array<SaleLoanEntity>>;
  saleOrigin: Scalars['String'];
  salePayment?: Maybe<SalePaymentEntity>;
  saleRebate?: Maybe<Array<SaleRebatePackageEntity>>;
  salesDiscount: Array<SaleDiscountEntity>;
  salesNo: Scalars['String'];
  salesPerson?: Maybe<Scalars['String']>;
  salesPersonId?: Maybe<Scalars['String']>;
  salesStatus: Scalars['String'];
  SaleStatusList: Scalars['JSONObject'];
  salesTeam?: Maybe<SaleTeamEntity>;
  salesTeamId?: Maybe<Scalars['String']>;
  salesTeamName?: Maybe<Scalars['String']>;
  schedules: Array<ScheduleEntity>;
  sellingPrice?: Maybe<Scalars['Float']>;
  snpReminderAfterDaysNotify: Scalars['Boolean'];
  snpReminderBeforeDaysNotify: Scalars['Boolean'];
  solicitorProgressLog?: Maybe<Array<SolicitorProgressLogEntity>>;
  spaDate?: Maybe<Scalars['DateTime']>;
  spaNo?: Maybe<Scalars['String']>;
  spaReminder?: Maybe<Array<SpaReminderEntity>>;
  spouse?: Maybe<ContactEntity>;
  spouseContactId?: Maybe<Scalars['String']>;
  stakeholder: SolicitorAppointedEntity;
  stampDate?: Maybe<Scalars['DateTime']>;
  subProduct?: Maybe<Array<SaleSubproductEntity>>;
  taxAmt?: Maybe<Scalars['Float']>;
  taxDetail?: Maybe<Array<SaleTaxOutput>>;
  taxRate?: Maybe<Scalars['Float']>;
  trNo: Scalars['String'];
  unit: UnitEntity;
  unitId: Scalars['String'];
  unitIntegration?: Maybe<UnitIntegrationEntity>;
  vpDate?: Maybe<Scalars['DateTime']>;
};

export type SaleExtensionEntity = AuditEntity & {
  __typename?: 'saleExtensionEntity';
  accountId: Scalars['String'];
  campaignId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  creditTerm?: Maybe<Scalars['Float']>;
  discardBumiDiscount?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isCashBuyer?: Maybe<Scalars['Boolean']>;
  isEInvoiceRequired: Scalars['Boolean'];
  isGenerateInterest?: Maybe<Scalars['Boolean']>;
  isGenerateReminder?: Maybe<Scalars['Boolean']>;
  isGovAuth: Scalars['Boolean'];
  loanGracePeriod?: Maybe<Scalars['Float']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  paymentPlan?: Maybe<PaymentPlanEntity>;
  paymentPlanId?: Maybe<Scalars['String']>;
  saleId: Scalars['String'];
  sales: SaleEntity;
  salesType?: Maybe<Scalars['String']>;
  totalSalesValue?: Maybe<Scalars['Float']>;
};

export type SaleLeadNotificationInput = {
  booking?: InputMaybe<BookingNotificationInput>;
  bookingCancellation?: InputMaybe<BookingCancellationNotificationInput>;
  bookingReassignment?: InputMaybe<Scalars['Boolean']>;
  leadAssignment?: InputMaybe<Scalars['Boolean']>;
  loanAssignment?: InputMaybe<LoanAssignmentInput>;
  snpSigningReminder?: InputMaybe<SnpSigningReminderInput>;
  solicitorCaseAssignment?: InputMaybe<SolicitorCaseAssignmentInput>;
};

export type SaleLeadNotificationOutput = {
  __typename?: 'SaleLeadNotificationOutput';
  booking?: Maybe<BookingNotificationOutput>;
  bookingCancellation?: Maybe<BookingCancellationNotificationOutput>;
  bookingReassignment?: Maybe<Scalars['Boolean']>;
  leadAssignment?: Maybe<Scalars['Boolean']>;
  loanAssignment?: Maybe<LoanAssignmentOutput>;
  snpSigningReminder?: Maybe<SnpSigningReminderOutput>;
  solicitorCaseAssignment?: Maybe<SolicitorCaseAssignmentOutput>;
};

export type SaleLoanCancellationDetailEntity = AuditEntity & {
  __typename?: 'saleLoanCancellationDetailEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  saleLoanCancellationId: Scalars['String'];
  saleLoanId: Scalars['String'];
};

export type SaleLoanCancellationEntity = AuditEntity & {
  __typename?: 'saleLoanCancellationEntity';
  accountId: Scalars['String'];
  approvalStatus: Scalars['String'];
  cancelDate: Scalars['DateTime'];
  cancelReason: Scalars['String'];
  cancelRemark?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  saleId: Scalars['String'];
};

export type SaleLoanEntity = AuditEntity & {
  __typename?: 'saleLoanEntity';
  accountId: Scalars['String'];
  borrowerAddress: AddressJson;
  borrowerName: Scalars['String'];
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancelReason?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  financialBilling?: Maybe<Array<FinancialBillingEntity>>;
  financierId: Scalars['String'];
  financierReference: Scalars['String'];
  financiers?: Maybe<Array<FinancierEntity>>;
  generationBatch?: Maybe<Scalars['String']>;
  generationBatchDate?: Maybe<Scalars['DateTime']>;
  gstFinanceByBank: Scalars['Boolean'];
  id: Scalars['String'];
  loanAmount: Scalars['Float'];
  loanApprovalDate?: Maybe<Scalars['DateTime']>;
  loanLimit: Scalars['Float'];
  loanLooDate?: Maybe<Scalars['DateTime']>;
  loanPercentage: Scalars['Float'];
  loanPeriod?: Maybe<Scalars['Float']>;
  loanSeqno: Scalars['Float'];
  loanSignDate?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  remark?: Maybe<Scalars['String']>;
  renoticeDate?: Maybe<Scalars['DateTime']>;
  rrDate?: Maybe<Scalars['DateTime']>;
  rrSolicitorId?: Maybe<Scalars['String']>;
  rrSolicitorReferenceNo?: Maybe<Scalars['String']>;
  sale?: Maybe<SaleEntity>;
  saleId: Scalars['String'];
  solicitorAppointed?: Maybe<Array<SolicitorAppointedEntity>>;
  solicitorId: Scalars['String'];
  solicitorReference: Scalars['String'];
  taxSchemeId: Scalars['String'];
  totalLoanAmount: Scalars['Float'];
};

export type SalePackageEntity = AuditEntity & {
  __typename?: 'salePackageEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  discountAmount?: Maybe<Scalars['Float']>;
  discountId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isIncludeContract: Scalars['Boolean'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  openMarketValue: Scalars['Float'];
  package: PackageEntity;
  packageId: Scalars['String'];
  paymentPlanId?: Maybe<Scalars['String']>;
  saleId: Scalars['String'];
  sellingMethod: Scalars['String'];
  sellingPrice: Scalars['Float'];
  taxSchemeId: Scalars['String'];
  workProgress: WorkProgressEntity;
  workProgressId: Scalars['String'];
};

export type SalePaymentEntity = {
  __typename?: 'salePaymentEntity';
  accountId: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  attachment?: Maybe<AttachmentEntity>;
  bookingFeeAttachments: Array<AttachmentEntity>;
  id: Scalars['String'];
  paymentGatewayId?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentMethodDesc?: Maybe<Scalars['String']>;
  receiptNo?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  response: Scalars['JSONObject'];
  sale: SaleEntity;
  saleId: Scalars['String'];
  status: Scalars['String'];
  transactionDate?: Maybe<Scalars['DateTime']>;
  transactionMethod?: Maybe<Scalars['String']>;
};

export type SalePaymentValueOutput = {
  __typename?: 'SalePaymentValueOutput';
  amount: Scalars['Float'];
  receiptNo: Scalars['String'];
};

export type SaleRebateEntity = {
  __typename?: 'saleRebateEntity';
  amount: Scalars['Float'];
  discount: DiscountEntity;
  discountId: Scalars['String'];
  id: Scalars['String'];
  sale: SaleEntity;
  saleDiscountExtension?: Maybe<SalesDiscountExtEntity>;
  saleId: Scalars['String'];
  seqNo: Scalars['Float'];
};

export type SaleRebatePackageEntity = AuditEntity & {
  __typename?: 'saleRebatePackageEntity';
  accountId: Scalars['String'];
  campaign: CampaignEntity;
  campaignId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  discount: DiscountEntity;
  discountAmount: Scalars['Float'];
  discountId: Scalars['String'];
  discountMethod: Scalars['String'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  rebate?: Maybe<RebateEntity>;
  rebateId?: Maybe<Scalars['String']>;
  roundingMode: Scalars['String'];
  roundingPrecision: Scalars['String'];
  saleId: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  seqNo?: Maybe<Scalars['Float']>;
  workProgress: WorkProgressEntity;
  workProgressId: Scalars['String'];
};

export type SalesActivityEntity = {
  __typename?: 'salesActivityEntity';
  accountId: Scalars['String'];
  actionBy?: Maybe<Scalars['String']>;
  activityDate: Scalars['DateTime'];
  activityDesc?: Maybe<Scalars['String']>;
  activityType: Scalars['String'];
  fromPerson?: Maybe<Scalars['String']>;
  fromTeam?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  person?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  salesPersonType: Scalars['String'];
  team?: Maybe<Scalars['String']>;
};

export type SalesAgingDetails = {
  __typename?: 'SalesAgingDetails';
  alertAgingActivated?: Maybe<Scalars['Boolean']>;
  salesAlertAging?: Maybe<SalesAlertAginggOutput>;
};

export type SalesAlertAginggOutput = {
  __typename?: 'SalesAlertAginggOutput';
  isActive: Scalars['Boolean'];
  maxMajorSalesAlertAging: Scalars['Float'];
  maxMinorSalesAlertAging: Scalars['Float'];
};

export type SalesAlertAgingInput = {
  isActive: Scalars['Boolean'];
  maxMajorSalesAlertAging: Scalars['Float'];
  maxMinorSalesAlertAging: Scalars['Float'];
};

export type SalesAlertAgingOutput = {
  __typename?: 'SalesAlertAgingOutput';
  isActive: Scalars['Boolean'];
  maxMajorSalesAlertAging: Scalars['Float'];
  maxMinorSalesAlertAging: Scalars['Float'];
};

export type SalesCancellationEntity = AuditEntity & {
  __typename?: 'salesCancellationEntity';
  accountId: Scalars['String'];
  approvalLevel?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  notifiedDate: Scalars['DateTime'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  status: Scalars['String'];
};

export type SalesChartEntity = {
  __typename?: 'salesChartEntity';
  block: Scalars['String'];
  coordinates: Scalars['String'];
  floor: Scalars['Float'];
  id: Scalars['String'];
  project: ProjectEntity;
  resource: ResourceEntity;
  unit: UnitEntity;
};

export type SalesDiscountExtEntity = AuditEntity & {
  __typename?: 'salesDiscountExtEntity';
  accountId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  discountMethod: Scalars['String'];
  formula: Scalars['String'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  saleDiscount: SaleDiscountEntity;
  saleDiscountId: Scalars['String'];
};

export type SalesDocEntity = AuditEntity & {
  __typename?: 'salesDocEntity';
  accountId: Scalars['String'];
  allowAgency: Scalars['Boolean'];
  allowSalesTeam: Scalars['Boolean'];
  attachment: AttachmentEntity;
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  docName: Scalars['String'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  projectId: Scalars['String'];
  type: Scalars['String'];
  typeDescription: Scalars['String'];
};

export type SalesGalleryCloseDateEntity = {
  __typename?: 'salesGalleryCloseDateEntity';
  accountId: Scalars['String'];
  appointmentScheduling: AppointmentSchedulingEntity;
  appointmentSchedulingId: Scalars['String'];
  endDate: Scalars['DateTime'];
  endTime: Scalars['String'];
  id: Scalars['String'];
  projectId: Scalars['String'];
  startDate: Scalars['DateTime'];
  startTime: Scalars['String'];
};

export type SalesLeadOutput = {
  __typename?: 'SalesLeadOutput';
  coldTotal?: Maybe<Scalars['Float']>;
  data?: Maybe<Array<LeadDataOutput>>;
  dropTotal?: Maybe<Scalars['Float']>;
  hotTotal?: Maybe<Scalars['Float']>;
  openTotal?: Maybe<Scalars['Float']>;
  reservedTotal?: Maybe<Scalars['Float']>;
  salesAgingDetails?: Maybe<SalesAgingDetails>;
  showSaleStaff?: Maybe<Scalars['Boolean']>;
  signTotal?: Maybe<Scalars['Float']>;
  soldTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  waitingListTotal?: Maybe<Scalars['Float']>;
  warmTotal?: Maybe<Scalars['Float']>;
};

export enum SalesPermission {
  AdminRead = 'ADMIN__READ',
  AdminProjectCarParkSetupRead = 'ADMIN_PROJECT_CAR_PARK_SETUP_READ',
  AdminProjectDiscountRead = 'ADMIN_PROJECT_DISCOUNT_READ',
  AdminProjectEmailLetterRead = 'ADMIN_PROJECT_EMAIL_LETTER_READ',
  AdminProjectGalleryRead = 'ADMIN_PROJECT_GALLERY_READ',
  AdminProjectLayoutImagesRead = 'ADMIN_PROJECT_LAYOUT_IMAGES_READ',
  AdminProjectPanelEndFinancierRead = 'ADMIN_PROJECT_PANEL_END_FINANCIER_READ',
  AdminProjectPanelSolicitorRead = 'ADMIN_PROJECT_PANEL_SOLICITOR_READ',
  AdminProjectPolicySettingRead = 'ADMIN_PROJECT_POLICY_SETTING_READ',
  AdminProjectProjectDetailsRead = 'ADMIN_PROJECT_PROJECT_DETAILS_READ',
  AdminProjectRoleAuthorizationRead = 'ADMIN_PROJECT_ROLE_AUTHORIZATION_READ',
  AdminProjectSalesChartSetupRead = 'ADMIN_PROJECT_SALES_CHART_SETUP_READ',
  AdminProjectSalesDocumentsRead = 'ADMIN_PROJECT_SALES_DOCUMENTS_READ',
  AdminProjectSalesPackagesRead = 'ADMIN_PROJECT_SALES_PACKAGES_READ',
  AdminProjectSiteProgressRead = 'ADMIN_PROJECT_SITE_PROGRESS_READ',
  AdminProjectStandardLetterRead = 'ADMIN_PROJECT_STANDARD_LETTER_READ',
  AdminProjectTeamAssignmentRead = 'ADMIN_PROJECT_TEAM_ASSIGNMENT_READ',
  AdminProjectUnitAllocationRead = 'ADMIN_PROJECT_UNIT_ALLOCATION_READ',
  AdminProjectUnitSetupRead = 'ADMIN_PROJECT_UNIT_SETUP_READ',
  ProjectReadAll = 'PROJECT_______READ_ALL',
  ProjectReadInternalAndAgencyTeam = 'PROJECT_______READ_INTERNAL_AND_AGENCY_TEAM',
  ProjectReadSelf = 'PROJECT_______READ_SELF',
  ProjectReadTeam = 'PROJECT_______READ_TEAM',
  ProjectAppointedAgencyRead = 'PROJECT_APPOINTED_AGENCY____READ',
  ProjectBookingListAllowBook = 'PROJECT_BOOKING_LIST____ALLOW_BOOK',
  ProjectBookingListCancel = 'PROJECT_BOOKING_LIST____CANCEL',
  ProjectBookingListEditDiscount = 'PROJECT_BOOKING_LIST____EDIT_DISCOUNT',
  ProjectBookingListEditPurchaserAndJointBuyer = 'PROJECT_BOOKING_LIST____EDIT_PURCHASER_AND_JOINT_BUYER',
  ProjectBookingListEditPurchaserAndJointBuyerSequence = 'PROJECT_BOOKING_LIST____EDIT_PURCHASER_AND_JOINT_BUYER_SEQUENCE',
  ProjectBookingListEditSalesPackages = 'PROJECT_BOOKING_LIST____EDIT_SALES_PACKAGES',
  ProjectBookingListEditSalesRebates = 'PROJECT_BOOKING_LIST____EDIT_SALES_REBATES',
  ProjectBookingListEditSalesTeamAndSalesPersonnel = 'PROJECT_BOOKING_LIST____EDIT_SALES_TEAM_AND_SALES_PERSONNEL',
  ProjectBookingListOnline = 'PROJECT_BOOKING_LIST____ONLINE',
  ProjectBookingListRead = 'PROJECT_BOOKING_LIST____READ',
  ProjectBusinessActiveSalesPerformanceRead = 'PROJECT_BUSINESS_ACTIVE_SALES_PERFORMANCE_READ',
  ProjectBusinessInsightsRead = 'PROJECT_BUSINESS_INSIGHTS____READ',
  ProjectBusinessInsightsBookingListAnalysisRead = 'PROJECT_BUSINESS_INSIGHTS_BOOKING_LIST_ANALYSIS_READ',
  ProjectBusinessInsightsBookingListRead = 'PROJECT_BUSINESS_INSIGHTS_BOOKING_LIST_READ',
  ProjectBusinessInsightsCampaignPerformanceRead = 'PROJECT_BUSINESS_INSIGHTS_CAMPAIGN_PERFORMANCE_READ',
  ProjectBusinessInsightsELaunchBookingTimeTakenRead = 'PROJECT_BUSINESS_INSIGHTS_E_LAUNCH_BOOKING_TIME_TAKEN_READ',
  ProjectBusinessInsightsELaunchMostFavouriteRead = 'PROJECT_BUSINESS_INSIGHTS_E_LAUNCH_MOST_FAVOURITE_READ',
  ProjectBusinessInsightsELaunchPerformanceRead = 'PROJECT_BUSINESS_INSIGHTS_E_LAUNCH_PERFORMANCE_READ',
  ProjectBusinessInsightsELaunchPurchaserDemographicRead = 'PROJECT_BUSINESS_INSIGHTS_E_LAUNCH_PURCHASER_DEMOGRAPHIC_READ',
  ProjectBusinessInsightsLeadDemographicRead = 'PROJECT_BUSINESS_INSIGHTS_LEAD_DEMOGRAPHIC_READ',
  ProjectBusinessInsightsLeadSourceRead = 'PROJECT_BUSINESS_INSIGHTS_LEAD_SOURCE_READ',
  ProjectBusinessInsightsLoanRead = 'PROJECT_BUSINESS_INSIGHTS_LOAN_READ',
  ProjectBusinessInsightsPaymentSourceRead = 'PROJECT_BUSINESS_INSIGHTS_PAYMENT_SOURCE_READ',
  ProjectBusinessInsightsPurchaserDemographicRead = 'PROJECT_BUSINESS_INSIGHTS_PURCHASER_DEMOGRAPHIC_READ',
  ProjectBusinessInsightsSalesLeadPerformanceRead = 'PROJECT_BUSINESS_INSIGHTS_SALES_LEAD_PERFORMANCE_READ',
  ProjectBusinessInsightsSalesLeadTeamPerformanceRead = 'PROJECT_BUSINESS_INSIGHTS_SALES_LEAD_TEAM_PERFORMANCE_READ',
  ProjectBusinessInsightsSalesMovementRead = 'PROJECT_BUSINESS_INSIGHTS_SALES_MOVEMENT_READ',
  ProjectBusinessInsightsSalesPerformanceRead = 'PROJECT_BUSINESS_INSIGHTS_SALES_PERFORMANCE_READ',
  ProjectBusinessInsightsSalesTeamPerformanceRead = 'PROJECT_BUSINESS_INSIGHTS_SALES_TEAM_PERFORMANCE_READ',
  ProjectBusinessInsightsWaitingListRead = 'PROJECT_BUSINESS_INSIGHTS_WAITING_LIST_READ',
  ProjectDigitalDocumentsPurchaserBooking = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_BOOKING',
  ProjectDigitalDocumentsPurchaserBookingCreate = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_BOOKING_CREATE',
  ProjectDigitalDocumentsPurchaserBookingDelete = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_BOOKING_DELETE',
  ProjectDigitalDocumentsPurchaserLoan = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_LOAN',
  ProjectDigitalDocumentsPurchaserLoanCreate = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_LOAN_CREATE',
  ProjectDigitalDocumentsPurchaserLoanDelete = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_LOAN_DELETE',
  ProjectDigitalDocumentsPurchaserSales = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_SALES',
  ProjectDigitalDocumentsPurchaserSalesCreate = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_SALES_CREATE',
  ProjectDigitalDocumentsPurchaserSalesDelete = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_SALES_DELETE',
  ProjectLoanRead = 'PROJECT_LOAN____READ',
  ProjectNotificationBookingListAllowBook = 'PROJECT_NOTIFICATION_BOOKING_LIST_ALLOW_BOOK',
  ProjectNotificationBookingListBookingFee = 'PROJECT_NOTIFICATION_BOOKING_LIST_BOOKING_FEE',
  ProjectNotificationBookingListCancel = 'PROJECT_NOTIFICATION_BOOKING_LIST_CANCEL',
  ProjectNotificationBookingListSpaCollected = 'PROJECT_NOTIFICATION_BOOKING_LIST_SPA_COLLECTED',
  ProjectNotificationBookingListSpaReminder = 'PROJECT_NOTIFICATION_BOOKING_LIST_SPA_REMINDER',
  ProjectNotificationBookingListSpaSign = 'PROJECT_NOTIFICATION_BOOKING_LIST_SPA_SIGN',
  ProjectNotificationBookingListSpaStamped = 'PROJECT_NOTIFICATION_BOOKING_LIST_SPA_STAMPED',
  ProjectNotificationCollaborativeAgecny = 'PROJECT_NOTIFICATION_COLLABORATIVE_AGECNY',
  ProjectNotificationCollaborativeEndFinancier = 'PROJECT_NOTIFICATION_COLLABORATIVE_END_FINANCIER',
  ProjectNotificationCollaborativeSolicitor = 'PROJECT_NOTIFICATION_COLLABORATIVE_SOLICITOR',
  ProjectNotificationLoanAccepted = 'PROJECT_NOTIFICATION_LOAN_ACCEPTED',
  ProjectNotificationLoanAmountUpdate = 'PROJECT_NOTIFICATION_LOAN_AMOUNT_UPDATE',
  ProjectNotificationLoanAssign = 'PROJECT_NOTIFICATION_LOAN_ASSIGN',
  ProjectNotificationLoanIssued = 'PROJECT_NOTIFICATION_LOAN_ISSUED',
  ProjectNotificationLoanWithdrawal = 'PROJECT_NOTIFICATION_LOAN_WITHDRAWAL',
  ProjectNotificationSalesLeadAging = 'PROJECT_NOTIFICATION_SALES_LEAD_AGING',
  ProjectNotificationSalesLeadAssign = 'PROJECT_NOTIFICATION_SALES_LEAD_ASSIGN',
  ProjectNotificationSalesLeadAssignProejct = 'PROJECT_NOTIFICATION_SALES_LEAD_ASSIGN_PROEJCT',
  ProjectNotificationSalesLeadDrop = 'PROJECT_NOTIFICATION_SALES_LEAD_DROP',
  ProjectNotificationSalesLeadReassign = 'PROJECT_NOTIFICATION_SALES_LEAD_REASSIGN',
  ProjectNotificationSalesLeadUnattended = 'PROJECT_NOTIFICATION_SALES_LEAD_UNATTENDED',
  ProjectNotificationWaitingListAvailable = 'PROJECT_NOTIFICATION_WAITING_LIST_AVAILABLE',
  ProjectNotificationWaitingListLeadJoint = 'PROJECT_NOTIFICATION_WAITING_LIST_LEAD_JOINT',
  ProjectNotificationWaitingListLeadLeaveQueue = 'PROJECT_NOTIFICATION_WAITING_LIST_LEAD_LEAVE_QUEUE',
  ProjectNotificationWaitingListMoveToTop = 'PROJECT_NOTIFICATION_WAITING_LIST_MOVE_TO_TOP',
  ProjectNotificationWaitingListUnavailable = 'PROJECT_NOTIFICATION_WAITING_LIST_UNAVAILABLE',
  ProjectSalesLeadAssign = 'PROJECT_SALES_LEAD____ASSIGN',
  ProjectSalesLeadRead = 'PROJECT_SALES_LEAD____READ',
  ProjectSolicitorAssignmentRead = 'PROJECT_SOLICITOR_ASSIGNMENT____READ',
  ProjectSolicitorAssignmentReassignment = 'PROJECT_SOLICITOR_ASSIGNMENT_REASSIGNMENT',
  ProjectSolicitorSpaSign = 'PROJECT_SOLICITOR_SPA_SIGN',
  ProjectWaitingListLeaveQueue = 'PROJECT_WAITING_LIST____LEAVE_QUEUE',
  ProjectWaitingListMoveToTop = 'PROJECT_WAITING_LIST____MOVE_TO_TOP',
  ProjectWaitingListRead = 'PROJECT_WAITING_LIST____READ',
  WorkdeskProjectWorkdeskAppointmentAppointmentAdd = 'WORKDESK_PROJECT_WORKDESK_APPOINTMENT_APPOINTMENT_ADD'
}

export type SalesStatisticEntity = AuditEntity & {
  __typename?: 'salesStatisticEntity';
  accountId: Scalars['String'];
  contactId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  listPrice: Scalars['Float'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  saleStatus: Scalars['String'];
  spaPrice?: Maybe<Scalars['Float']>;
  transactionDate: Scalars['DateTime'];
  unitId: Scalars['String'];
};

export type SalesTeamOutput = {
  __typename?: 'SalesTeamOutput';
  commonStatus?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  users: Array<SalesTeamUserOuput>;
};

export type SalesTeamUserInfoOuput = {
  __typename?: 'SalesTeamUserInfoOuput';
  contactNo: Scalars['String'];
  email: Scalars['String'];
  ID: Scalars['String'];
  name: Scalars['String'];
};

export type SalesTeamUserOuput = {
  __typename?: 'SalesTeamUserOuput';
  id: Scalars['String'];
  leadCount?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  teamId: Scalars['String'];
};

export type SaleSubproductEntity = AuditEntity & {
  __typename?: 'saleSubproductEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  discount?: Maybe<DiscountEntity>;
  discountAmount?: Maybe<Scalars['Float']>;
  discountId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  paymentPlanId?: Maybe<Scalars['String']>;
  progressBilling?: Maybe<Array<ProgressBillingEntity>>;
  saleId: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  sellingMethod: Scalars['String'];
  sellingPrice: Scalars['Float'];
  unit?: Maybe<UnitEntity>;
  unitId: Scalars['String'];
};

export type SaleTaxOutput = {
  __typename?: 'SaleTaxOutput';
  description: Scalars['String'];
  sale_id: Scalars['String'];
  tax_code?: Maybe<Scalars['String']>;
  tax_desc?: Maybe<Scalars['String']>;
  tax_id: Scalars['String'];
  tax_rate: Scalars['Float'];
};

export type SaleTeamAllocationEntity = {
  __typename?: 'saleTeamAllocationEntity';
  accountId: Scalars['String'];
  id: Scalars['String'];
  project: ProjectEntity;
  projectId: Scalars['String'];
  saleTeam: SaleTeamEntity;
  teamId: Scalars['String'];
  unit: UnitEntity;
  unitId: Scalars['String'];
};

export type SaleTeamEntity = {
  __typename?: 'saleTeamEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  enableAllocation: Scalars['Boolean'];
  id: Scalars['String'];
  leadActivities: Array<LeadActivityEntity>;
  leadActivitiesTeam: Array<LeadActivityEntity>;
  leads: Array<LeadEntity>;
  memberCount?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  sales: Array<SaleEntity>;
  saleTeamMembers?: Maybe<Array<SaleTeamUserEntity>>;
  saleTeamUnitAllocation?: Maybe<Array<SaleTeamAllocationEntity>>;
  saleTeamUsers?: Maybe<Array<SaleTeamUserEntity>>;
  waitlistTeam?: Maybe<Array<BookingWaitlistEntity>>;
};

export type SaleTeamMemberLeadAssignmentOutput = {
  __typename?: 'SaleTeamMemberLeadAssignmentOutput';
  leadCount?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  teamId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type SaleTeamUserEntity = {
  __typename?: 'saleTeamUserEntity';
  acceptLead: Scalars['Boolean'];
  accountId: Scalars['String'];
  id: Scalars['String'];
  saleTeam: SaleTeamEntity;
  teamId: Scalars['String'];
  user?: Maybe<UserEntity>;
  userId: Scalars['String'];
};

export type SalutationEntity = {
  __typename?: 'salutationEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  contacts: Array<ContactEntity>;
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  saleContact: SaleContactEntity;
  seqNo?: Maybe<Scalars['Float']>;
};

export type ScheduleEntity = {
  __typename?: 'scheduleEntity';
  batchId?: Maybe<Scalars['String']>;
  billedDate?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  docAmount: Scalars['String'];
  id: Scalars['String'];
  ledgers: Array<LedgerEntity>;
  project: ProjectEntity;
  sale: SaleEntity;
  scheduleDate: Scalars['DateTime'];
};

export type SCityEntity = {
  __typename?: 'sCityEntity';
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  sPostcode?: Maybe<Array<SPostcodeEntity>>;
  state: SStateEntity;
};

export type SCountryEntity = {
  __typename?: 'sCountryEntity';
  code: Scalars['String'];
  commonStatus: Scalars['String'];
  einvCode: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  sStates: Array<SStateEntity>;
};

export type SGatewayUpdates_A = AuditEntity & {
  __typename?: 'SGatewayUpdates_A';
  commonStatus: CommonStatus;
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  hasSqlUpdate: Scalars['Boolean'];
  ID: Scalars['String'];
  IntegrationType?: Maybe<Scalars['String']>;
  md5?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  releaseNote?: Maybe<Scalars['String']>;
  sql?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  verifySql?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type ShareInput = {
  sharedBy: Scalars['String'];
  sharedDateTime: Scalars['String'];
  sharedMethod?: InputMaybe<Scalars['String']>;
};

/** JsonType */
export type ShareObject = {
  __typename?: 'shareObject';
  sharedBy: Scalars['String'];
  sharedDateTime: Scalars['String'];
  sharedMethod?: Maybe<Scalars['String']>;
};

export type SiteProgressEntity = {
  __typename?: 'siteProgressEntity';
  accountId: Scalars['String'];
  attachment: Array<AttachmentEntity>;
  commonStatus: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  projectId: Scalars['String'];
  publishDate: Scalars['DateTime'];
  title: Scalars['String'];
};

export type SmtpInfoInput = {
  createdBy?: InputMaybe<Scalars['String']>;
  createdTs?: InputMaybe<Scalars['DateTime']>;
  enableSSL?: InputMaybe<Scalars['Boolean']>;
  hostName: Scalars['String'];
  lastUpdateBy?: InputMaybe<Scalars['String']>;
  lastUpdatedDate?: InputMaybe<Scalars['DateTime']>;
  maxRetry: Scalars['Float'];
  modBy?: InputMaybe<Scalars['String']>;
  modTs?: InputMaybe<Scalars['DateTime']>;
  password: Scalars['String'];
  portNumber: Scalars['Float'];
  userName: Scalars['String'];
};

export type SmtpInfoOutput = {
  __typename?: 'smtpInfoOutput';
  createdBy?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  enableSSL?: Maybe<Scalars['Boolean']>;
  hostName: Scalars['String'];
  lastUpdateBy?: Maybe<Scalars['String']>;
  lastUpdatedDate?: Maybe<Scalars['DateTime']>;
  maxRetry: Scalars['Float'];
  modBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['DateTime']>;
  password: Scalars['String'];
  portNumber: Scalars['Float'];
  userName: Scalars['String'];
};

export type SNationalityEntity = {
  __typename?: 'sNationalityEntity';
  commonStatus: Scalars['String'];
  contacts: Array<ContactEntity>;
  id: Scalars['String'];
  leadContacts: Array<LeadContactEntity>;
  name: Scalars['String'];
};

export type SnpSigningReminderInput = {
  afterDay?: InputMaybe<Scalars['Float']>;
  agencyUserReceive?: InputMaybe<Scalars['Boolean']>;
  beforeDay?: InputMaybe<Scalars['Float']>;
  purchaserReceive?: InputMaybe<Scalars['Boolean']>;
  salesUserReceive?: InputMaybe<Scalars['Boolean']>;
};

export type SnpSigningReminderOutput = {
  __typename?: 'SNPSigningReminderOutput';
  afterDay?: Maybe<Scalars['Float']>;
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  beforeDay?: Maybe<Scalars['Float']>;
  purchaserReceive?: Maybe<Scalars['Boolean']>;
  salesUserReceive?: Maybe<Scalars['Boolean']>;
};

export type SolicitorAlertAgingInput = {
  isActive: Scalars['Boolean'];
  maxMajorSolicitorAlertAging: Scalars['Float'];
  maxMinorSolicitorAlertAging: Scalars['Float'];
};

export type SolicitorAlertAgingOutput = {
  __typename?: 'SolicitorAlertAgingOutput';
  isActive: Scalars['Boolean'];
  maxMajorSolicitorAlertAging: Scalars['Float'];
  maxMinorSolicitorAlertAging: Scalars['Float'];
};

export type SolicitorAppointedContactEntity = AuditEntity & {
  __typename?: 'solicitorAppointedContactEntity';
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  phoneNo?: Maybe<Scalars['String']>;
  solicitor: SolicitorAppointedEntity;
  solicitorAppointedId: Scalars['String'];
};

export type SolicitorAppointedCount = {
  __typename?: 'solicitorAppointedCount';
  lastAppointedDate?: Maybe<Scalars['DateTime']>;
  totalAppointed?: Maybe<Scalars['Float']>;
};

export type SolicitorAppointedEntity = AuditEntity & {
  __typename?: 'solicitorAppointedEntity';
  accountId: Scalars['String'];
  address?: Maybe<AddressJson>;
  assignedProjectCount: Scalars['Float'];
  commonStatus: Scalars['String'];
  contact: Array<SolicitorAppointedContactEntity>;
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhoneNo?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  default?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invitationLink: Scalars['String'];
  isIntegrated: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  officeTel?: Maybe<Scalars['String']>;
  projectCount?: Maybe<Scalars['Float']>;
  saleBuyerSolicitor: Array<SaleEntity>;
  saleDeveloperSolicitor: Array<SaleEntity>;
  saleLoan: Array<SaleLoanEntity>;
  saleStakeholder: Array<SaleEntity>;
  solicitorCode?: Maybe<Scalars['String']>;
  solicitorId?: Maybe<Scalars['String']>;
  solicitorUnitCount?: Maybe<SolicitorUnitCount>;
  subsaleBuyerSolicitor: Array<SubsaleEntity>;
  unitCount?: Maybe<Scalars['Float']>;
  unitExt: Array<UnitExtensionEntity>;
  unitExtLawyer: Array<UnitExtensionEntity>;
};


export type SolicitorAppointedEntityDefaultArgs = {
  projectId: Scalars['String'];
};


export type SolicitorAppointedEntitySolicitorUnitCountArgs = {
  project_id: Scalars['String'];
};

export type SolicitorCaseAssignmentInput = {
  agencyUserReceive?: InputMaybe<Scalars['Boolean']>;
  salesUserReceive?: InputMaybe<Scalars['Boolean']>;
  solicitorUserReceive?: InputMaybe<Scalars['Boolean']>;
};

export type SolicitorCaseAssignmentOutput = {
  __typename?: 'solicitorCaseAssignmentOutput';
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  salesUserReceive?: Maybe<Scalars['Boolean']>;
  solicitorUserReceive?: Maybe<Scalars['Boolean']>;
};

export type SolicitorContactEntity = AuditEntity & {
  __typename?: 'solicitorContactEntity';
  commonStatus?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isSuspendible: Scalars['Boolean'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  phoneNo?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  user: SolicitorUserEntity;
  userId: Scalars['String'];
};

export type SolicitorEntity = AuditEntity & {
  __typename?: 'solicitorEntity';
  address?: Maybe<AddressJson>;
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  officeTel?: Maybe<Scalars['String']>;
};

export type SolicitorLayoutList = {
  __typename?: 'solicitorLayoutList';
  allotted: Scalars['Float'];
  desc: Scalars['String'];
  layout_id: Scalars['String'];
  project_id: Scalars['String'];
  sold: Scalars['Float'];
  solicitor_project_id: Scalars['String'];
  total_selling: Scalars['Float'];
};

export type SolicitorLeadNotificationInput = {
  leadAssignment?: InputMaybe<Scalars['Boolean']>;
};

export type SolicitorLeadNotificationOutput = {
  __typename?: 'SolicitorLeadNotificationOutput';
  leadAssignment?: Maybe<Scalars['Boolean']>;
};

export type SolicitorNotificationEntity = AuditEntity & {
  __typename?: 'solicitorNotificationEntity';
  accountId: Scalars['String'];
  body: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  permissionId: Scalars['Float'];
  projectId: Scalars['String'];
  solicitorNotificationRecipient: Array<SolicitorNotificationRecipientEntity>;
  subject: Scalars['String'];
};

export type SolicitorNotificationPolicyEntity = {
  __typename?: 'solicitorNotificationPolicyEntity';
  accountId: Scalars['String'];
  id: Scalars['String'];
  notificationDuration: Scalars['Float'];
  permissionId: Scalars['Float'];
  userId: Scalars['String'];
};

export type SolicitorNotificationRecipientEntity = AuditEntity & {
  __typename?: 'solicitorNotificationRecipientEntity';
  accountId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  isRead: Scalars['Boolean'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  notificationId: Scalars['String'];
  projectId: Scalars['String'];
  recipientId: Scalars['String'];
  solicitorNotification: SolicitorNotificationEntity;
};

export type SolicitorProgressLogEntity = AuditEntity & {
  __typename?: 'solicitorProgressLogEntity';
  accountId: Scalars['String'];
  actionBy: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  progressDate: Scalars['DateTime'];
  progressDesc?: Maybe<Scalars['String']>;
  progressType: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  sale: SaleEntity;
  saleId: Scalars['String'];
  solicitorId?: Maybe<Scalars['String']>;
  spaStatus?: Maybe<Scalars['String']>;
};

export type SolicitorProjectEntity = AuditEntity & {
  __typename?: 'solicitorProjectEntity';
  accountId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  project?: Maybe<ProjectEntity>;
  projectId: Scalars['String'];
  solicitorId: Scalars['String'];
};

export type SolicitorStatusCount = {
  __typename?: 'solicitorStatusCount';
  assignedCount?: Maybe<Scalars['Float']>;
  bookedCount?: Maybe<Scalars['Float']>;
  reservationCount?: Maybe<Scalars['Float']>;
  snpSignedCount?: Maybe<Scalars['Float']>;
  toBeAssignedCount?: Maybe<Scalars['Float']>;
  totalCount?: Maybe<Scalars['Float']>;
};

export type SolicitorUnitAllocationEntity = AuditEntity & {
  __typename?: 'solicitorUnitAllocationEntity';
  accountId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  projectId: Scalars['String'];
  solicitorId: Scalars['String'];
  unit: UnitEntity;
  unitId: Scalars['String'];
};

export type SolicitorUnitCount = {
  __typename?: 'solicitorUnitCount';
  allocated?: Maybe<Scalars['Float']>;
  sold?: Maybe<Scalars['Float']>;
};

export type SolicitorUserEntity = AuditEntity & {
  __typename?: 'solicitorUserEntity';
  accessToken?: Maybe<Scalars['String']>;
  activated: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  forgotPasswordLink?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  loginId: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  passwordHash?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  solicitorId: Scalars['String'];
};

export type SpaReminderEntity = AuditEntity & {
  __typename?: 'spaReminderEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  generationBatch?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  projectId: Scalars['String'];
  reminderDate: Scalars['DateTime'];
  reminderSeq: Scalars['Float'];
  saleId: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  share?: Maybe<Array<ShareObject>>;
};

export type SPostcodeEntity = {
  __typename?: 'sPostcodeEntity';
  citys: SCityEntity;
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  postcode: Scalars['String'];
};

export type SStateEntity = {
  __typename?: 'sStateEntity';
  commonStatus: Scalars['String'];
  country: SCountryEntity;
  einvCode: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  sCities?: Maybe<Array<SCityEntity>>;
};

export type StakeholderProcessEntity = {
  __typename?: 'stakeholderProcessEntity';
  accountId: Scalars['String'];
  amount: Scalars['Float'];
  collectionBatch?: Maybe<Scalars['String']>;
  collectionDate?: Maybe<Scalars['DateTime']>;
  collectionReferenceNo?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  contraId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  duration: Scalars['Float'];
  durationFlag?: Maybe<Scalars['String']>;
  financialBillingId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isReversal: Scalars['Boolean'];
  progressBilling?: Maybe<ProgressBillingEntity>;
  progressBillingId: Scalars['String'];
  projectId: Scalars['String'];
  releaseDate: Scalars['DateTime'];
  saleId: Scalars['String'];
  solicitorId: Scalars['String'];
  status: Scalars['String'];
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
};

export type StakeholderProcessReleaseDetailEntity = {
  __typename?: 'stakeholderProcessReleaseDetailEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  releaseAmount: Scalars['Float'];
  stakeholderProcessId: Scalars['String'];
  stakeholderProcessReleaseId: Scalars['String'];
};

export type StakeholderProcessReleaseEntity = {
  __typename?: 'stakeholderProcessReleaseEntity';
  accountId: Scalars['String'];
  bankAccountId: Scalars['String'];
  commonStatus: Scalars['String'];
  gl_generated_reversed: Scalars['String'];
  glGenerated: Scalars['String'];
  glGenerationBatch?: Maybe<Scalars['String']>;
  glGenerationReversedBatch?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isReversal: Scalars['Boolean'];
  projectId: Scalars['String'];
  releaseDate: Scalars['DateTime'];
  releaseMethod: Scalars['String'];
  releaseReferenceNo?: Maybe<Scalars['String']>;
  releaseRemark?: Maybe<Scalars['String']>;
  solicitorId: Scalars['String'];
  status: Scalars['String'];
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
};

export type StandardAuditEntity = {
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
};

export type StandardCoreBaseEntity = AuditEntity & {
  __typename?: 'StandardCoreBaseEntity';
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  ID: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
};

export type StandardLetterDataSourceEntity = {
  __typename?: 'standardLetterDataSourceEntity';
  accountId: Scalars['String'];
  dataSource: Array<DataSource>;
  id: Scalars['String'];
  name: Scalars['String'];
  queryFilterString: Array<QueryFilterString>;
  queryParameter: Array<QueryParameter>;
  reportParameter: Array<ReportParameter>;
  standardLetterId: Scalars['String'];
  version: Scalars['Float'];
};

export type StandardLetterEntity = {
  __typename?: 'standardLetterEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  isAllowHtmlEditor: Scalars['Boolean'];
  isAllowReportDesignEditor: Scalars['Boolean'];
  letterCategory: Scalars['String'];
  letterTemplate: Array<LetterTemplateEntity>;
  letterType: Scalars['String'];
  name: Scalars['String'];
  projectTemplate: Array<ProjectLetterTemplateEntity>;
  standardLetterParameter: Array<StandardLetterParameterEntity>;
};

export type StandardLetterParameterEntity = {
  __typename?: 'standardLetterParameterEntity';
  accountId: Scalars['String'];
  columnName: Scalars['String'];
  commonStatus: Scalars['String'];
  displayName: Scalars['String'];
  id: Scalars['String'];
  standardLetter: StandardLetterEntity;
  standardLetterId: Scalars['String'];
};

export type StateMasterDataOutput = {
  __typename?: 'StateMasterDataOutput';
  commonStatus: Scalars['String'];
  countryID: Scalars['String'];
  ID: Scalars['String'];
  name: Scalars['String'];
};

export type StaticData = {
  __typename?: 'StaticData';
  Desc: Scalars['String'];
  Value: Scalars['String'];
};

export type StaticDataInput = {
  Desc: Scalars['String'];
  Value: Scalars['String'];
};

export type SubsaleEntity = {
  __typename?: 'subsaleEntity';
  accountId: Scalars['String'];
  administrationCharges: Scalars['Float'];
  buyerIdentityNo: Scalars['String'];
  buyerName: Scalars['String'];
  buyerSolicitor?: Maybe<SolicitorAppointedEntity>;
  buyerSolicitorId: Scalars['String'];
  buyerSolicitorReferenceNo: Scalars['String'];
  commonStatus: Scalars['String'];
  completedDate?: Maybe<Scalars['DateTime']>;
  contactId: Scalars['String'];
  dmcDate: Scalars['DateTime'];
  doaDate: Scalars['DateTime'];
  id: Scalars['String'];
  isCompany: Scalars['Boolean'];
  printingCharges: Scalars['Float'];
  projectId: Scalars['String'];
  rrDate: Scalars['DateTime'];
  sellingPrice: Scalars['Float'];
  serviceChargeDeposit: Scalars['Float'];
  spaDate: Scalars['DateTime'];
  status: Scalars['String'];
  subsaleCategory: Scalars['String'];
  subsaleJointBuyers?: Maybe<Array<SubsaleJointBuyerEntity>>;
  subsaleLoans?: Maybe<Array<SubsaleLoanEntity>>;
  unitId: Scalars['String'];
  units?: Maybe<UnitEntity>;
  vendorSolicitorId: Scalars['String'];
  vendorSolicitorReferenceNo: Scalars['String'];
};

export type SubsaleJointBuyerEntity = {
  __typename?: 'subsaleJointBuyerEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  contactId: Scalars['String'];
  id: Scalars['String'];
  ranking: Scalars['Float'];
  subsaleId: Scalars['String'];
  subsales: SubsaleEntity;
};

export type SubsaleLoanEntity = {
  __typename?: 'subsaleLoanEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  DOADate: Scalars['DateTime'];
  DOASolicitorId: Scalars['String'];
  DOASolicitorReferenceNo: Scalars['String'];
  financierId: Scalars['String'];
  financierReferenceNo: Scalars['String'];
  id: Scalars['String'];
  loanSeqNo: Scalars['Float'];
  LoanSolicitorId: Scalars['String'];
  loanSolicitorReferenceNo: Scalars['String'];
  RRDate: Scalars['DateTime'];
  RRSolicitorId: Scalars['String'];
  RRSolicitorReferenceNo: Scalars['String'];
  subsaleId: Scalars['String'];
  subsales: SubsaleEntity;
};

export type SUserEntity = AuditEntity & {
  __typename?: 'sUserEntity';
  accountID: Scalars['String'];
  address?: Maybe<Address>;
  avatar?: Maybe<Scalars['String']>;
  blockCount: Scalars['Float'];
  blockDuration: Scalars['Float'];
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  contactNo: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  department?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  deviceToken?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  forgotPasswordLink?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  isActivated: Scalars['Boolean'];
  isSuspendible: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  languageSet?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  lastPwdChangedDate?: Maybe<Scalars['DateTime']>;
  loginAttempt: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  softwareCode: Scalars['String'];
  superUser: Scalars['Boolean'];
  userFeaturePermission: UserFeaturePermissionEntity;
  userName?: Maybe<Scalars['String']>;
};

export type TaxLedgerEntity = {
  __typename?: 'taxLedgerEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  progressBilling?: Maybe<ProgressBillingEntity>;
  projectId: Scalars['String'];
  refId: Scalars['String'];
  refTable: Scalars['String'];
  taxAmount: Scalars['Float'];
  taxId: Scalars['String'];
  taxRate: Scalars['Float'];
  taxSchemeDetailId?: Maybe<Scalars['String']>;
  taxSchemeId: Scalars['String'];
};

export type TeamMember = {
  person_in_charge: Array<Scalars['String']>;
};

/** JsonType */
export type TeamMemberObject = {
  __typename?: 'teamMemberObject';
  person_in_charge: Array<Scalars['String']>;
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  dataURL?: Maybe<Scalars['String']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  otpURL?: Maybe<Scalars['String']>;
  totpSecret?: Maybe<Scalars['String']>;
};

export type TownshipEntity = {
  __typename?: 'townshipEntity';
  accountId: Scalars['String'];
  code: Scalars['String'];
  commonStatus: Scalars['String'];
  company: CompanyEntity;
  companyId: Scalars['String'];
  id: Scalars['String'];
  location: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectExt?: Maybe<Array<ProjectExtEntity>>;
  seqNo: Scalars['Float'];
};

export type TryToFixAConstantValueHere = {
  __typename?: 'TryToFixAConstantValueHere';
  amount: Scalars['Float'];
};

export type UnitEntity = {
  __typename?: 'unitEntity';
  architectCertUnits?: Maybe<Array<ArchitectCertUnitEntity>>;
  block?: Maybe<Scalars['String']>;
  bookingWaitlists?: Maybe<Array<BookingWaitlistEntity>>;
  builtUp: Scalars['Float'];
  carParks?: Maybe<Array<UnitEntity>>;
  cccDate?: Maybe<Scalars['DateTime']>;
  cell: Scalars['Float'];
  cellLabel?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  events: Array<EventsEntity>;
  extraLandArea?: Maybe<Scalars['Float']>;
  facingDirection?: Maybe<DirectionEntity>;
  facingDirectionId?: Maybe<Scalars['String']>;
  facingView?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Float']>;
  floorLabel?: Maybe<Scalars['String']>;
  floorLayoutUrl?: Maybe<Scalars['String']>;
  getIntegratedProjectCarParkNo?: Maybe<Scalars['String']>;
  hsdNo?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isBumi: Scalars['Boolean'];
  isCccReady: Scalars['Boolean'];
  isSubProduct: Scalars['Boolean'];
  landArea?: Maybe<Scalars['String']>;
  leads: Array<LeadEntity>;
  leadSelection: Array<LeadEntity>;
  motTracking?: Maybe<Array<MotTrackingEntity>>;
  parentId?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceAdjustmentUnit?: Maybe<PriceAdjustmentUnitEntity>;
  project: ProjectEntity;
  projectDetailsUnitLayout?: Maybe<UnitLayoutEntity>;
  projectId: Scalars['String'];
  projectSpec: Array<ProjectSpecEntity>;
  projectTitleUnit?: Maybe<ProjectTitleUnitEntity>;
  propertyType: PropertyTypeEntity;
  ptdNo?: Maybe<Scalars['String']>;
  sales: Array<SaleEntity>;
  salesCharts?: Maybe<SalesChartEntity>;
  saleSubProduct?: Maybe<SaleSubproductEntity>;
  subsaleUnit?: Maybe<SubsaleEntity>;
  timeLock?: Maybe<Scalars['DateTime']>;
  unitExt?: Maybe<UnitExtensionEntity>;
  unitIntegration?: Maybe<UnitIntegrationEntity>;
  unitLayout?: Maybe<UnitLayoutEntity>;
  unitLayoutDes?: Maybe<Scalars['String']>;
  unitLayoutId?: Maybe<Scalars['String']>;
  unitLayoutUrl?: Maybe<Scalars['String']>;
  unitLayoutVitualLink?: Maybe<Array<Scalars['String']>>;
  unitLockBy?: Maybe<Scalars['String']>;
  unitNo: Scalars['String'];
  units?: Maybe<UnitEntity>;
  unitStatus: Scalars['String'];
  unitType?: Maybe<UnitTypeEntity>;
  unitTypeId?: Maybe<Scalars['String']>;
  waitingListCount?: Maybe<Scalars['Float']>;
};

export type UnitExcelOutput = {
  __typename?: 'UnitExcelOutput';
  base64: Scalars['String'];
  isSubProduct: Scalars['Boolean'];
  projectname: Scalars['String'];
};

export type UnitExcelReturnOutput = {
  __typename?: 'UnitExcelReturnOutput';
  file?: Maybe<Scalars['String']>;
  file_name: Scalars['String'];
  isSubProduct?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UnitExtensionEntity = {
  __typename?: 'unitExtensionEntity';
  accountId: Scalars['String'];
  actualBuiltUpArea?: Maybe<Scalars['Float']>;
  actualLandArea?: Maybe<Scalars['Float']>;
  builtUpAreaRate?: Maybe<Scalars['Float']>;
  bumiAllocation?: Maybe<Scalars['Boolean']>;
  bumiReleaseDate?: Maybe<Scalars['DateTime']>;
  bumiReleaseReferenceNo?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  district?: Maybe<Scalars['String']>;
  dlp1Date?: Maybe<Scalars['DateTime']>;
  dlp2Date?: Maybe<Scalars['DateTime']>;
  dlpExpiryDate?: Maybe<Scalars['DateTime']>;
  extraLandAreaRate?: Maybe<Scalars['Float']>;
  hsdHsmNo?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  landAreaRate?: Maybe<Scalars['Float']>;
  lawyer?: Maybe<SolicitorAppointedEntity>;
  lawyerId?: Maybe<Scalars['String']>;
  leasePejakanNegeri?: Maybe<Scalars['String']>;
  lotPtNo?: Maybe<Scalars['String']>;
  masterGrantNo?: Maybe<Scalars['String']>;
  noOfKey?: Maybe<Scalars['Float']>;
  noOfStorey?: Maybe<Scalars['Float']>;
  paymentPlan?: Maybe<PaymentPlanEntity>;
  paymentPlanId?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  scheduleLaunchDate?: Maybe<Scalars['DateTime']>;
  shareValue?: Maybe<Scalars['String']>;
  stakeholder?: Maybe<SolicitorAppointedEntity>;
  stakeholderId?: Maybe<Scalars['String']>;
  stampDutyWaiver?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  subdivisionMukim?: Maybe<Scalars['String']>;
  unitAddress?: Maybe<AddressJson>;
  unitId: Scalars['String'];
  units: UnitEntity;
  usableArea?: Maybe<Scalars['Float']>;
  vpBillingDate?: Maybe<Scalars['DateTime']>;
  vpLetterDate?: Maybe<Scalars['DateTime']>;
};

export type UnitImportCountOutput = {
  __typename?: 'UnitImportCountOutput';
  agencyUnitCount: Scalars['Float'];
  architectCertCount: Scalars['Float'];
  assignCarparkCount: Scalars['Float'];
  bookingCount: Scalars['Float'];
  bridgingFinancierCount: Scalars['Float'];
  elaunchCount: Scalars['Float'];
  elaunchFavouriteCount: Scalars['Float'];
  leadCount: Scalars['Float'];
  priceAdjustmentCount: Scalars['Float'];
  projectTitleCount: Scalars['Float'];
  saleChartCount: Scalars['Float'];
  salesCount: Scalars['Float'];
  saleSubproductCount: Scalars['Float'];
  saleTeamAllocationCount: Scalars['Float'];
  unitIds: Array<Scalars['String']>;
  unitIntegrationCount: Scalars['Float'];
};

export type UnitImportOptionOutput = {
  __typename?: 'UnitImportOptionOutput';
  append: Scalars['Boolean'];
  displayImportMethods: Scalars['Boolean'];
  emptyProject: Scalars['Boolean'];
  integrated: Scalars['Boolean'];
  modify: Scalars['Boolean'];
  overwrite: Scalars['Boolean'];
  unitIds: Array<Scalars['String']>;
};

export type UnitIntegrationEntity = {
  __typename?: 'unitIntegrationEntity';
  accountId: Scalars['String'];
  accountSpec?: Maybe<AccountSpecEntity>;
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  ifcaBlockId: Scalars['Float'];
  ifcaCompanyId: Scalars['Float'];
  ifcaPhaseId: Scalars['Float'];
  ifcaProjectId: Scalars['Float'];
  ifcaUnitId: Scalars['Float'];
  projectId: Scalars['String'];
  projectIntegration?: Maybe<ProjectIntegrationEntity>;
  sale?: Maybe<SaleEntity>;
  unit: UnitEntity;
  unitId: Scalars['String'];
  waitingList?: Maybe<BookingWaitlistEntity>;
};

export type UnitLayoutEntity = {
  __typename?: 'unitLayoutEntity';
  desc: Scalars['String'];
  id: Scalars['String'];
  pictureUnitlayouts: Array<PictureUnitlayoutEntity>;
  project: ProjectEntity;
  projectId: Scalars['String'];
  units: Array<UnitEntity>;
  unitTourVirtualLinks: Array<UnitTourVirtualLinkEntity>;
};

export type UnitMortgageOutput = {
  __typename?: 'UnitMortgageOutput';
  financierList?: Maybe<Array<FinancierUnitMortgage>>;
  unitId: Scalars['String'];
  unitNo: Scalars['String'];
  unitPrice: Scalars['Float'];
};

export type UnitTourVirtualLinkEntity = {
  __typename?: 'unitTourVirtualLinkEntity';
  block: Scalars['String'];
  id: Scalars['String'];
  projectId: Scalars['String'];
  seqNo: Scalars['Float'];
  unitLayout: UnitLayoutEntity;
  unitLayoutId?: Maybe<Scalars['String']>;
  unitType: UnitTypeEntity;
  unitTypeId?: Maybe<Scalars['String']>;
  virtualModelLink: Scalars['String'];
};

export type UnitTourVirtualOutput = {
  __typename?: 'unitTourVirtualOutput';
  block: Scalars['String'];
  tourId: Scalars['String'];
  unitLayoutId?: Maybe<Scalars['String']>;
  unitLayoutName?: Maybe<Scalars['String']>;
  unitTypeId?: Maybe<Scalars['String']>;
  unitTypeName?: Maybe<Scalars['String']>;
  virtualModelLink: Scalars['String'];
};

export type UnitTransferEntity = AuditEntity & {
  __typename?: 'unitTransferEntity';
  accountId: Scalars['String'];
  administrationCharges: Scalars['Float'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  discount?: Maybe<DiscountObject>;
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  newListPrice: Scalars['Float'];
  newSellingPrice: Scalars['Float'];
  newUnitId: Scalars['String'];
  oldSaleDetail?: Maybe<OldSaleDetail>;
  oldSellingPrice?: Maybe<Scalars['Float']>;
  oldUnitId: Scalars['String'];
  paymentMethod: Scalars['String'];
  paymentPlanId: Scalars['String'];
  projectId: Scalars['String'];
  reason: Scalars['String'];
  remark: Scalars['String'];
  saleId: Scalars['String'];
  status: Scalars['String'];
  transferDate: Scalars['DateTime'];
};

export type UnitTypeEntity = {
  __typename?: 'unitTypeEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  seq_no?: Maybe<Scalars['Float']>;
  units: Array<UnitEntity>;
  unitTourVirtualLinks: Array<UnitTourVirtualLinkEntity>;
};

export type UnitTypeOutput = {
  __typename?: 'unitTypeOutput';
  builtUp?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UnitViewLogEntity = {
  __typename?: 'unitViewLogEntity';
  accountId: Scalars['String'];
  block: Scalars['String'];
  id: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  leftTime?: Maybe<Scalars['DateTime']>;
  projectId: Scalars['String'];
  socketId?: Maybe<Scalars['String']>;
  softwareCode: Scalars['String'];
  unitId: Scalars['String'];
  userId: Scalars['String'];
  visitTime: Scalars['DateTime'];
};

export type UpdateAuditInput = {
  modBy?: InputMaybe<Scalars['String']>;
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  accountID: Scalars['String'];
  address?: Maybe<Address>;
  avatar?: Maybe<Scalars['String']>;
  blockCount: Scalars['Float'];
  blockDuration: Scalars['Float'];
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  contactNo: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  department?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  ID: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  isActivated: Scalars['Boolean'];
  isSuspendible: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  loginAttempt: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  softwareCode: Scalars['String'];
  superUser: Scalars['Boolean'];
  userFeaturePermission: UserFeaturePermissionEntity;
  userName?: Maybe<Scalars['String']>;
};

export type UserFeaturePermissionEntity = AuditEntity & {
  __typename?: 'userFeaturePermissionEntity';
  accountId: Scalars['String'];
  allowGlobalBI: Scalars['Boolean'];
  allowOpenlead: Scalars['Boolean'];
  allowOpenLeadRoundRobin: Scalars['Boolean'];
  allowPropertyAgency: Scalars['Boolean'];
  allowReporting: Scalars['Boolean'];
  allowRoundRobin: Scalars['Boolean'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  notificationHistory: Scalars['Float'];
  userId: Scalars['String'];
};

export type UserInput = {
  address?: InputMaybe<AddressInput>;
  avatar?: InputMaybe<Scalars['String']>;
  commonStatus?: InputMaybe<CommonStatus>;
  contactNo: Scalars['String'];
  department?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  hashed?: InputMaybe<Scalars['String']>;
  ID?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  superUser?: InputMaybe<Scalars['Boolean']>;
  userName?: InputMaybe<Scalars['String']>;
};

export type UserNotificationSettingEntity = {
  __typename?: 'userNotificationSettingEntity';
  accountId: Scalars['String'];
  id: Scalars['String'];
  permissionId: Scalars['Float'];
  userId: Scalars['String'];
};

export type UserOutput = {
  __typename?: 'UserOutput';
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNo?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  accountID: Scalars['String'];
  address?: Maybe<Address>;
  avatar?: Maybe<Scalars['String']>;
  blockCount?: Maybe<Scalars['Float']>;
  blockDuration?: Maybe<Scalars['Float']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  contactNo: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  department?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  errors?: Maybe<Array<FieldError>>;
  ID: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  userName: Scalars['String'];
};

export type WithdrawnLoanStatusOutput = {
  __typename?: 'WithdrawnLoanStatusOutput';
  count: Scalars['Float'];
  customerCancelledCount: Scalars['Float'];
  customerRejectedCount: Scalars['Float'];
  developerCancelledCount: Scalars['Float'];
  loanRejectedCount: Scalars['Float'];
};

export type WorkdeskNotificationEntity = AuditEntity & {
  __typename?: 'workdeskNotificationEntity';
  accountId: Scalars['String'];
  body: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  permissionId: Scalars['Float'];
  projectId: Scalars['String'];
  subject: Scalars['String'];
  wdNotificationRecipient: Array<WorkdeskNotificationRecipientEntity>;
};

export type WorkdeskNotificationRecipientEntity = AuditEntity & {
  __typename?: 'workdeskNotificationRecipientEntity';
  accountId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  isRead: Scalars['Boolean'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  notificationId: Scalars['String'];
  projectId: Scalars['String'];
  recipientId: Scalars['String'];
  workdeskNotification: WorkdeskNotificationEntity;
};

export type WorkFlowApprovedStepEntity = AuditEntity & {
  __typename?: 'workFlowApprovedStepEntity';
  accountId: Scalars['String'];
  approvalDate: Scalars['DateTime'];
  approvalRoleId: Scalars['String'];
  approvalStatus: Scalars['String'];
  approverId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  notifiedDate: Scalars['DateTime'];
  statusRemark?: Maybe<Scalars['String']>;
  workFlowStepId: Scalars['String'];
};

export type WorkFlowEntity = AuditEntity & {
  __typename?: 'workFlowEntity';
  accountId: Scalars['String'];
  approvalPolicyAssignmentId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  entityId: Scalars['String'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  refId: Scalars['String'];
  refTable: Scalars['String'];
  submitDate: Scalars['DateTime'];
  submitterId: Scalars['String'];
  workFlowStatus: Scalars['String'];
};

export type WorkFlowStepEntity = AuditEntity & {
  __typename?: 'workFlowStepEntity';
  accountId: Scalars['String'];
  approvalRoleId: Scalars['String'];
  approvalStatus: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  notifiedDate: Scalars['DateTime'];
  statusRemark?: Maybe<Scalars['String']>;
  stepNo: Scalars['Float'];
  workFlowId: Scalars['String'];
};

export type WorkProgressEntity = {
  __typename?: 'workProgressEntity';
  accountId: Scalars['String'];
  archCert: Array<ArchitectCertWorkProgressEntity>;
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  isCarPark: Scalars['Boolean'];
  isVp: Scalars['Boolean'];
  name: Scalars['String'];
  paymentPlanDetail: Array<PaymentPlanDetailEntity>;
  salePackage?: Maybe<Array<SalePackageEntity>>;
  saleRebatePackage?: Maybe<Array<SaleRebatePackageEntity>>;
  systemUse: Scalars['Boolean'];
};

export type FinancierNameQueryVariables = Exact<{ [key: string]: never; }>;


export type FinancierNameQuery = { __typename?: 'Query', getEndfinFinancierName: string };

export type ProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileQuery = { __typename?: 'Query', profile: { __typename?: 'endfinContactEntity', name: string, email?: string | null, phoneNo?: string | null, photoUrl?: string | null, user: { __typename?: 'endfinUserEntity', endfinUserRoles: { __typename?: 'endfinUserRoleEntity', role: string } } } };

export type UpdateProfileMutationVariables = Exact<{
  ConInput: LoanEndFinContactInput;
  file?: InputMaybe<Scalars['Upload']>;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: boolean };

export type ChangePasswordMutationVariables = Exact<{
  input: LoanForgotPasswordInput;
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: boolean };

export type RefreshTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenQuery = { __typename?: 'Query', refreshToken?: string | null };

export type LoginQueryVariables = Exact<{
  LoginId: Scalars['String'];
  Password: Scalars['String'];
  FinancierId?: InputMaybe<Scalars['String']>;
}>;


export type LoginQuery = { __typename?: 'Query', login: { __typename?: 'endfinUserEntity', accessToken?: string | null } };

export type LogoutQueryVariables = Exact<{ [key: string]: never; }>;


export type LogoutQuery = { __typename?: 'Query', logout: boolean };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type NewPasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  confirmPassword: Scalars['String'];
}>;


export type NewPasswordMutation = { __typename?: 'Mutation', newPassword: boolean };

export type RegisterTokenQueryVariables = Exact<{
  registerLink: Scalars['String'];
}>;


export type RegisterTokenQuery = { __typename?: 'Query', registerToken?: { __typename?: 'LoanRegisterOutput', financierName: string, invitation: { __typename?: 'Contact', contactId: string, date?: string | null, link: string, contactInfo?: { __typename?: 'financierContactEntity', email: string } | null } } | null };

export type RegisterMutationVariables = Exact<{
  input: LoanRegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: boolean };

export type ProfileNameQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileNameQuery = { __typename?: 'Query', profile: { __typename?: 'endfinContactEntity', name: string } };

export type FinancierStaffQueryVariables = Exact<{ [key: string]: never; }>;


export type FinancierStaffQuery = { __typename?: 'Query', getFinancierStaff: Array<{ __typename?: 'endfinContactEntity', id: string, name: string, email?: string | null, phoneNo?: string | null, isSuspendible: boolean, user: { __typename?: 'endfinUserEntity', id: string, activated: boolean, userRole?: { __typename?: 'endfinUserRoleEntity', role: string, commonStatus: string } | null } }> };

export type StaffDetailsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type StaffDetailsQuery = { __typename?: 'Query', getStaffDetails: { __typename?: 'endfinUserEntity', userRole?: { __typename?: 'endfinUserRoleEntity', commonStatus: string } | null, assignedLoanCount?: { __typename?: 'LoanAssignedCountOutput', total_count: number, active_count: number, withdrawn_count: number } | null, projectLoanCount?: Array<{ __typename?: 'LoanProjectCountOutput', project_name: string, total_count: number, active_count: number, withdrawn_count: number }> | null } };

export type StaffEditDetailsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type StaffEditDetailsQuery = { __typename?: 'Query', getStaffDetails: { __typename?: 'endfinUserEntity', contact?: { __typename?: 'endfinContactEntity', name: string, email?: string | null, phoneNo?: string | null } | null, userRole?: { __typename?: 'endfinUserRoleEntity', role: string } | null } };

export type EditStaffMutationVariables = Exact<{
  input: LoanEditStaffInput;
}>;


export type EditStaffMutation = { __typename?: 'Mutation', editStaff: boolean };

export type DeactivateStaffMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type DeactivateStaffMutation = { __typename?: 'Mutation', deactivateStaff: boolean };

export type ActivateStaffMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type ActivateStaffMutation = { __typename?: 'Mutation', activateStaff: boolean };

export type ResendEmailInvitationMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type ResendEmailInvitationMutation = { __typename?: 'Mutation', resendInvitation: boolean };

export type AddStaffMutationVariables = Exact<{
  input: LoanAddStaffInput;
}>;


export type AddStaffMutation = { __typename?: 'Mutation', addStaff: boolean };

export type ProjectListQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectListQuery = { __typename?: 'Query', getProjectLoan: Array<{ __typename?: 'projectEntity', id: string, name: string, projectAddress?: string | null, city?: string | null, state?: string | null }> };

export type ProjectQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectQuery = { __typename?: 'Query', getProjectLoan: Array<{ __typename?: 'projectEntity', id: string, name: string, totalLoanCount?: number | null, firstGalleryImage?: { __typename?: 'ProjectResourceOuput', url?: string | null, caption?: string | null } | null, activeLoans?: { __typename?: 'ActiveLoanStatusOutput', count: number } | null, withdrawnLoans?: { __typename?: 'WithdrawnLoanStatusOutput', count: number } | null }> };

export type ProjectByIdQueryVariables = Exact<{
  projectid: Scalars['String'];
}>;


export type ProjectByIdQuery = { __typename?: 'Query', getProjectbyId: { __typename?: 'projectEntity', id: string, name: string, currency: string, minPrice?: number | null, maxPrice?: number | null, projectAddress?: string | null, city?: string | null, state?: string | null, tenureDesc: string, completionDate?: any | null, launchDate?: any | null, totalLoanCount?: number | null, activeLoans?: { __typename?: 'ActiveLoanStatusOutput', count: number, openCount: number, unreachableCount: number, docsSubmittedCount: number, loanAppealedCount: number, pendingDocsCount: number, loanIssuedCount: number, loanDocSignedCount: number, loanSignedCount: number, assignedCount: number, unassignedCount: number } | null, withdrawnLoans?: { __typename?: 'WithdrawnLoanStatusOutput', count: number, developerCancelledCount: number, customerCancelledCount: number, customerRejectedCount: number, loanRejectedCount: number } | null } };

export type LoanCountQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type LoanCountQuery = { __typename?: 'Query', getLoanCountByProjectId: { __typename?: 'projectEntity', totalLoanCount?: number | null, activeLoans?: { __typename?: 'ActiveLoanStatusOutput', count: number, openCount: number, unreachableCount: number, docsSubmittedCount: number, loanAppealedCount: number, pendingDocsCount: number, loanIssuedCount: number, loanDocSignedCount: number, loanSignedCount: number, assignedCount: number, unassignedCount: number } | null, withdrawnLoans?: { __typename?: 'WithdrawnLoanStatusOutput', count: number, developerCancelledCount: number, customerCancelledCount: number, customerRejectedCount: number, loanRejectedCount: number } | null, loanStatusList: Array<{ __typename?: 'endfinLoanAppStatusEntity', id: string, statusCode: string, statusName: string, stages: number }> } };

export type LoanListQueryVariables = Exact<{
  projectId: Scalars['String'];
  loanStatusIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  searchedDate?: InputMaybe<Scalars['DateTime']>;
}>;


export type LoanListQuery = { __typename?: 'Query', getLoanListByProjectId: Array<{ __typename?: 'endfinLoanAppEntity', id: string, applyDate: any, applyAmount: number, approvalDate?: any | null, approvalAmount?: number | null, loanAppStatus: { __typename?: 'endfinLoanAppStatusEntity', statusCode: string, statusName: string }, sale: { __typename?: 'saleEntity', id: string, sellingPrice?: number | null, salesStatus: string, unit: { __typename?: 'unitEntity', unitNo: string }, contact: { __typename?: 'contactEntity', fullName: string, icNo?: string | null, phoneNo?: string | null, email?: string | null, salutation?: { __typename?: 'salutationEntity', name: string } | null, race?: { __typename?: 'raceEntity', name: string } | null, maritalStatus?: { __typename?: 'maritalStatusEntity', name: string } | null, nationality?: { __typename?: 'sNationalityEntity', name: string } | null } }, staff?: { __typename?: 'endfinUserEntity', id: string, endfinContacts: { __typename?: 'endfinContactEntity', name: string } } | null }> };

export type LoanListActiveQueryVariables = Exact<{
  projectId: Scalars['String'];
  loanStatusIds?: InputMaybe<Scalars['String']>;
  searchedDate?: InputMaybe<Scalars['DateTime']>;
}>;


export type LoanListActiveQuery = { __typename?: 'Query', getLoanListByProjectIdActive: Array<{ __typename?: 'endfinLoanAppEntity', id: string, applyDate: any, applyAmount: number, approvalDate?: any | null, approvalAmount?: number | null, loanAppStatus: { __typename?: 'endfinLoanAppStatusEntity', statusCode: string, statusName: string }, sale: { __typename?: 'saleEntity', id: string, sellingPrice?: number | null, salesStatus: string, unit: { __typename?: 'unitEntity', unitNo: string }, contact: { __typename?: 'contactEntity', fullName: string, icNo?: string | null, phoneNo?: string | null, email?: string | null, salutation?: { __typename?: 'salutationEntity', name: string } | null, race?: { __typename?: 'raceEntity', name: string } | null, maritalStatus?: { __typename?: 'maritalStatusEntity', name: string } | null, nationality?: { __typename?: 'sNationalityEntity', name: string } | null } }, staff?: { __typename?: 'endfinUserEntity', id: string, endfinContacts: { __typename?: 'endfinContactEntity', name: string } } | null }> };

export type LoanListWithdrawnQueryVariables = Exact<{
  projectId: Scalars['String'];
  loanStatusIds?: InputMaybe<Scalars['String']>;
  searchedDate?: InputMaybe<Scalars['DateTime']>;
}>;


export type LoanListWithdrawnQuery = { __typename?: 'Query', getLoanListByProjectIdWithdrawn: Array<{ __typename?: 'endfinLoanAppEntity', id: string, applyDate: any, applyAmount: number, approvalDate?: any | null, approvalAmount?: number | null, loanAppStatus: { __typename?: 'endfinLoanAppStatusEntity', statusCode: string, statusName: string }, sale: { __typename?: 'saleEntity', id: string, sellingPrice?: number | null, salesStatus: string, unit: { __typename?: 'unitEntity', unitNo: string }, contact: { __typename?: 'contactEntity', fullName: string, icNo?: string | null, phoneNo?: string | null, email?: string | null, salutation?: { __typename?: 'salutationEntity', name: string } | null, race?: { __typename?: 'raceEntity', name: string } | null, maritalStatus?: { __typename?: 'maritalStatusEntity', name: string } | null, nationality?: { __typename?: 'sNationalityEntity', name: string } | null } }, staff?: { __typename?: 'endfinUserEntity', id: string, endfinContacts: { __typename?: 'endfinContactEntity', name: string } } | null }> };

export type LoanListAssignmentQueryVariables = Exact<{
  projectId: Scalars['String'];
  searchedDate?: InputMaybe<Scalars['DateTime']>;
}>;


export type LoanListAssignmentQuery = { __typename?: 'Query', getLoanListByProjectIdLOAssignment: Array<{ __typename?: 'endfinLoanAppEntity', id: string, applyDate: any, applyAmount: number, approvalDate?: any | null, approvalAmount?: number | null, sale: { __typename?: 'saleEntity', id: string, sellingPrice?: number | null, salesStatus: string, unit: { __typename?: 'unitEntity', unitNo: string }, contact: { __typename?: 'contactEntity', fullName: string, icNo?: string | null, phoneNo?: string | null, email?: string | null, salutation?: { __typename?: 'salutationEntity', name: string } | null, race?: { __typename?: 'raceEntity', name: string } | null, maritalStatus?: { __typename?: 'maritalStatusEntity', name: string } | null, nationality?: { __typename?: 'sNationalityEntity', name: string } | null } }, staff?: { __typename?: 'endfinUserEntity', id: string, endfinContacts: { __typename?: 'endfinContactEntity', name: string } } | null }> };

export type LoanDetailQueryVariables = Exact<{
  loanId: Scalars['String'];
}>;


export type LoanDetailQuery = { __typename?: 'Query', getLoanDetailById: { __typename?: 'endfinLoanAppEntity', id: string, applyAmount: number, applyDate: any, approvalAmount?: number | null, approvalDate?: any | null, agingDate?: any | null, loanAppStatus: { __typename?: 'endfinLoanAppStatusEntity', statusCode: string, statusName: string }, staff?: { __typename?: 'endfinUserEntity', id: string, endfinContacts: { __typename?: 'endfinContactEntity', id: string, name: string } } | null, sale: { __typename?: 'saleEntity', id: string, sellingPrice?: number | null, buyerReference?: string | null, buyerSolicitor?: { __typename?: 'solicitorAppointedEntity', id: string } | null, unit: { __typename?: 'unitEntity', unitNo: string }, contact: { __typename?: 'contactEntity', fullName: string, icNo?: string | null, nationality?: { __typename?: 'sNationalityEntity', name: string } | null, salutation?: { __typename?: 'salutationEntity', name: string } | null, race?: { __typename?: 'raceEntity', name: string } | null } }, activityData?: Array<{ __typename?: 'endfinLoanAppActivityEntity', id: string, createdBy?: string | null, createdDate: any, remark?: string | null, activityTypeDesc: string, loanAppStatus: { __typename?: 'endfinLoanAppStatusEntity', statusName: string, statusCode: string } }> | null } };

export type GetAttachmentByLoanIdQueryVariables = Exact<{
  loanId: Scalars['String'];
}>;


export type GetAttachmentByLoanIdQuery = { __typename?: 'Query', getAttachmentByLoanId: Array<{ __typename?: 'attachmentEntity', id: string, resourceUrl: string, mediaType: string, fileName: string, refType: string, allowDelete?: boolean | null, createdTs: any }>, getESignLetter: Array<{ __typename?: 'ESignProjectLetterListingOutput', letterTemplateId?: string | null, name?: string | null, letterType?: string | null }>, getMultiESignLetter: Array<{ __typename?: 'MultipleEsignProjectLetterListingOutput', letterTypeName?: string | null, letter?: Array<{ __typename?: 'ESignProjectLetterListingOutput', letterTemplateId?: string | null, name?: string | null, letterType?: string | null, defaulted?: boolean | null }> | null }> };

export type UpdateLoanStatusMutationVariables = Exact<{
  input: LoanUpdateLoanStatusInput;
  file?: InputMaybe<Scalars['Upload']>;
}>;


export type UpdateLoanStatusMutation = { __typename?: 'Mutation', updateLoanStatus: boolean };

export type LoanStatusListQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type LoanStatusListQuery = { __typename?: 'Query', getLoanCountByProjectId: { __typename?: 'projectEntity', loanStatusList: Array<{ __typename?: 'endfinLoanAppStatusEntity', id: string, statusCode: string, statusName: string, stages: number }> } };

export type GetSolicitorListQueryVariables = Exact<{
  project_id: Scalars['String'];
}>;


export type GetSolicitorListQuery = { __typename?: 'Query', getSolicitorListByProjectId: Array<{ __typename?: 'solicitorAppointedEntity', id: string, name: string }> };

export type GetUpdateLoanInfoQueryVariables = Exact<{
  loanId: Scalars['String'];
}>;


export type GetUpdateLoanInfoQuery = { __typename?: 'Query', getLoanDetailById: { __typename?: 'endfinLoanAppEntity', id: string, approvalAmount?: number | null, tenure?: number | null, loanAppStatus: { __typename?: 'endfinLoanAppStatusEntity', statusCode: string }, sale: { __typename?: 'saleEntity', id: string, buyerReference?: string | null, buyerSolicitor?: { __typename?: 'solicitorAppointedEntity', id: string } | null }, activityData?: Array<{ __typename?: 'endfinLoanAppActivityEntity', remark?: string | null }> | null } };

export type AssignStaffMutationVariables = Exact<{
  input: LoanAssignStaffInput;
}>;


export type AssignStaffMutation = { __typename?: 'Mutation', assignStaff: boolean };

export type DownloadAttachmentQueryVariables = Exact<{
  attachmentId: Scalars['String'];
}>;


export type DownloadAttachmentQuery = { __typename?: 'Query', downloadAttachment: { __typename?: 'LoanDownloadAttachmentOuput', fileName: string, base64: string } };

export type DeleteAttachmentQueryVariables = Exact<{
  attachmentId: Scalars['String'];
}>;


export type DeleteAttachmentQuery = { __typename?: 'Query', deleteAttachment: boolean };

export type UploadAttachmentMutationVariables = Exact<{
  input: LoanUploadAttachmentInput;
}>;


export type UploadAttachmentMutation = { __typename?: 'Mutation', uploadAttachment: boolean };

export type GetWaterMarkTextQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWaterMarkTextQuery = { __typename?: 'Query', GetWaterMarkText: string };

export type UploadNricPassportMutationVariables = Exact<{
  input: LoanUploadNricPassportInput;
}>;


export type UploadNricPassportMutation = { __typename?: 'Mutation', uploadNricPassport: Array<{ __typename?: 'attachmentEntity', id: string, accountId: string, bucketFileName: string, fileName: string, isPrivate: boolean, mediaType: string, refId: string, refTable: string, refType: string, resourceUrl: string, seqNo: number }> };

export type GetNotificationsQueryVariables = Exact<{
  limit: Scalars['Float'];
  page: Scalars['Float'];
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetNotificationsQuery = { __typename?: 'Query', getNotifications?: any | null };

export type MarkAllReadQueryVariables = Exact<{ [key: string]: never; }>;


export type MarkAllReadQuery = { __typename?: 'Query', markAllRead: boolean };

export type MarkAsReadMutationVariables = Exact<{
  notificationIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type MarkAsReadMutation = { __typename?: 'Mutation', markAsRead: boolean };

export type GetELetterPdfQueryVariables = Exact<{
  projectId: Scalars['String'];
  letterTemplateId: Scalars['String'];
  loanId: Scalars['String'];
}>;


export type GetELetterPdfQuery = { __typename?: 'Query', getELetterPDF: { __typename?: 'ELetterOutput', fileName: string, content: string } };

export type NotificationPermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationPermissionQuery = { __typename?: 'Query', getNotificationPermission: any };

export type SaveNotificationPermissionMutationVariables = Exact<{
  input: NotificationInput;
}>;


export type SaveNotificationPermissionMutation = { __typename?: 'Mutation', saveNotificationPermission: boolean };

export type GetNotificationHistoryDayQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationHistoryDayQuery = { __typename?: 'Query', getNotificationHistoryDay?: number | null };

export type GetUserNotificationPermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserNotificationPermissionQuery = { __typename?: 'Query', getUserNotificationPermission?: Array<string> | null };


export const FinancierNameDocument = gql`
    query financierName {
  getEndfinFinancierName
}
    `;

/**
 * __useFinancierNameQuery__
 *
 * To run a query within a React component, call `useFinancierNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancierNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancierNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useFinancierNameQuery(baseOptions?: Apollo.QueryHookOptions<FinancierNameQuery, FinancierNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FinancierNameQuery, FinancierNameQueryVariables>(FinancierNameDocument, options);
      }
export function useFinancierNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FinancierNameQuery, FinancierNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FinancierNameQuery, FinancierNameQueryVariables>(FinancierNameDocument, options);
        }
export type FinancierNameQueryHookResult = ReturnType<typeof useFinancierNameQuery>;
export type FinancierNameLazyQueryHookResult = ReturnType<typeof useFinancierNameLazyQuery>;
export type FinancierNameQueryResult = Apollo.QueryResult<FinancierNameQuery, FinancierNameQueryVariables>;
export const ProfileDocument = gql`
    query profile {
  profile {
    name
    email
    phoneNo
    photoUrl
    user {
      endfinUserRoles {
        role
      }
    }
  }
}
    `;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
      }
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = Apollo.QueryResult<ProfileQuery, ProfileQueryVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($ConInput: LoanEndFinContactInput!, $file: Upload) {
  updateProfile(ConInput: $ConInput, file: $file)
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      ConInput: // value for 'ConInput'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($input: LoanForgotPasswordInput!) {
  changePassword(input: $input)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const RefreshTokenDocument = gql`
    query refreshToken {
  refreshToken
}
    `;

/**
 * __useRefreshTokenQuery__
 *
 * To run a query within a React component, call `useRefreshTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenQuery(baseOptions?: Apollo.QueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
      }
export function useRefreshTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
        }
export type RefreshTokenQueryHookResult = ReturnType<typeof useRefreshTokenQuery>;
export type RefreshTokenLazyQueryHookResult = ReturnType<typeof useRefreshTokenLazyQuery>;
export type RefreshTokenQueryResult = Apollo.QueryResult<RefreshTokenQuery, RefreshTokenQueryVariables>;
export const LoginDocument = gql`
    query login($LoginId: String!, $Password: String!, $FinancierId: String) {
  login(LoginId: $LoginId, Password: $Password, FinancierId: $FinancierId) {
    accessToken
  }
}
    `;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      LoginId: // value for 'LoginId'
 *      Password: // value for 'Password'
 *      FinancierId: // value for 'FinancierId'
 *   },
 * });
 */
export function useLoginQuery(baseOptions: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;
export const LogoutDocument = gql`
    query logout {
  logout
}
    `;

/**
 * __useLogoutQuery__
 *
 * To run a query within a React component, call `useLogoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useLogoutQuery(baseOptions?: Apollo.QueryHookOptions<LogoutQuery, LogoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LogoutQuery, LogoutQueryVariables>(LogoutDocument, options);
      }
export function useLogoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogoutQuery, LogoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LogoutQuery, LogoutQueryVariables>(LogoutDocument, options);
        }
export type LogoutQueryHookResult = ReturnType<typeof useLogoutQuery>;
export type LogoutLazyQueryHookResult = ReturnType<typeof useLogoutLazyQuery>;
export type LogoutQueryResult = Apollo.QueryResult<LogoutQuery, LogoutQueryVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(Email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const NewPasswordDocument = gql`
    mutation newPassword($newPassword: String!, $confirmPassword: String!) {
  newPassword(newPassword: $newPassword, confirmPassword: $confirmPassword)
}
    `;
export type NewPasswordMutationFn = Apollo.MutationFunction<NewPasswordMutation, NewPasswordMutationVariables>;

/**
 * __useNewPasswordMutation__
 *
 * To run a mutation, you first call `useNewPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newPasswordMutation, { data, loading, error }] = useNewPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      confirmPassword: // value for 'confirmPassword'
 *   },
 * });
 */
export function useNewPasswordMutation(baseOptions?: Apollo.MutationHookOptions<NewPasswordMutation, NewPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewPasswordMutation, NewPasswordMutationVariables>(NewPasswordDocument, options);
      }
export type NewPasswordMutationHookResult = ReturnType<typeof useNewPasswordMutation>;
export type NewPasswordMutationResult = Apollo.MutationResult<NewPasswordMutation>;
export type NewPasswordMutationOptions = Apollo.BaseMutationOptions<NewPasswordMutation, NewPasswordMutationVariables>;
export const RegisterTokenDocument = gql`
    query registerToken($registerLink: String!) {
  registerToken(register_link: $registerLink) {
    invitation {
      contactId
      contactInfo {
        email
      }
      date
      link
    }
    financierName
  }
}
    `;

/**
 * __useRegisterTokenQuery__
 *
 * To run a query within a React component, call `useRegisterTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterTokenQuery({
 *   variables: {
 *      registerLink: // value for 'registerLink'
 *   },
 * });
 */
export function useRegisterTokenQuery(baseOptions: Apollo.QueryHookOptions<RegisterTokenQuery, RegisterTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegisterTokenQuery, RegisterTokenQueryVariables>(RegisterTokenDocument, options);
      }
export function useRegisterTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegisterTokenQuery, RegisterTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegisterTokenQuery, RegisterTokenQueryVariables>(RegisterTokenDocument, options);
        }
export type RegisterTokenQueryHookResult = ReturnType<typeof useRegisterTokenQuery>;
export type RegisterTokenLazyQueryHookResult = ReturnType<typeof useRegisterTokenLazyQuery>;
export type RegisterTokenQueryResult = Apollo.QueryResult<RegisterTokenQuery, RegisterTokenQueryVariables>;
export const RegisterDocument = gql`
    mutation register($input: LoanRegisterInput!) {
  register(input: $input)
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const ProfileNameDocument = gql`
    query profileName {
  profile {
    name
  }
}
    `;

/**
 * __useProfileNameQuery__
 *
 * To run a query within a React component, call `useProfileNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileNameQuery(baseOptions?: Apollo.QueryHookOptions<ProfileNameQuery, ProfileNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileNameQuery, ProfileNameQueryVariables>(ProfileNameDocument, options);
      }
export function useProfileNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileNameQuery, ProfileNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileNameQuery, ProfileNameQueryVariables>(ProfileNameDocument, options);
        }
export type ProfileNameQueryHookResult = ReturnType<typeof useProfileNameQuery>;
export type ProfileNameLazyQueryHookResult = ReturnType<typeof useProfileNameLazyQuery>;
export type ProfileNameQueryResult = Apollo.QueryResult<ProfileNameQuery, ProfileNameQueryVariables>;
export const FinancierStaffDocument = gql`
    query financierStaff {
  getFinancierStaff {
    id
    name
    email
    phoneNo
    isSuspendible
    user {
      id
      userRole {
        role
        commonStatus
      }
      activated
    }
  }
}
    `;

/**
 * __useFinancierStaffQuery__
 *
 * To run a query within a React component, call `useFinancierStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancierStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancierStaffQuery({
 *   variables: {
 *   },
 * });
 */
export function useFinancierStaffQuery(baseOptions?: Apollo.QueryHookOptions<FinancierStaffQuery, FinancierStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FinancierStaffQuery, FinancierStaffQueryVariables>(FinancierStaffDocument, options);
      }
export function useFinancierStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FinancierStaffQuery, FinancierStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FinancierStaffQuery, FinancierStaffQueryVariables>(FinancierStaffDocument, options);
        }
export type FinancierStaffQueryHookResult = ReturnType<typeof useFinancierStaffQuery>;
export type FinancierStaffLazyQueryHookResult = ReturnType<typeof useFinancierStaffLazyQuery>;
export type FinancierStaffQueryResult = Apollo.QueryResult<FinancierStaffQuery, FinancierStaffQueryVariables>;
export const StaffDetailsDocument = gql`
    query staffDetails($userId: String!) {
  getStaffDetails(user_id: $userId) {
    userRole {
      commonStatus
    }
    assignedLoanCount {
      total_count
      active_count
      withdrawn_count
    }
    projectLoanCount {
      project_name
      total_count
      active_count
      withdrawn_count
    }
  }
}
    `;

/**
 * __useStaffDetailsQuery__
 *
 * To run a query within a React component, call `useStaffDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useStaffDetailsQuery(baseOptions: Apollo.QueryHookOptions<StaffDetailsQuery, StaffDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffDetailsQuery, StaffDetailsQueryVariables>(StaffDetailsDocument, options);
      }
export function useStaffDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffDetailsQuery, StaffDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffDetailsQuery, StaffDetailsQueryVariables>(StaffDetailsDocument, options);
        }
export type StaffDetailsQueryHookResult = ReturnType<typeof useStaffDetailsQuery>;
export type StaffDetailsLazyQueryHookResult = ReturnType<typeof useStaffDetailsLazyQuery>;
export type StaffDetailsQueryResult = Apollo.QueryResult<StaffDetailsQuery, StaffDetailsQueryVariables>;
export const StaffEditDetailsDocument = gql`
    query staffEditDetails($userId: String!) {
  getStaffDetails(user_id: $userId) {
    contact {
      name
      email
      phoneNo
    }
    userRole {
      role
    }
  }
}
    `;

/**
 * __useStaffEditDetailsQuery__
 *
 * To run a query within a React component, call `useStaffEditDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffEditDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffEditDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useStaffEditDetailsQuery(baseOptions: Apollo.QueryHookOptions<StaffEditDetailsQuery, StaffEditDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffEditDetailsQuery, StaffEditDetailsQueryVariables>(StaffEditDetailsDocument, options);
      }
export function useStaffEditDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffEditDetailsQuery, StaffEditDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffEditDetailsQuery, StaffEditDetailsQueryVariables>(StaffEditDetailsDocument, options);
        }
export type StaffEditDetailsQueryHookResult = ReturnType<typeof useStaffEditDetailsQuery>;
export type StaffEditDetailsLazyQueryHookResult = ReturnType<typeof useStaffEditDetailsLazyQuery>;
export type StaffEditDetailsQueryResult = Apollo.QueryResult<StaffEditDetailsQuery, StaffEditDetailsQueryVariables>;
export const EditStaffDocument = gql`
    mutation editStaff($input: LoanEditStaffInput!) {
  editStaff(input: $input)
}
    `;
export type EditStaffMutationFn = Apollo.MutationFunction<EditStaffMutation, EditStaffMutationVariables>;

/**
 * __useEditStaffMutation__
 *
 * To run a mutation, you first call `useEditStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStaffMutation, { data, loading, error }] = useEditStaffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditStaffMutation(baseOptions?: Apollo.MutationHookOptions<EditStaffMutation, EditStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditStaffMutation, EditStaffMutationVariables>(EditStaffDocument, options);
      }
export type EditStaffMutationHookResult = ReturnType<typeof useEditStaffMutation>;
export type EditStaffMutationResult = Apollo.MutationResult<EditStaffMutation>;
export type EditStaffMutationOptions = Apollo.BaseMutationOptions<EditStaffMutation, EditStaffMutationVariables>;
export const DeactivateStaffDocument = gql`
    mutation deactivateStaff($userId: String!) {
  deactivateStaff(user_id: $userId)
}
    `;
export type DeactivateStaffMutationFn = Apollo.MutationFunction<DeactivateStaffMutation, DeactivateStaffMutationVariables>;

/**
 * __useDeactivateStaffMutation__
 *
 * To run a mutation, you first call `useDeactivateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateStaffMutation, { data, loading, error }] = useDeactivateStaffMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeactivateStaffMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateStaffMutation, DeactivateStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateStaffMutation, DeactivateStaffMutationVariables>(DeactivateStaffDocument, options);
      }
export type DeactivateStaffMutationHookResult = ReturnType<typeof useDeactivateStaffMutation>;
export type DeactivateStaffMutationResult = Apollo.MutationResult<DeactivateStaffMutation>;
export type DeactivateStaffMutationOptions = Apollo.BaseMutationOptions<DeactivateStaffMutation, DeactivateStaffMutationVariables>;
export const ActivateStaffDocument = gql`
    mutation activateStaff($userId: String!) {
  activateStaff(user_id: $userId)
}
    `;
export type ActivateStaffMutationFn = Apollo.MutationFunction<ActivateStaffMutation, ActivateStaffMutationVariables>;

/**
 * __useActivateStaffMutation__
 *
 * To run a mutation, you first call `useActivateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateStaffMutation, { data, loading, error }] = useActivateStaffMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useActivateStaffMutation(baseOptions?: Apollo.MutationHookOptions<ActivateStaffMutation, ActivateStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateStaffMutation, ActivateStaffMutationVariables>(ActivateStaffDocument, options);
      }
export type ActivateStaffMutationHookResult = ReturnType<typeof useActivateStaffMutation>;
export type ActivateStaffMutationResult = Apollo.MutationResult<ActivateStaffMutation>;
export type ActivateStaffMutationOptions = Apollo.BaseMutationOptions<ActivateStaffMutation, ActivateStaffMutationVariables>;
export const ResendEmailInvitationDocument = gql`
    mutation resendEmailInvitation($userId: String!) {
  resendInvitation(userId: $userId)
}
    `;
export type ResendEmailInvitationMutationFn = Apollo.MutationFunction<ResendEmailInvitationMutation, ResendEmailInvitationMutationVariables>;

/**
 * __useResendEmailInvitationMutation__
 *
 * To run a mutation, you first call `useResendEmailInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailInvitationMutation, { data, loading, error }] = useResendEmailInvitationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendEmailInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendEmailInvitationMutation, ResendEmailInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendEmailInvitationMutation, ResendEmailInvitationMutationVariables>(ResendEmailInvitationDocument, options);
      }
export type ResendEmailInvitationMutationHookResult = ReturnType<typeof useResendEmailInvitationMutation>;
export type ResendEmailInvitationMutationResult = Apollo.MutationResult<ResendEmailInvitationMutation>;
export type ResendEmailInvitationMutationOptions = Apollo.BaseMutationOptions<ResendEmailInvitationMutation, ResendEmailInvitationMutationVariables>;
export const AddStaffDocument = gql`
    mutation addStaff($input: LoanAddStaffInput!) {
  addStaff(input: $input)
}
    `;
export type AddStaffMutationFn = Apollo.MutationFunction<AddStaffMutation, AddStaffMutationVariables>;

/**
 * __useAddStaffMutation__
 *
 * To run a mutation, you first call `useAddStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStaffMutation, { data, loading, error }] = useAddStaffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStaffMutation(baseOptions?: Apollo.MutationHookOptions<AddStaffMutation, AddStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStaffMutation, AddStaffMutationVariables>(AddStaffDocument, options);
      }
export type AddStaffMutationHookResult = ReturnType<typeof useAddStaffMutation>;
export type AddStaffMutationResult = Apollo.MutationResult<AddStaffMutation>;
export type AddStaffMutationOptions = Apollo.BaseMutationOptions<AddStaffMutation, AddStaffMutationVariables>;
export const ProjectListDocument = gql`
    query projectList {
  getProjectLoan {
    id
    name
    projectAddress
    city
    state
  }
}
    `;

/**
 * __useProjectListQuery__
 *
 * To run a query within a React component, call `useProjectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectListQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectListQuery(baseOptions?: Apollo.QueryHookOptions<ProjectListQuery, ProjectListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectListQuery, ProjectListQueryVariables>(ProjectListDocument, options);
      }
export function useProjectListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectListQuery, ProjectListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectListQuery, ProjectListQueryVariables>(ProjectListDocument, options);
        }
export type ProjectListQueryHookResult = ReturnType<typeof useProjectListQuery>;
export type ProjectListLazyQueryHookResult = ReturnType<typeof useProjectListLazyQuery>;
export type ProjectListQueryResult = Apollo.QueryResult<ProjectListQuery, ProjectListQueryVariables>;
export const ProjectDocument = gql`
    query project {
  getProjectLoan {
    id
    name
    firstGalleryImage {
      url
      caption
    }
    totalLoanCount
    activeLoans {
      count
    }
    withdrawnLoans {
      count
    }
  }
}
    `;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectQuery(baseOptions?: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const ProjectByIdDocument = gql`
    query projectById($projectid: String!) {
  getProjectbyId(projectid: $projectid) {
    id
    name
    currency
    minPrice
    maxPrice
    projectAddress
    city
    state
    tenureDesc
    completionDate
    launchDate
    totalLoanCount
    activeLoans {
      count
      openCount
      unreachableCount
      docsSubmittedCount
      loanAppealedCount
      pendingDocsCount
      loanIssuedCount
      loanDocSignedCount
      loanSignedCount
      assignedCount
      unassignedCount
    }
    withdrawnLoans {
      count
      developerCancelledCount
      customerCancelledCount
      customerRejectedCount
      loanRejectedCount
    }
  }
}
    `;

/**
 * __useProjectByIdQuery__
 *
 * To run a query within a React component, call `useProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectByIdQuery({
 *   variables: {
 *      projectid: // value for 'projectid'
 *   },
 * });
 */
export function useProjectByIdQuery(baseOptions: Apollo.QueryHookOptions<ProjectByIdQuery, ProjectByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectByIdQuery, ProjectByIdQueryVariables>(ProjectByIdDocument, options);
      }
export function useProjectByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectByIdQuery, ProjectByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectByIdQuery, ProjectByIdQueryVariables>(ProjectByIdDocument, options);
        }
export type ProjectByIdQueryHookResult = ReturnType<typeof useProjectByIdQuery>;
export type ProjectByIdLazyQueryHookResult = ReturnType<typeof useProjectByIdLazyQuery>;
export type ProjectByIdQueryResult = Apollo.QueryResult<ProjectByIdQuery, ProjectByIdQueryVariables>;
export const LoanCountDocument = gql`
    query loanCount($projectId: String!) {
  getLoanCountByProjectId(project_id: $projectId) {
    totalLoanCount
    activeLoans {
      count
      openCount
      unreachableCount
      docsSubmittedCount
      loanAppealedCount
      pendingDocsCount
      loanIssuedCount
      loanDocSignedCount
      loanSignedCount
      assignedCount
      unassignedCount
    }
    withdrawnLoans {
      count
      developerCancelledCount
      customerCancelledCount
      customerRejectedCount
      loanRejectedCount
    }
    loanStatusList {
      id
      statusCode
      statusName
      stages
    }
  }
}
    `;

/**
 * __useLoanCountQuery__
 *
 * To run a query within a React component, call `useLoanCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanCountQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useLoanCountQuery(baseOptions: Apollo.QueryHookOptions<LoanCountQuery, LoanCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoanCountQuery, LoanCountQueryVariables>(LoanCountDocument, options);
      }
export function useLoanCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoanCountQuery, LoanCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoanCountQuery, LoanCountQueryVariables>(LoanCountDocument, options);
        }
export type LoanCountQueryHookResult = ReturnType<typeof useLoanCountQuery>;
export type LoanCountLazyQueryHookResult = ReturnType<typeof useLoanCountLazyQuery>;
export type LoanCountQueryResult = Apollo.QueryResult<LoanCountQuery, LoanCountQueryVariables>;
export const LoanListDocument = gql`
    query loanList($projectId: String!, $loanStatusIds: [String!], $searchedDate: DateTime) {
  getLoanListByProjectId(project_id: $projectId, loan_status_ids: $loanStatusIds, searchedDate: $searchedDate) {
    id
    applyDate
    applyAmount
    approvalDate
    approvalAmount
    loanAppStatus {
      statusCode
      statusName
    }
    sale {
      id
      sellingPrice
      salesStatus
      unit {
        unitNo
      }
      contact {
        fullName
        salutation {
          name
        }
        race {
          name
        }
        maritalStatus {
          name
        }
        nationality {
          name
        }
        icNo
        phoneNo
        email
      }
    }
    staff {
      id
      endfinContacts {
        name
      }
    }
  }
}
    `;

/**
 * __useLoanListQuery__
 *
 * To run a query within a React component, call `useLoanListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      loanStatusIds: // value for 'loanStatusIds'
 *      searchedDate: // value for 'searchedDate'
 *   },
 * });
 */
export function useLoanListQuery(baseOptions: Apollo.QueryHookOptions<LoanListQuery, LoanListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoanListQuery, LoanListQueryVariables>(LoanListDocument, options);
      }
export function useLoanListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoanListQuery, LoanListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoanListQuery, LoanListQueryVariables>(LoanListDocument, options);
        }
export type LoanListQueryHookResult = ReturnType<typeof useLoanListQuery>;
export type LoanListLazyQueryHookResult = ReturnType<typeof useLoanListLazyQuery>;
export type LoanListQueryResult = Apollo.QueryResult<LoanListQuery, LoanListQueryVariables>;
export const LoanListActiveDocument = gql`
    query loanListActive($projectId: String!, $loanStatusIds: String, $searchedDate: DateTime) {
  getLoanListByProjectIdActive(project_id: $projectId, loan_status_ids: $loanStatusIds, searchedDate: $searchedDate) {
    id
    applyDate
    applyAmount
    approvalDate
    approvalAmount
    loanAppStatus {
      statusCode
      statusName
    }
    sale {
      id
      sellingPrice
      salesStatus
      unit {
        unitNo
      }
      contact {
        fullName
        salutation {
          name
        }
        race {
          name
        }
        maritalStatus {
          name
        }
        nationality {
          name
        }
        icNo
        phoneNo
        email
      }
    }
    staff {
      id
      endfinContacts {
        name
      }
    }
  }
}
    `;

/**
 * __useLoanListActiveQuery__
 *
 * To run a query within a React component, call `useLoanListActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanListActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanListActiveQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      loanStatusIds: // value for 'loanStatusIds'
 *      searchedDate: // value for 'searchedDate'
 *   },
 * });
 */
export function useLoanListActiveQuery(baseOptions: Apollo.QueryHookOptions<LoanListActiveQuery, LoanListActiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoanListActiveQuery, LoanListActiveQueryVariables>(LoanListActiveDocument, options);
      }
export function useLoanListActiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoanListActiveQuery, LoanListActiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoanListActiveQuery, LoanListActiveQueryVariables>(LoanListActiveDocument, options);
        }
export type LoanListActiveQueryHookResult = ReturnType<typeof useLoanListActiveQuery>;
export type LoanListActiveLazyQueryHookResult = ReturnType<typeof useLoanListActiveLazyQuery>;
export type LoanListActiveQueryResult = Apollo.QueryResult<LoanListActiveQuery, LoanListActiveQueryVariables>;
export const LoanListWithdrawnDocument = gql`
    query loanListWithdrawn($projectId: String!, $loanStatusIds: String, $searchedDate: DateTime) {
  getLoanListByProjectIdWithdrawn(project_id: $projectId, loan_status_ids: $loanStatusIds, searchedDate: $searchedDate) {
    id
    applyDate
    applyAmount
    approvalDate
    approvalAmount
    loanAppStatus {
      statusCode
      statusName
    }
    sale {
      id
      sellingPrice
      salesStatus
      unit {
        unitNo
      }
      contact {
        fullName
        salutation {
          name
        }
        race {
          name
        }
        maritalStatus {
          name
        }
        nationality {
          name
        }
        icNo
        phoneNo
        email
      }
    }
    staff {
      id
      endfinContacts {
        name
      }
    }
  }
}
    `;

/**
 * __useLoanListWithdrawnQuery__
 *
 * To run a query within a React component, call `useLoanListWithdrawnQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanListWithdrawnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanListWithdrawnQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      loanStatusIds: // value for 'loanStatusIds'
 *      searchedDate: // value for 'searchedDate'
 *   },
 * });
 */
export function useLoanListWithdrawnQuery(baseOptions: Apollo.QueryHookOptions<LoanListWithdrawnQuery, LoanListWithdrawnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoanListWithdrawnQuery, LoanListWithdrawnQueryVariables>(LoanListWithdrawnDocument, options);
      }
export function useLoanListWithdrawnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoanListWithdrawnQuery, LoanListWithdrawnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoanListWithdrawnQuery, LoanListWithdrawnQueryVariables>(LoanListWithdrawnDocument, options);
        }
export type LoanListWithdrawnQueryHookResult = ReturnType<typeof useLoanListWithdrawnQuery>;
export type LoanListWithdrawnLazyQueryHookResult = ReturnType<typeof useLoanListWithdrawnLazyQuery>;
export type LoanListWithdrawnQueryResult = Apollo.QueryResult<LoanListWithdrawnQuery, LoanListWithdrawnQueryVariables>;
export const LoanListAssignmentDocument = gql`
    query loanListAssignment($projectId: String!, $searchedDate: DateTime) {
  getLoanListByProjectIdLOAssignment(project_id: $projectId, searchedDate: $searchedDate) {
    id
    applyDate
    applyAmount
    approvalDate
    approvalAmount
    sale {
      id
      sellingPrice
      salesStatus
      unit {
        unitNo
      }
      contact {
        fullName
        salutation {
          name
        }
        race {
          name
        }
        maritalStatus {
          name
        }
        nationality {
          name
        }
        icNo
        phoneNo
        email
      }
    }
    staff {
      id
      endfinContacts {
        name
      }
    }
  }
}
    `;

/**
 * __useLoanListAssignmentQuery__
 *
 * To run a query within a React component, call `useLoanListAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanListAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanListAssignmentQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      searchedDate: // value for 'searchedDate'
 *   },
 * });
 */
export function useLoanListAssignmentQuery(baseOptions: Apollo.QueryHookOptions<LoanListAssignmentQuery, LoanListAssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoanListAssignmentQuery, LoanListAssignmentQueryVariables>(LoanListAssignmentDocument, options);
      }
export function useLoanListAssignmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoanListAssignmentQuery, LoanListAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoanListAssignmentQuery, LoanListAssignmentQueryVariables>(LoanListAssignmentDocument, options);
        }
export type LoanListAssignmentQueryHookResult = ReturnType<typeof useLoanListAssignmentQuery>;
export type LoanListAssignmentLazyQueryHookResult = ReturnType<typeof useLoanListAssignmentLazyQuery>;
export type LoanListAssignmentQueryResult = Apollo.QueryResult<LoanListAssignmentQuery, LoanListAssignmentQueryVariables>;
export const LoanDetailDocument = gql`
    query loanDetail($loanId: String!) {
  getLoanDetailById(loan_id: $loanId) {
    id
    applyAmount
    applyDate
    approvalAmount
    approvalDate
    agingDate
    loanAppStatus {
      statusCode
      statusName
    }
    staff {
      id
      endfinContacts {
        id
        name
      }
    }
    sale {
      id
      sellingPrice
      buyerSolicitor {
        id
      }
      buyerReference
      unit {
        unitNo
      }
      contact {
        fullName
        icNo
        nationality {
          name
        }
        salutation {
          name
        }
        race {
          name
        }
      }
    }
    activityData {
      id
      createdBy
      createdDate
      remark
      loanAppStatus {
        statusName
        statusCode
      }
      activityTypeDesc
    }
  }
}
    `;

/**
 * __useLoanDetailQuery__
 *
 * To run a query within a React component, call `useLoanDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanDetailQuery({
 *   variables: {
 *      loanId: // value for 'loanId'
 *   },
 * });
 */
export function useLoanDetailQuery(baseOptions: Apollo.QueryHookOptions<LoanDetailQuery, LoanDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoanDetailQuery, LoanDetailQueryVariables>(LoanDetailDocument, options);
      }
export function useLoanDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoanDetailQuery, LoanDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoanDetailQuery, LoanDetailQueryVariables>(LoanDetailDocument, options);
        }
export type LoanDetailQueryHookResult = ReturnType<typeof useLoanDetailQuery>;
export type LoanDetailLazyQueryHookResult = ReturnType<typeof useLoanDetailLazyQuery>;
export type LoanDetailQueryResult = Apollo.QueryResult<LoanDetailQuery, LoanDetailQueryVariables>;
export const GetAttachmentByLoanIdDocument = gql`
    query getAttachmentByLoanId($loanId: String!) {
  getAttachmentByLoanId(loanId: $loanId) {
    id
    resourceUrl
    mediaType
    fileName
    refType
    allowDelete
    createdTs
  }
  getESignLetter(loanId: $loanId) {
    letterTemplateId
    name
    letterType
  }
  getMultiESignLetter(loanId: $loanId) {
    letterTypeName
    letter {
      letterTemplateId
      name
      letterType
      defaulted
    }
  }
}
    `;

/**
 * __useGetAttachmentByLoanIdQuery__
 *
 * To run a query within a React component, call `useGetAttachmentByLoanIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentByLoanIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentByLoanIdQuery({
 *   variables: {
 *      loanId: // value for 'loanId'
 *   },
 * });
 */
export function useGetAttachmentByLoanIdQuery(baseOptions: Apollo.QueryHookOptions<GetAttachmentByLoanIdQuery, GetAttachmentByLoanIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttachmentByLoanIdQuery, GetAttachmentByLoanIdQueryVariables>(GetAttachmentByLoanIdDocument, options);
      }
export function useGetAttachmentByLoanIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttachmentByLoanIdQuery, GetAttachmentByLoanIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttachmentByLoanIdQuery, GetAttachmentByLoanIdQueryVariables>(GetAttachmentByLoanIdDocument, options);
        }
export type GetAttachmentByLoanIdQueryHookResult = ReturnType<typeof useGetAttachmentByLoanIdQuery>;
export type GetAttachmentByLoanIdLazyQueryHookResult = ReturnType<typeof useGetAttachmentByLoanIdLazyQuery>;
export type GetAttachmentByLoanIdQueryResult = Apollo.QueryResult<GetAttachmentByLoanIdQuery, GetAttachmentByLoanIdQueryVariables>;
export const UpdateLoanStatusDocument = gql`
    mutation updateLoanStatus($input: LoanUpdateLoanStatusInput!, $file: Upload) {
  updateLoanStatus(input: $input, file: $file)
}
    `;
export type UpdateLoanStatusMutationFn = Apollo.MutationFunction<UpdateLoanStatusMutation, UpdateLoanStatusMutationVariables>;

/**
 * __useUpdateLoanStatusMutation__
 *
 * To run a mutation, you first call `useUpdateLoanStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoanStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoanStatusMutation, { data, loading, error }] = useUpdateLoanStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateLoanStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoanStatusMutation, UpdateLoanStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoanStatusMutation, UpdateLoanStatusMutationVariables>(UpdateLoanStatusDocument, options);
      }
export type UpdateLoanStatusMutationHookResult = ReturnType<typeof useUpdateLoanStatusMutation>;
export type UpdateLoanStatusMutationResult = Apollo.MutationResult<UpdateLoanStatusMutation>;
export type UpdateLoanStatusMutationOptions = Apollo.BaseMutationOptions<UpdateLoanStatusMutation, UpdateLoanStatusMutationVariables>;
export const LoanStatusListDocument = gql`
    query loanStatusList($projectId: String!) {
  getLoanCountByProjectId(project_id: $projectId) {
    loanStatusList {
      id
      statusCode
      statusName
      stages
    }
  }
}
    `;

/**
 * __useLoanStatusListQuery__
 *
 * To run a query within a React component, call `useLoanStatusListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanStatusListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanStatusListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useLoanStatusListQuery(baseOptions: Apollo.QueryHookOptions<LoanStatusListQuery, LoanStatusListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoanStatusListQuery, LoanStatusListQueryVariables>(LoanStatusListDocument, options);
      }
export function useLoanStatusListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoanStatusListQuery, LoanStatusListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoanStatusListQuery, LoanStatusListQueryVariables>(LoanStatusListDocument, options);
        }
export type LoanStatusListQueryHookResult = ReturnType<typeof useLoanStatusListQuery>;
export type LoanStatusListLazyQueryHookResult = ReturnType<typeof useLoanStatusListLazyQuery>;
export type LoanStatusListQueryResult = Apollo.QueryResult<LoanStatusListQuery, LoanStatusListQueryVariables>;
export const GetSolicitorListDocument = gql`
    query getSolicitorList($project_id: String!) {
  getSolicitorListByProjectId(project_id: $project_id) {
    id
    name
  }
}
    `;

/**
 * __useGetSolicitorListQuery__
 *
 * To run a query within a React component, call `useGetSolicitorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSolicitorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSolicitorListQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *   },
 * });
 */
export function useGetSolicitorListQuery(baseOptions: Apollo.QueryHookOptions<GetSolicitorListQuery, GetSolicitorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSolicitorListQuery, GetSolicitorListQueryVariables>(GetSolicitorListDocument, options);
      }
export function useGetSolicitorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSolicitorListQuery, GetSolicitorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSolicitorListQuery, GetSolicitorListQueryVariables>(GetSolicitorListDocument, options);
        }
export type GetSolicitorListQueryHookResult = ReturnType<typeof useGetSolicitorListQuery>;
export type GetSolicitorListLazyQueryHookResult = ReturnType<typeof useGetSolicitorListLazyQuery>;
export type GetSolicitorListQueryResult = Apollo.QueryResult<GetSolicitorListQuery, GetSolicitorListQueryVariables>;
export const GetUpdateLoanInfoDocument = gql`
    query getUpdateLoanInfo($loanId: String!) {
  getLoanDetailById(loan_id: $loanId) {
    id
    approvalAmount
    tenure
    loanAppStatus {
      statusCode
    }
    sale {
      id
      buyerSolicitor {
        id
      }
      buyerReference
    }
    activityData {
      remark
    }
  }
}
    `;

/**
 * __useGetUpdateLoanInfoQuery__
 *
 * To run a query within a React component, call `useGetUpdateLoanInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdateLoanInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdateLoanInfoQuery({
 *   variables: {
 *      loanId: // value for 'loanId'
 *   },
 * });
 */
export function useGetUpdateLoanInfoQuery(baseOptions: Apollo.QueryHookOptions<GetUpdateLoanInfoQuery, GetUpdateLoanInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpdateLoanInfoQuery, GetUpdateLoanInfoQueryVariables>(GetUpdateLoanInfoDocument, options);
      }
export function useGetUpdateLoanInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpdateLoanInfoQuery, GetUpdateLoanInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpdateLoanInfoQuery, GetUpdateLoanInfoQueryVariables>(GetUpdateLoanInfoDocument, options);
        }
export type GetUpdateLoanInfoQueryHookResult = ReturnType<typeof useGetUpdateLoanInfoQuery>;
export type GetUpdateLoanInfoLazyQueryHookResult = ReturnType<typeof useGetUpdateLoanInfoLazyQuery>;
export type GetUpdateLoanInfoQueryResult = Apollo.QueryResult<GetUpdateLoanInfoQuery, GetUpdateLoanInfoQueryVariables>;
export const AssignStaffDocument = gql`
    mutation assignStaff($input: LoanAssignStaffInput!) {
  assignStaff(input: $input)
}
    `;
export type AssignStaffMutationFn = Apollo.MutationFunction<AssignStaffMutation, AssignStaffMutationVariables>;

/**
 * __useAssignStaffMutation__
 *
 * To run a mutation, you first call `useAssignStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignStaffMutation, { data, loading, error }] = useAssignStaffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignStaffMutation(baseOptions?: Apollo.MutationHookOptions<AssignStaffMutation, AssignStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignStaffMutation, AssignStaffMutationVariables>(AssignStaffDocument, options);
      }
export type AssignStaffMutationHookResult = ReturnType<typeof useAssignStaffMutation>;
export type AssignStaffMutationResult = Apollo.MutationResult<AssignStaffMutation>;
export type AssignStaffMutationOptions = Apollo.BaseMutationOptions<AssignStaffMutation, AssignStaffMutationVariables>;
export const DownloadAttachmentDocument = gql`
    query downloadAttachment($attachmentId: String!) {
  downloadAttachment(attachmentId: $attachmentId) {
    fileName
    base64
  }
}
    `;

/**
 * __useDownloadAttachmentQuery__
 *
 * To run a query within a React component, call `useDownloadAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadAttachmentQuery({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useDownloadAttachmentQuery(baseOptions: Apollo.QueryHookOptions<DownloadAttachmentQuery, DownloadAttachmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadAttachmentQuery, DownloadAttachmentQueryVariables>(DownloadAttachmentDocument, options);
      }
export function useDownloadAttachmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadAttachmentQuery, DownloadAttachmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadAttachmentQuery, DownloadAttachmentQueryVariables>(DownloadAttachmentDocument, options);
        }
export type DownloadAttachmentQueryHookResult = ReturnType<typeof useDownloadAttachmentQuery>;
export type DownloadAttachmentLazyQueryHookResult = ReturnType<typeof useDownloadAttachmentLazyQuery>;
export type DownloadAttachmentQueryResult = Apollo.QueryResult<DownloadAttachmentQuery, DownloadAttachmentQueryVariables>;
export const DeleteAttachmentDocument = gql`
    query deleteAttachment($attachmentId: String!) {
  deleteAttachment(attachmentId: $attachmentId)
}
    `;

/**
 * __useDeleteAttachmentQuery__
 *
 * To run a query within a React component, call `useDeleteAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteAttachmentQuery({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useDeleteAttachmentQuery(baseOptions: Apollo.QueryHookOptions<DeleteAttachmentQuery, DeleteAttachmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeleteAttachmentQuery, DeleteAttachmentQueryVariables>(DeleteAttachmentDocument, options);
      }
export function useDeleteAttachmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeleteAttachmentQuery, DeleteAttachmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeleteAttachmentQuery, DeleteAttachmentQueryVariables>(DeleteAttachmentDocument, options);
        }
export type DeleteAttachmentQueryHookResult = ReturnType<typeof useDeleteAttachmentQuery>;
export type DeleteAttachmentLazyQueryHookResult = ReturnType<typeof useDeleteAttachmentLazyQuery>;
export type DeleteAttachmentQueryResult = Apollo.QueryResult<DeleteAttachmentQuery, DeleteAttachmentQueryVariables>;
export const UploadAttachmentDocument = gql`
    mutation uploadAttachment($input: LoanUploadAttachmentInput!) {
  uploadAttachment(input: $input)
}
    `;
export type UploadAttachmentMutationFn = Apollo.MutationFunction<UploadAttachmentMutation, UploadAttachmentMutationVariables>;

/**
 * __useUploadAttachmentMutation__
 *
 * To run a mutation, you first call `useUploadAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAttachmentMutation, { data, loading, error }] = useUploadAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<UploadAttachmentMutation, UploadAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadAttachmentMutation, UploadAttachmentMutationVariables>(UploadAttachmentDocument, options);
      }
export type UploadAttachmentMutationHookResult = ReturnType<typeof useUploadAttachmentMutation>;
export type UploadAttachmentMutationResult = Apollo.MutationResult<UploadAttachmentMutation>;
export type UploadAttachmentMutationOptions = Apollo.BaseMutationOptions<UploadAttachmentMutation, UploadAttachmentMutationVariables>;
export const GetWaterMarkTextDocument = gql`
    query getWaterMarkText {
  GetWaterMarkText
}
    `;

/**
 * __useGetWaterMarkTextQuery__
 *
 * To run a query within a React component, call `useGetWaterMarkTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaterMarkTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaterMarkTextQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWaterMarkTextQuery(baseOptions?: Apollo.QueryHookOptions<GetWaterMarkTextQuery, GetWaterMarkTextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWaterMarkTextQuery, GetWaterMarkTextQueryVariables>(GetWaterMarkTextDocument, options);
      }
export function useGetWaterMarkTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWaterMarkTextQuery, GetWaterMarkTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWaterMarkTextQuery, GetWaterMarkTextQueryVariables>(GetWaterMarkTextDocument, options);
        }
export type GetWaterMarkTextQueryHookResult = ReturnType<typeof useGetWaterMarkTextQuery>;
export type GetWaterMarkTextLazyQueryHookResult = ReturnType<typeof useGetWaterMarkTextLazyQuery>;
export type GetWaterMarkTextQueryResult = Apollo.QueryResult<GetWaterMarkTextQuery, GetWaterMarkTextQueryVariables>;
export const UploadNricPassportDocument = gql`
    mutation uploadNricPassport($input: LoanUploadNricPassportInput!) {
  uploadNricPassport(input: $input) {
    id
    accountId
    bucketFileName
    fileName
    isPrivate
    mediaType
    refId
    refTable
    refType
    resourceUrl
    seqNo
  }
}
    `;
export type UploadNricPassportMutationFn = Apollo.MutationFunction<UploadNricPassportMutation, UploadNricPassportMutationVariables>;

/**
 * __useUploadNricPassportMutation__
 *
 * To run a mutation, you first call `useUploadNricPassportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadNricPassportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadNricPassportMutation, { data, loading, error }] = useUploadNricPassportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadNricPassportMutation(baseOptions?: Apollo.MutationHookOptions<UploadNricPassportMutation, UploadNricPassportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadNricPassportMutation, UploadNricPassportMutationVariables>(UploadNricPassportDocument, options);
      }
export type UploadNricPassportMutationHookResult = ReturnType<typeof useUploadNricPassportMutation>;
export type UploadNricPassportMutationResult = Apollo.MutationResult<UploadNricPassportMutation>;
export type UploadNricPassportMutationOptions = Apollo.BaseMutationOptions<UploadNricPassportMutation, UploadNricPassportMutationVariables>;
export const GetNotificationsDocument = gql`
    query getNotifications($limit: Float!, $page: Float!, $search: String) {
  getNotifications(limit: $limit, page: $page, search: $search)
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const MarkAllReadDocument = gql`
    query markAllRead {
  markAllRead
}
    `;

/**
 * __useMarkAllReadQuery__
 *
 * To run a query within a React component, call `useMarkAllReadQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarkAllReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarkAllReadQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllReadQuery(baseOptions?: Apollo.QueryHookOptions<MarkAllReadQuery, MarkAllReadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarkAllReadQuery, MarkAllReadQueryVariables>(MarkAllReadDocument, options);
      }
export function useMarkAllReadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarkAllReadQuery, MarkAllReadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarkAllReadQuery, MarkAllReadQueryVariables>(MarkAllReadDocument, options);
        }
export type MarkAllReadQueryHookResult = ReturnType<typeof useMarkAllReadQuery>;
export type MarkAllReadLazyQueryHookResult = ReturnType<typeof useMarkAllReadLazyQuery>;
export type MarkAllReadQueryResult = Apollo.QueryResult<MarkAllReadQuery, MarkAllReadQueryVariables>;
export const MarkAsReadDocument = gql`
    mutation markAsRead($notificationIds: [String!]!) {
  markAsRead(notificationIds: $notificationIds)
}
    `;
export type MarkAsReadMutationFn = Apollo.MutationFunction<MarkAsReadMutation, MarkAsReadMutationVariables>;

/**
 * __useMarkAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadMutation, { data, loading, error }] = useMarkAsReadMutation({
 *   variables: {
 *      notificationIds: // value for 'notificationIds'
 *   },
 * });
 */
export function useMarkAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkAsReadMutation, MarkAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAsReadMutation, MarkAsReadMutationVariables>(MarkAsReadDocument, options);
      }
export type MarkAsReadMutationHookResult = ReturnType<typeof useMarkAsReadMutation>;
export type MarkAsReadMutationResult = Apollo.MutationResult<MarkAsReadMutation>;
export type MarkAsReadMutationOptions = Apollo.BaseMutationOptions<MarkAsReadMutation, MarkAsReadMutationVariables>;
export const GetELetterPdfDocument = gql`
    query getELetterPDF($projectId: String!, $letterTemplateId: String!, $loanId: String!) {
  getELetterPDF(projectId: $projectId, letterTemplateId: $letterTemplateId, loanId: $loanId) {
    fileName
    content
  }
}
    `;

/**
 * __useGetELetterPdfQuery__
 *
 * To run a query within a React component, call `useGetELetterPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetELetterPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetELetterPdfQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      letterTemplateId: // value for 'letterTemplateId'
 *      loanId: // value for 'loanId'
 *   },
 * });
 */
export function useGetELetterPdfQuery(baseOptions: Apollo.QueryHookOptions<GetELetterPdfQuery, GetELetterPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetELetterPdfQuery, GetELetterPdfQueryVariables>(GetELetterPdfDocument, options);
      }
export function useGetELetterPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetELetterPdfQuery, GetELetterPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetELetterPdfQuery, GetELetterPdfQueryVariables>(GetELetterPdfDocument, options);
        }
export type GetELetterPdfQueryHookResult = ReturnType<typeof useGetELetterPdfQuery>;
export type GetELetterPdfLazyQueryHookResult = ReturnType<typeof useGetELetterPdfLazyQuery>;
export type GetELetterPdfQueryResult = Apollo.QueryResult<GetELetterPdfQuery, GetELetterPdfQueryVariables>;
export const NotificationPermissionDocument = gql`
    query notificationPermission {
  getNotificationPermission
}
    `;

/**
 * __useNotificationPermissionQuery__
 *
 * To run a query within a React component, call `useNotificationPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationPermissionQuery(baseOptions?: Apollo.QueryHookOptions<NotificationPermissionQuery, NotificationPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationPermissionQuery, NotificationPermissionQueryVariables>(NotificationPermissionDocument, options);
      }
export function useNotificationPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationPermissionQuery, NotificationPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationPermissionQuery, NotificationPermissionQueryVariables>(NotificationPermissionDocument, options);
        }
export type NotificationPermissionQueryHookResult = ReturnType<typeof useNotificationPermissionQuery>;
export type NotificationPermissionLazyQueryHookResult = ReturnType<typeof useNotificationPermissionLazyQuery>;
export type NotificationPermissionQueryResult = Apollo.QueryResult<NotificationPermissionQuery, NotificationPermissionQueryVariables>;
export const SaveNotificationPermissionDocument = gql`
    mutation saveNotificationPermission($input: NotificationInput!) {
  saveNotificationPermission(input: $input)
}
    `;
export type SaveNotificationPermissionMutationFn = Apollo.MutationFunction<SaveNotificationPermissionMutation, SaveNotificationPermissionMutationVariables>;

/**
 * __useSaveNotificationPermissionMutation__
 *
 * To run a mutation, you first call `useSaveNotificationPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNotificationPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNotificationPermissionMutation, { data, loading, error }] = useSaveNotificationPermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveNotificationPermissionMutation(baseOptions?: Apollo.MutationHookOptions<SaveNotificationPermissionMutation, SaveNotificationPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveNotificationPermissionMutation, SaveNotificationPermissionMutationVariables>(SaveNotificationPermissionDocument, options);
      }
export type SaveNotificationPermissionMutationHookResult = ReturnType<typeof useSaveNotificationPermissionMutation>;
export type SaveNotificationPermissionMutationResult = Apollo.MutationResult<SaveNotificationPermissionMutation>;
export type SaveNotificationPermissionMutationOptions = Apollo.BaseMutationOptions<SaveNotificationPermissionMutation, SaveNotificationPermissionMutationVariables>;
export const GetNotificationHistoryDayDocument = gql`
    query getNotificationHistoryDay {
  getNotificationHistoryDay
}
    `;

/**
 * __useGetNotificationHistoryDayQuery__
 *
 * To run a query within a React component, call `useGetNotificationHistoryDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationHistoryDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationHistoryDayQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationHistoryDayQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationHistoryDayQuery, GetNotificationHistoryDayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationHistoryDayQuery, GetNotificationHistoryDayQueryVariables>(GetNotificationHistoryDayDocument, options);
      }
export function useGetNotificationHistoryDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationHistoryDayQuery, GetNotificationHistoryDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationHistoryDayQuery, GetNotificationHistoryDayQueryVariables>(GetNotificationHistoryDayDocument, options);
        }
export type GetNotificationHistoryDayQueryHookResult = ReturnType<typeof useGetNotificationHistoryDayQuery>;
export type GetNotificationHistoryDayLazyQueryHookResult = ReturnType<typeof useGetNotificationHistoryDayLazyQuery>;
export type GetNotificationHistoryDayQueryResult = Apollo.QueryResult<GetNotificationHistoryDayQuery, GetNotificationHistoryDayQueryVariables>;
export const GetUserNotificationPermissionDocument = gql`
    query getUserNotificationPermission {
  getUserNotificationPermission
}
    `;

/**
 * __useGetUserNotificationPermissionQuery__
 *
 * To run a query within a React component, call `useGetUserNotificationPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserNotificationPermissionQuery(baseOptions?: Apollo.QueryHookOptions<GetUserNotificationPermissionQuery, GetUserNotificationPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserNotificationPermissionQuery, GetUserNotificationPermissionQueryVariables>(GetUserNotificationPermissionDocument, options);
      }
export function useGetUserNotificationPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNotificationPermissionQuery, GetUserNotificationPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserNotificationPermissionQuery, GetUserNotificationPermissionQueryVariables>(GetUserNotificationPermissionDocument, options);
        }
export type GetUserNotificationPermissionQueryHookResult = ReturnType<typeof useGetUserNotificationPermissionQuery>;
export type GetUserNotificationPermissionLazyQueryHookResult = ReturnType<typeof useGetUserNotificationPermissionLazyQuery>;
export type GetUserNotificationPermissionQueryResult = Apollo.QueryResult<GetUserNotificationPermissionQuery, GetUserNotificationPermissionQueryVariables>;